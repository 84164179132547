import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadItemActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { DepartmentsFilter } from "../../../sharedHealthComponents/types/frontendTypes";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { DepartmentType } from "../../types/enums";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { areEquivalent } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface DepartmentsState extends RemoteState<ViewModels.DepartmentViewModel, DepartmentsFilter> {
    serviceDepartments: ViewModels.DepartmentViewModel[];
}

const initialState: DepartmentsState = {
    ...defaultRemoteInitialState(),
    serviceDepartments: []
}

export const departmentsSlice = createSlice({
    name: 'departments',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        setServiceDepartments: (state, action: PayloadAction<ViewModels.DepartmentViewModel[]>) => {
            state.serviceDepartments = action.payload;
        }
    }
});

const departmentQueryBuilder = (state: RootState, sliceState: RemoteState<ViewModels.DepartmentViewModel,DepartmentsFilter>) => {
    const queryParams = defaultQueryBuilder(state, sliceState, departmentFilterComparar);
    queryParams.push({ key: 'orderBy', value: 'institution' });
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.institutionId) {
            queryParams.push({ key: 'institutionId', value: filter.institutionId });
        }
        if(filter.departmentTypes) {
            for (const departmentType of filter.departmentTypes) {
                queryParams.push({ key: 'departmentTypes', value: departmentType });
            }
        }
    }
    return queryParams;
}

export const departmentFilterComparar = (f1?: DepartmentsFilter, f2?: DepartmentsFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.institutionId === f2!.institutionId
        && areEquivalent(f1!.departmentTypes, f2!.departmentTypes);
}
export const departmentsActions = {
    ...createRestApiActions(
        'departments', 
        departmentsSlice.actions,
        state => state.departments,
        departmentQueryBuilder,
        departmentFilterComparar
    ),
    loadServiceDepartments: loadItemActionBuilder(
        _ => 'api/departments',
        _ => ({ departmentTypes: DepartmentType.Services }),
        () => resolveText("Departments_CouldNotLoad"),
        departmentsSlice.actions.setIsLoading,
        departmentsSlice.actions.setServiceDepartments
    )
};
export interface SelectDepartmentsByIdOrForInstitutionArgs {
    departmentId?: string;
    institutionId?: string;
}
export const departmentsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.departments),
    createForInstitution: () => createSelector(
        (state: RootState) => state.departments.items,
        (_: RootState, args: { institutionId?: string}) => args.institutionId,
        (items, institutionId) => institutionId 
            ? items.filter(x => x.institutionId === institutionId)
            : items
    ),
    createSelectByIdOrForInstitution: () => createSelector(
        (state: RootState) => state.departments.items,
        (_: RootState, args: SelectDepartmentsByIdOrForInstitutionArgs) => args.departmentId,
        (_: RootState, args: SelectDepartmentsByIdOrForInstitutionArgs) => args.institutionId,
        (items, departmentId, institutionId) => departmentId
            ? items.filter(x => x.id === departmentId)
            : items.filter(x => x.institutionId === institutionId)
    ),
};
