import { createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { Models } from "../../types/models";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { FunctionalGroupFilter } from "../../types/frontendTypes";

export interface FunctionalGroupState extends RemoteState<Models.Collaboration.FunctionalGroup, FunctionalGroupFilter> {}

const initialState: FunctionalGroupState = {
    ...defaultRemoteInitialState(),
}
export const functionalGroupsSlice = createSlice({
    name: 'functionalGroups',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const functionalGroupsFilterComparer = (f1?: FunctionalGroupFilter, f2?: FunctionalGroupFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.onlyMine === f2!.onlyMine;
}
const functionalGroupsQueryBuilder = (state: RootState, sliceState: FunctionalGroupState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, functionalGroupsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.onlyMine) {
            queryParams.push({ key: 'onlyMine', value: 'true' });
        }
    }
    return queryParams;
}
export const functionalGroupsActions = {
    ...createRestApiActions(
        'functionalGroups',
        functionalGroupsSlice.actions,
        state => state.functionalGroups,
        functionalGroupsQueryBuilder,
        functionalGroupsFilterComparer
    ),
}
export const functionalGroupsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.functionalGroups),
}