import { createSelector, createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { loadItemActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { LoadItemArgs, RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { RootState } from "../store/healthRecordStore";
import { Models } from "../../types/models";
import { isBefore, isAfter } from "date-fns";

export interface BedOccupanciesState extends RemoteState<ViewModels.BedOccupancyViewModel, {}> {
}

const initialState: BedOccupanciesState = {
    ...defaultRemoteInitialState(),
};

export const bedOccupanciesSlice = createSlice({
    name: 'bedOccupancies',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

export const bedOccupanciesActions = {
    ...createRestApiActions(
        'bedOccupancies', 
        bedOccupanciesSlice.actions,
        state => state.bedOccupancies,
        defaultQueryBuilder,
        _ => true
    ),
    loadBedOccupanciesForPerson: loadItemActionBuilder(
        (args: LoadItemArgs) => `api/persons/${args!.itemId}/bedOccupancies`,
        _ => ({}),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.setItems
    ),
    loadBedOccupanciesForInstitution: loadItemActionBuilder(
        args => `api/institutions/${args.itemId}/bedoccupancies`,
        _ => ({}),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    ),
    loadBedOccupanciesForDepartment: loadItemActionBuilder(
        args => `api/departments/${args.itemId}/bedoccupancies`,
        _ => ({}),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    ),
    loadBedOccupanciesForRoom: loadItemActionBuilder(
        args => `api/rooms/${args.itemId}/bedoccupancies`,
        _ => ({}),
        () => resolveText("BedOccupancies_CouldNotLoad"),
        bedOccupanciesSlice.actions.setIsLoading,
        bedOccupanciesSlice.actions.addOrUpdateItems
    )
};
const isCurrentBedOccupancy = (x: Models.BedManagement.BedOccupancy, personId: string, now: Date) => {
    if(x.personId !== personId) {
        return false;
    }
    if(isBefore(now, new Date(x.startTime))) {
        return false;
    }
    if(x.endTime && isAfter(now, new Date(x.endTime))) {
        return false;
    }
    return true;
}
export interface SelectBedOccupanciesForRoomArgs {
    roomId?: string;
    bedPosition?: string;
    now: Date;
}
export interface SelectCurrentBedOccupanciesForPersonArgs {
    personId: string;
    now: Date;
}
export const bedOccupanciesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.bedOccupancies),
    createSelectForDepartment: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: { departmentId: string | undefined }) => args.departmentId,
        (items, departmentId) => departmentId 
            ? items.filter(x => x.departmentId === departmentId)
            : []
    ),
    createSelectForRoom: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.roomId,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.bedPosition,
        (_: RootState, args: SelectBedOccupanciesForRoomArgs) => args.now.getTime(),
        (items, roomId, bedPosition, now) => roomId && now
            ? items.filter(x => 
                x.room?.id === roomId
                && (!bedPosition || x.bedPosition === bedPosition)
                && (!x.endTime || new Date(x.endTime).getTime() > now))
            : []
    ),
    createSelectCurrentForPerson: () => createSelector(
        (state: RootState) => state.bedOccupancies.items,
        (_: RootState, args: SelectCurrentBedOccupanciesForPersonArgs) => args.personId,
        (_: RootState, args: SelectCurrentBedOccupanciesForPersonArgs) => args.now,
        (items, personId, now) => personId && now
            ? items.find(x => isCurrentBedOccupancy(x, personId, now))
            : undefined
    ),
};