import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface RolesState extends RemoteState<Models.AccessControl.Role, {}> {
}
const initialState: RolesState = {
    ...defaultRemoteInitialState(),
}
export const rolesSlice = createSlice({
    name: 'roles',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const roleFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const roleQueryBuilder = (state: RootState, sliceState: RolesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, roleFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const rolesActions = {
    ...createRestApiActions(
        'roles', 
        rolesSlice.actions, 
        state => state.roles, 
        roleQueryBuilder,
        roleFilterComparer
    ),
};
export const rolesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.roles),
};