import { useContext } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import FeatureContext from '../../contexts/FeatureContext';

interface FinancialMenuProps {}

export const FinancialMenu = (props: FinancialMenuProps) => {

    const features = useContext(FeatureContext);
    const navigate = useNavigate();

    if(!features.enableInvoicing) {
        return null;
    }
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_Financial")} id="menu-financial">
                <NavDropdown.Item onClick={() => navigate("/invoices")}>{resolveText("Menu_Invoices")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}