import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { Models } from '../../types/models';

interface RoomAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Room> {
    departmentId?: string;
}

export const RoomAutocomplete = (props: RoomAutocompleteProps) => {
    const { departmentId } = props;

    return (
        <GenericAutocomplete<Models.Room>
            {...props}
            apiPath={departmentId ? `api/departments/${departmentId}/rooms` : 'api/rooms'}
            displayFunc={x => x.name}
            minSearchTextLength={1}
        />
    );

}