import './localComponents/styles/index.css';
import './sharedCommonComponents/styles/common.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "flatpickr/dist/themes/light.css";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import healthRecordStore from './localComponents/redux/store/healthRecordStore';
import App from './App';
import { initializeApp } from './localComponents/helpers/Startup';


initializeApp();
const root = createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={healthRecordStore}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>
);
