import { AccountType, InstitutionLocationType, JanKisNotificationType, JanKisSubscriptionType, ServiceAudienceType, SharedNotificationType } from "../types/enums";
import { Models } from "../types/models";
import { ViewModels } from "../types/viewModels";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { formatPerson, formatPersonName, formatSubscription as formatSharedSubscription } from "../../sharedHealthComponents/helpers/Formatters";
import { getPerson } from "./UserHelpers";

export const formatUser = (user: ViewModels.IUserViewModel) => {
    return formatPersonName(getPerson(user)!);
}
export const formatServiceAudience = (item: ViewModels.IServiceAudienceViewModel) => {
    if(item.type === ServiceAudienceType.All) {
        return resolveText('ServiceAudienceType_All');
    }
    if(item.type === ServiceAudienceType.Role) {
        const roleAudience = item as ViewModels.RoleServiceAudienceViewModel;
        return `${resolveText('ServiceAudienceType_Role')}: ${roleAudience.role.name}`;
    }
    if(item.type === ServiceAudienceType.Person) {
        const personAudience = item as ViewModels.PersonServiceAudienceViewModel;
        return `${resolveText('ServiceAudienceType_Person')}: ${formatPerson(personAudience.person)}`;
    }
    if(item.type === ServiceAudienceType.Institution) {
        const institutionAudience = item as ViewModels.InstitutionServiceAudienceViewModel;
        return `${resolveText('ServiceAudienceType_Institution')}: ${institutionAudience.institution.name}`;
    }
    if(item.type === ServiceAudienceType.Department) {
        const departmentAudience = item as ViewModels.DepartmentServiceAudienceViewModel;
        return `${resolveText('ServiceAudienceType_Department')}: ${departmentAudience.department.name}`;
    }
    throw new Error(`Formatting of service audience type '${item.type}' not implemented`);
}

export const formatLocation = (location: ViewModels.LocationViewModel) => {
    switch(location.type) {
        case InstitutionLocationType.Department:
            return `${resolveText(`InstitutionLocationType_${location.type}`)} ${location.department?.name ?? location.id}`;
        case InstitutionLocationType.Room:
            return `${resolveText(`InstitutionLocationType_${location.type}`)} ${location.room?.name ?? location.id}`;
    }
    return `${resolveText(`InstitutionLocationType_${location.type}`)} ${location.id}`;
}
export const formatStock = (stock: ViewModels.StockViewModel) => {
    return `${stock.name} (${resolveText('Department')} ${stock.department.name})`;
}
export const formatBed = (room: Models.Room, bedPosition: string) => {
    return `${room.name}${bedPosition}`;
}
export const formatMaterial = (material: Models.MaterialReference) => {
    return material.name;
}
export const formatMoney = (money: Models.Financial.Money) => {
    return `${money.value} ${money.currency}`;
}
export const formatAccount = (account: ViewModels.EmployeeAccountViewModel | ViewModels.PatientAccountViewModel | ViewModels.DeviceAccountViewModel) => {
    switch(account.accountType) {
        case AccountType.Employee:
        case AccountType.Patient:
            const personAccount = account as ViewModels.EmployeeAccountViewModel | ViewModels.PatientAccountViewModel;
            return formatPersonName(personAccount.person);
        case AccountType.Device:
            const deviceAccount = account as ViewModels.DeviceAccountViewModel;
            return deviceAccount.name;
        default:
            throw new Error(`Unknown account type ${account.accountType}`);
    }
}
export const formatNotificationType = (notificationType: string) => {
    if(Object.keys(SharedNotificationType).includes(notificationType)) {
        return resolveText(`SharedNotificationType_${notificationType}`);
    }
    if(Object.keys(JanKisNotificationType).includes(notificationType)) {
        return resolveText(`JanKisNotificationType_${notificationType}`);
    }
    return notificationType;
}
export const formatSubscription = (subscription: Models.Subscriptions.Subscription) => {
    switch(subscription.type) {
        case JanKisSubscriptionType.MaterialOrder:
            const materialOrderSubscriptionViewModel = subscription as ViewModels.Subscriptions.MaterialOrderSubscriptionViewModel;
            return formatMaterial(materialOrderSubscriptionViewModel.material);
        case JanKisSubscriptionType.Resource:
            const resourceSubscriptionViewModel = subscription as ViewModels.Subscriptions.ResourceSubscriptionViewModel;
            return resourceSubscriptionViewModel.resourceName;
        case JanKisSubscriptionType.Stock:
            const stockSubscriptionViewModel = subscription as ViewModels.Subscriptions.StockSubscriptionViewModel;
            return `${stockSubscriptionViewModel.stockName} (${stockSubscriptionViewModel.departmentName})`;
    }
    return formatSharedSubscription(subscription);
}