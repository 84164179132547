import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import DepartmentIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/DepartmentIdAutocomplete";
import { Models } from "../../types/models";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";

interface DepartmentSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.DepartmentSubscription> {
}

export const DepartmentSubscriptionEditor = (props: DepartmentSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Department")}</FormLabel>
            <DepartmentIdAutocomplete
                value={subscription.departmentId}
                onChange={departmentId => onChange(state => ({
                    ...state,
                    departmentId: departmentId
                } as Models.Subscriptions.DepartmentSubscription))}
            />
        </FormGroup>
    </>);

}