import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { ViewModels } from '../../../localComponents/types/viewModels';
import { Autocomplete } from '../../../sharedCommonComponents/components/Autocompletes/Autocomplete';
import { AutocompleteRunner } from '../../../sharedCommonComponents/helpers/AutocompleteRunner';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { SnomedCtConcepts } from '../../helpers/SpecialSnomedCtConcepts';
import { SnomedCtConceptFinderModal } from '../../modals/SnomedCtConceptFinderModal';
import { useAppDispatch } from '../../../localComponents/redux/store/healthRecordStore';
import { toSnomedCtConceptViewModel } from '../../helpers/ClassificationReferenceHelpers';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { classificationsSelectors, classificationsActions } from '../../redux/slices/classificationsSlice';
import { ClassificationReferenceSuggestions } from './ClassificationReferenceSuggestions';
import { ClassificationType } from '../../../localComponents/types/enums';

interface SnomedCtAutoCompleteProps {
    isLoading?: boolean;
    searchText?: string;
    value?: ViewModels.SnomedCtConceptViewModel;
    parentConceptId?: string;
    onChange: (service: ViewModels.SnomedCtConceptViewModel | undefined) => void;
    resetOnSelect?: boolean;
    hideBrowseButton?: boolean;
    suggestedConceptIds?: string[];
    autocompleteContext?: string;
    maxSuggestions?: number;
    required?: boolean;
    disabled?: boolean;
}

export const SnomedCtAutoComplete = (props: SnomedCtAutoCompleteProps) => {

    const { 
        isLoading,
        searchText,
        value,
        parentConceptId,
        onChange,
        resetOnSelect,
        hideBrowseButton,
        suggestedConceptIds,
        autocompleteContext,
        maxSuggestions,
        required,
        disabled
    } = props;
    const snomedCtAutocompleteRunner = useMemo(() => {
        const apiPath = parentConceptId === SnomedCtConcepts.DRUG_DISPENSIONFORMS_CONCEPTID ? 'api/classifications/snomedct/dispensionforms'
        : parentConceptId === SnomedCtConcepts.DRUG_APPLICATIONROUTES_CONCEPTID ? 'api/classifications/snomedct/applicationroutes'
        : 'api/classifications/snomedct'
        return new AutocompleteRunner<ViewModels.SnomedCtConceptViewModel>(apiPath, 'searchText', 10);
    }, [ parentConceptId ]);
    const [ showFinderModal, setShowFinderModal ] = useState<boolean>(false);
    const suggestions = useMemorizedSelector(classificationsSelectors.createSelectSnomedCtConcepts, { ids: suggestedConceptIds ?? [] });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!suggestedConceptIds || suggestedConceptIds.length === 0) {
            return;
        }
        dispatch(classificationsActions.loadSnomedCtConceptsIfNotLoaded({
            args: {
                conceptIds: suggestedConceptIds
            }
        }));
    }, [ suggestedConceptIds ]);

    if(value || isLoading) {
        return (<Alert 
            variant="info"
            dismissible={!disabled}
            onClose={() => onChange(undefined)}
        >
            {isLoading 
            ? resolveText('Loading...') 
            : value!.terms[0]}
        </Alert>);
    }
    return (<>
        <Row>
            <Col>
                <Autocomplete
                    defaultValue={searchText}
                    search={snomedCtAutocompleteRunner.search}
                    displayNameSelector={x => `${x.conceptId} - ${x.terms[0]}`}
                    onItemSelected={onChange}
                    resetOnSelect={resetOnSelect}
                    required={required}
                    disabled={disabled}
                />
            </Col>
            {!hideBrowseButton
            ? <Col xs="auto">
                <Button
                    onClick={() => setShowFinderModal(true)}
                    disabled={disabled}
                >
                    {resolveText("Browse...")}
                </Button>
                <SnomedCtConceptFinderModal 
                    show={showFinderModal}
                    onClose={() => setShowFinderModal(false)} 
                    initialConceptId={parentConceptId ?? SnomedCtConcepts.ROOT_CONCEPTID}
                    onConceptSelected={concept => {
                        onChange(concept);
                        setShowFinderModal(false);
                    }}
                />
            </Col>
            : null}
        </Row>
        {!value && !disabled
        ? <ClassificationReferenceSuggestions
            context={autocompleteContext}
            classification={ClassificationType.SnomedCt}
            maxCount={maxSuggestions}
            additionalSuggestions={suggestions}
            onSuggestionSelected={suggestion => {
                onChange(toSnomedCtConceptViewModel(suggestion));
            }}
        /> : null}
    </>);

}