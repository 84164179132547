import { ViewModels } from "../../../localComponents/types/viewModels";
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";

interface ServiceRequestAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.ServiceRequestViewModel> {
    serviceId?: string;
}

export const ServiceRequestAutocomplete = (props: ServiceRequestAutocompleteProps) => {
    const { serviceId } = props;

    return (<GenericAutocomplete
        {...props}
        apiPath={serviceId ? `api/services/${serviceId}/requests` : 'api/serviceRequests'}
        displayFunc={x => x.id}
    />);

}