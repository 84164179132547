import { FormEvent, useContext, useState } from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { apiClient } from '../../../sharedCommonComponents/communication/ApiClient';
import { AsyncButton } from '../../../sharedCommonComponents/components/AsyncButton';
import { showErrorAlert, showSuccessAlert } from '../../../sharedCommonComponents/helpers/AlertHelpers';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { Models } from '../../types/models';
import PasswordFormControl from '../../../sharedCommonComponents/components/FormControls/PasswordFormControl';
import UserContext from '../../contexts/UserContext';

interface ChangePasswordFormProps {
    username: string;
    onPasswordChanged?: () => void;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {

    const user = useContext(UserContext);
    const { username, onPasswordChanged } = props;
    const [ currentPassword, setCurrentPassword ] = useState<string>('');
    const [ newPassword, setNewPassword ] = useState<string>('');
    const [ newPasswordRepeat, setNewPasswordRepeat ] = useState<string>('');
    const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

    const submit = async (e?: FormEvent) => {
        e?.preventDefault();
        setIsSubmitting(true);
        try {
            const body: Models.RequestBodies.ChangePasswordRequest = {
                currentPassword: currentPassword,
                newPassword: newPassword
            };
            await apiClient.instance!.post(`api/accounts/${username}/changepassword`, body);
            showSuccessAlert(resolveText("Account_SuccessfullyChangedPassword"));
            if(onPasswordChanged) {
                onPasswordChanged();
            }
        } catch(error: any) {
            showErrorAlert(resolveText("Account_CouldNotChangePassword"));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Form 
            validated={user?.userSettings.forms.alwaysShowValidation} 
            onSubmit={submit}
        >
            <FormGroup>
                <FormLabel>{resolveText("Account_CurrentPassword")}</FormLabel>
                <PasswordFormControl required
                    id="current-password"
                    name="current-password"
                    value={currentPassword}
                    onChange={(e:any) => setCurrentPassword(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>{resolveText("Account_NewPassword")}</FormLabel>
                <PasswordFormControl required
                    id="new-password"
                    name="new-password"
                    value={newPassword}
                    onChange={(e:any) => setNewPassword(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>{resolveText("Account_NewPasswordRepeat")}</FormLabel>
                <PasswordFormControl required
                    id="new-password2"
                    name="new-password2"
                    value={newPasswordRepeat}
                    onChange={(e:any) => setNewPasswordRepeat(e.target.value)}
                    isInvalid={newPasswordRepeat !== newPassword}
                />
                <FormControl.Feedback type='invalid'>{resolveText("Register_PasswordsDontMatch")}</FormControl.Feedback>
            </FormGroup>
            <AsyncButton
                type='submit'
                isExecuting={isSubmitting}
                activeText={resolveText("Submit")}
                executingText={resolveText("Submitting...")}
                disabled={!currentPassword || !newPassword || newPasswordRepeat !== newPassword}
                className="m-3"
            />
        </Form>
    );

}