import { Navigate } from "react-router-dom";

interface RedirectToLoginProps {}

export const RedirectToLogin = (props: RedirectToLoginProps) => {

    const requestedPath = window.location.pathname + window.location.search;
    if(window.location.pathname === '/login') {
        return null;
    }

    return (<Navigate to={`/login?redirectUrl=${requestedPath}`} />);

}
export default RedirectToLogin;