import './sharedHealthComponents/styles/healthrecord.css';

import { useState, useEffect, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ViewModels } from './localComponents/types/viewModels';
import { apiClient } from './sharedCommonComponents/communication/ApiClient';
import { resolveText } from './sharedCommonComponents/helpers/Globalizer';
import { reset, useAppDispatch, useAppSelector } from './localComponents/redux/store/healthRecordStore';
import { healthRecordEntrySliceActions } from './sharedHealthComponents/redux/slices/healthRecordsSlice';
import { ProfessionalPortalRoutes } from './localComponents/navigation/ProfessionalPortalRoutes';
import { NotLoggedInRoutes } from './localComponents/navigation/NotLoggedInRoutes';
import { AccountType } from './localComponents/types/enums';
import PatientPortalRoutes from './localComponents/navigation/PatientPortalRoutes';
import { EmployeeLayout } from './localComponents/components/EmployeeLayout';
import { PatientPortalLayout } from './localComponents/components/PatientPortal/PatientPortalLayout';
import UserContext from './localComponents/contexts/UserContext';
import { NotLoggedInLayout } from './localComponents/components/NotLoggedInLayout';
import FeatureContext, { noFeatures } from './localComponents/contexts/FeatureContext';
import { buildLoadObjectFunc } from './sharedCommonComponents/helpers/LoadingHelpers';
import RoutesBuilder from './sharedCommonComponents/navigation/RoutesBuilder';
import { Models } from './localComponents/types/models';
import { RouteDefinition } from './sharedCommonComponents/types/frontendTypes';
import PageContainer from './sharedCommonComponents/components/PageContainer';
import { Col, Container, Row } from 'react-bootstrap';
import { accessTokenSessionStorageKey, userSessionStorageKey, featuresSessionStorageKey, csrfTokenSessionStorageKey } from './sharedCommonComponents/helpers/Constants';
import { lockScreen } from './sharedHealthComponents/helpers/ScreenLockHelpers';
import { PasswordChangeModal } from './localComponents/modals/PasswordChangeModal';
import { sessionSlice } from './localComponents/redux/slices/sessionSlice';
import { getSessionStorageItem } from './sharedCommonComponents/helpers/BrowserStorageHelpers';
import { Session } from './localComponents/types/frontendTypes';
import { LoadingAlert } from './sharedCommonComponents/components/LoadingAlert';
import { institutionsActions } from './localComponents/redux/slices/institutionsSlice';
import { departmentsSlice } from './localComponents/redux/slices/departmentsSlice';


interface AppProps {}
export const App = (props: AppProps) => {

    const [ features, setFeatures ] = useState<Models.Configuration.FeatureSettings>(
        !!sessionStorage.getItem(featuresSessionStorageKey) 
            ? JSON.parse(sessionStorage.getItem(featuresSessionStorageKey)!)
            : noFeatures
        );
    const [ hasLoadedSession, setHasLoadedSession ] = useState<boolean>(false);
    const session = useAppSelector(state => state.session);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onNewAuthenticationResult = useCallback((authenticationResult: Models.AccessControl.AuthenticationResult) => {
        if (authenticationResult.isAuthenticated) {
            apiClient.instance!.isLoggedIn = true;
            apiClient.instance!.csrfToken = authenticationResult.csrfToken;
            apiClient.instance!.accessToken = authenticationResult.jwt!.rawAccessToken;
            sessionStorage.setItem(csrfTokenSessionStorageKey, authenticationResult.csrfToken!);
            sessionStorage.setItem(accessTokenSessionStorageKey, authenticationResult.jwt!.rawAccessToken);
            dispatch(sessionSlice.actions.setIsPasswordChangeRequired(authenticationResult.isPasswordChangeRequired));
        }
    }, [ dispatch ]);

    const navigateToEntryPage = useCallback((user: ViewModels.IUserViewModel, isPasswordChangeRequired: boolean, redirectUrl?: string) => {
        if(isPasswordChangeRequired) {
            navigate('/');
        } else if(redirectUrl) {
            navigate(redirectUrl);
        } else if(user.accountType === AccountType.Employee) {
            const employeeUserViewModel = user as ViewModels.EmployeeUserViewModel;
            const userSettings = employeeUserViewModel.userSettings as Models.Configuration.UserSettings;
            if(userSettings.restoreLastUrl) {
                navigate(userSettings.lastUrl ?? userSettings.entryUrl);
            } else {
                navigate(userSettings.entryUrl ?? '/');
            }
        } else {
            navigate('/');
        }
    }, [ navigate ]);
    
    const onLoggedIn = useCallback((loginResult: Models.AccessControl.LoginResult, redirectUrl?: string) => {
        onNewAuthenticationResult(loginResult.authenticationResult);
        const { authenticationResult, user } = loginResult;
        dispatch(sessionSlice.actions.setLoggedInUser(user));
        dispatch(healthRecordEntrySliceActions.loadHealthRecordViewSettings({}));
        dispatch(institutionsActions.loadItems({}));
        if(user.accountType === AccountType.Employee) {
            const employee = user as ViewModels.EmployeeUserViewModel;
            dispatch(departmentsSlice.actions.addOrUpdateItems(employee.departments));
        }
        navigateToEntryPage(user, authenticationResult.isPasswordChangeRequired, redirectUrl);
    }, [ dispatch, onNewAuthenticationResult, navigateToEntryPage ]);

    const onLogOut = useCallback(async () => {
        if(session.loggedInUser && session.loggedInUser.accountType === AccountType.Employee) {
            try {
                // Set last URL
                const lastUrl = window.location.pathname + window.location.search;
                await apiClient.instance!.post('api/accounts/me/settings/lastUrl', `"${lastUrl}"`);
            } catch {
                // Ignore
            }
        }
        try {
            await apiClient.instance!.logOut();
        } catch {
            // Ignore
        }
        sessionStorage.removeItem(userSessionStorageKey);
        dispatch(reset());
        navigate("/");
    }, [ dispatch, navigate, session ]);

    useEffect(() => {
        if(hasLoadedSession) {
            return;
        }
        const storedSession = getSessionStorageItem<Session>(userSessionStorageKey);
        if(storedSession) {
            dispatch(sessionSlice.actions.setSession(storedSession));
            if(storedSession.loggedInUser) {
                //navigateToEntryPage(storedSession.loggedInUser, storedSession.isPasswordChangeRequired ?? false);
            }
        }
        setHasLoadedSession(true);
    }, [ dispatch, hasLoadedSession, navigateToEntryPage ]);

    useEffect(() => {
        if(hasLoadedSession) {
            sessionStorage.setItem(userSessionStorageKey, JSON.stringify(session));
        }
    }, [ hasLoadedSession, session ]);
    
    const onKeyPress = useCallback((e: KeyboardEvent) => {
        if(e.key === 'l' && e.ctrlKey && e.altKey) {
            lockScreen(dispatch, navigate);
        }
        else if(e.key === 'Backspace' && e.altKey) {
            navigate(-1);
        }
        else if(e.key === 'h' && e.altKey) {
            const userSettings = (session.loggedInUser as ViewModels.EmployeeUserViewModel)?.userSettings as Models.Configuration.UserSettings;
            navigate(userSettings?.entryUrl ?? '/');
        }
    }, [ navigate, dispatch, session ]);

    useEffect(() => {
        const loadFeatures = buildLoadObjectFunc(
            'api/configuration/features', {},
            resolveText("Features_CouldNotLoad"),
            setFeatures
        );
        loadFeatures();
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', onKeyPress);
        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [ onKeyPress ]);

    if(!hasLoadedSession) {
        return (<LoadingAlert />);
    }

    if(session.isPasswordChangeRequired && session.loggedInUser) {
        return (<PasswordChangeModal
            loginId={session.loggedInUser.loginId}
            onPasswordChanged={onLogOut}
        />);
    }

    let routes: RouteDefinition[] = [];
    let Layout = null;
    let containerBuilder: (children: ReactNode) => ReactNode;
    if (!session.loggedInUser) {
        routes = NotLoggedInRoutes({ onLoggedIn });
        Layout = NotLoggedInLayout;
        containerBuilder = children => (<Container>
            <Row>
                <Col>
                    {children}
                </Col>
            </Row>
        </Container>)
    } else {
        switch(session.loggedInUser.accountType) {
            case AccountType.Employee:
                routes = ProfessionalPortalRoutes(features, onNewAuthenticationResult, onLogOut);
                Layout = EmployeeLayout;
                containerBuilder = children => (
                    <PageContainer
                        className='mt-3'
                    >
                        {children}
                    </PageContainer>
                );
                break;
            case AccountType.Patient:
                routes = PatientPortalRoutes(features, onNewAuthenticationResult, onLogOut);
                Layout = PatientPortalLayout;
                containerBuilder = children => (
                    <PageContainer
                        className='mt-3'
                    >
                        {children}
                    </PageContainer>
                );
                break;
            default:
                throw new Error(`Unknown account type ${session.loggedInUser.accountType}`);
        }
    }

    return (
    <FeatureContext.Provider value={features}>
        <UserContext.Provider value={session.loggedInUser}>
            <Layout onLogOut={onLogOut}>
                <RoutesBuilder
                    routeDefinitions={routes}
                    containerBuilder={containerBuilder}
                />
            </Layout>
        </UserContext.Provider>
    </FeatureContext.Provider>);
}



export default App;
