import React, { Suspense, useContext, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingAlert } from '../../sharedCommonComponents/components/LoadingAlert';
import UserContext from '../contexts/UserContext';
import { QRScannerModal } from '../modals/QRScannerModal';
import { departmentsActions } from '../redux/slices/departmentsSlice';
import { useAppDispatch } from '../redux/store/healthRecordStore';
import { CommonMenu } from './Menus/CommonMenu';
import { ConfigMenu } from './Menus/ConfigMenu';
import { DepartmentsMenu } from './Menus/DepartmentsMenu';
import { FinancialMenu } from './Menus/FinancialMenu';
import { LoggedInUser } from './Menus/LoggedInUser';
import { NurseMenu } from './Menus/NurseMenu';
import { QRScannerMenu } from './Menus/QRScannerMenu';
import { RegistrationMenu } from './Menus/RegistrationMenu';
import { ViewModels } from '../types/viewModels';
import { getAllRoles } from '../helpers/UserHelpers';
import { LockedOverlay } from '../../sharedHealthComponents/components/UserManagement/LockedOverlay';
import { HealthRecordSearchSelfcontained } from '../../sharedHealthComponents/components/Patients/HealthRecordSearchSelfcontained';
import { FeedbackIcon } from '../../sharedCommonComponents/components/FeedbackIcon';

interface EmployeeLayoutProps extends React.PropsWithChildren<{}> {
    onLogOut: () => void;
}

export const EmployeeLayout = (props: EmployeeLayoutProps) => {

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(departmentsActions.loadServiceDepartments({ args: { itemId: 'not used' }}));
    }, [ dispatch ]);

    const navigate = useNavigate();
    const user = useContext(UserContext)! as ViewModels.EmployeeUserViewModel;
    let userMenus = getAllRoles(user).map(role => {
        switch(role.name.toLowerCase()) {
            case "registration":
                return (<RegistrationMenu key="registration" />);
            case "nurse":
                return (<NurseMenu key="nurse" />);
            case "controlling":
                return (<FinancialMenu key="controlling" />);
            default:
                return null;
        }
    }).filter(x => x !== null);
    
    const [ showQRScannerModal, setShowQRScannerModal ] = useState<boolean>(false);
    const openQRScannerModal = () => {
        setShowQRScannerModal(true);
    }

    return (
        <>
            <ToastContainer
                theme='colored'
            />
            <HealthRecordSearchSelfcontained />
            <Navbar id='main-navbar' bg="light" expand="xl" sticky="top">
                <Navbar.Brand className="ms-2 clickable" onClick={() => navigate('/')}>
                    <i className='fa fa-hospital-o me-2' />
                    <strong>JanKIS</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav no-print">
                    <CommonMenu />
                    {userMenus}
                    <DepartmentsMenu />
                    <ConfigMenu />
                    <QRScannerMenu openQRScannerModal={openQRScannerModal}  />
                    <LoggedInUser 
                        onLogOut={props.onLogOut}
                    />
                </Navbar.Collapse>
            </Navbar>
            <Suspense fallback={<LoadingAlert />}>
                {props.children}
            </Suspense>
            <QRScannerModal show={showQRScannerModal} onHide={() => setShowQRScannerModal(false)} />
            <FeedbackIcon />
            <LockedOverlay />
        </>
    );

}