import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { ServiceIdAutocomplete } from "../../../sharedHealthComponents/components/Autocompletes/ServiceIdAutocomplete";

interface ServiceSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.ServiceSubscription> {
}

export const ServiceSubscriptionEditor = (props: ServiceSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Service")}</FormLabel>
            <ServiceIdAutocomplete
                value={subscription.serviceId}
                onChange={serviceId => onChange(state => ({
                    ...state,
                    serviceId: serviceId
                } as Models.Subscriptions.ServiceSubscription))}
            />
        </FormGroup>
    </>);

}