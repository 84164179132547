import { resolveText, defaultGlobalizer } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Language } from "../../types/enums";
import { PrivacyPolicyDe } from "./PrivacyPolicy_DE";
import { PrivacyPolicyEn } from "./PrivacyPolicy_EN";

interface PrivacyPolicyProps {}

export const PrivacyPolicy = (props: PrivacyPolicyProps) => {

    return (
        <>
            <h1>{resolveText("Privacy")}</h1>
            {defaultGlobalizer.instance!.preferredLanguage === Language.de ? <PrivacyPolicyDe />
            : <PrivacyPolicyEn />}
        </>
    );

}