import { ReactNode, useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Point2D } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { getRoomAtPosition, isInsideExistingRoom } from "../../helpers/DepartmentRoomLayoutHelpers";

interface DepartmentRoomLayoutGridEditorProps {
    gridSize: Point2D;
    rooms: Models.Organizations.RoomGridPosition[];
    roomNames: { [roomId:string]: string },
    onSelectionChanged: (selection?: GridSelection) => void;
}

export interface GridSelection {
    start: Point2D;
    end: Point2D;
}

export const DepartmentRoomLayoutGridEditor = (props: DepartmentRoomLayoutGridEditorProps) => {

    const { gridSize, rooms, roomNames, onSelectionChanged } = props;

    const [ selection, setSelection ] = useState<GridSelection>();
    const [ isSelectionInProgress, setIsSelectionInProgress ] = useState<boolean>(false);

    useEffect(() => {
        if(!isSelectionInProgress) {
            if(!selection) {
                onSelectionChanged(undefined);
                return;
            }
            const isStartAndEndAlreadySorted = selection.start.x < selection.end.x || selection.start.y < selection.end.y;
            const selectionStart = isStartAndEndAlreadySorted ? selection.start : selection.end;
            const selectionEnd = isStartAndEndAlreadySorted ? selection.end : selection.start;
            onSelectionChanged({
                start: selectionStart,
                end: selectionEnd
            });
            setSelection(undefined);
        }
    }, [ isSelectionInProgress ]);

    const isInSelection = useCallback((columnIndex: number, rowIndex: number, selection?: GridSelection) => {
        if(!selection) {
            return false;
        }
        const minX = Math.min(selection.start.x, selection.end.x);
        const maxX = Math.max(selection.start.x, selection.end.x);
        const minY = Math.min(selection.start.y, selection.end.y);
        const maxY = Math.max(selection.start.y, selection.end.y);
        return columnIndex >= minX && columnIndex <= maxX
            && rowIndex >= minY && rowIndex <= maxY;
    }, []);

    const isOverlappingExistingRooms = useCallback((selection: GridSelection) => {
        const selectionMinX = Math.min(selection.start.x, selection.end.x);
        const selectionMaxX = Math.max(selection.start.x, selection.end.x);
        const selectionMinY = Math.min(selection.start.y, selection.end.y);
        const selectionMaxY = Math.max(selection.start.y, selection.end.y);
        for (const room of rooms) {
            if(room.start.x > selectionMaxX) {
                continue; // Room is right of selection
            }
            if(room.end.x < selectionMinX) {
                continue; // Room is left of selection
            }
            if(room.start.y > selectionMaxY) {
                continue; // Room is under selection
            }
            if(room.end.y < selectionMinY) {
                continue; // Room is over selection
            }
            return true;
        }
        return false;
    }, [ rooms ]);

    const extendSelectionIfNotOverlappingOccupiedCells = useCallback((rowIndex: number, columnIndex: number) => {
        setSelection(state => {
            if(!state) {
                return undefined;
            }
            const newSelection = {
                ...state,
                end: {
                    x: columnIndex,
                    y: rowIndex
                }
            };
            if(isOverlappingExistingRooms(newSelection)) {
                return state; // Do not extend selection
            } else {
                return newSelection;
            }
        });
    }, [ isOverlappingExistingRooms ]);

    const tableRows: ReactNode[] = [];
    for (let rowIndex = 0; rowIndex < gridSize.y; rowIndex++) {
        const cells: ReactNode[] = [];
        for (let columnIndex = 0; columnIndex < gridSize.x; columnIndex++) {
            const roomAtPosition = getRoomAtPosition(rooms, rowIndex, columnIndex);
            const isThisCellInSelection = isInSelection(columnIndex, rowIndex, selection);
            if(roomAtPosition) {
                const isUpperLeftCornerOfRoom = roomAtPosition.start.x === columnIndex && roomAtPosition.start.y === rowIndex;
                if(isUpperLeftCornerOfRoom) {
                    const roomWidth = roomAtPosition.end.x - roomAtPosition.start.x + 1;
                    const roomHeight = roomAtPosition.end.y - roomAtPosition.start.y + 1;
                    const roomCell = (<td
                        key={"Room-" + roomAtPosition.roomId}
                        rowSpan={roomHeight}
                        colSpan={roomWidth}
                        className="border-dark border-2 bg-warning text-center"
                        style={{
                            width: `${roomWidth * 50}px`,
                            height: `${roomHeight * 50}px`,
                            verticalAlign: 'middle',
                        }}
                    >
                        {roomNames[roomAtPosition.roomId] ?? 'ROOM'}
                    </td>);
                    cells.push(roomCell);
                }
            } else {
                const unoccupiedCell = (<td
                    key={columnIndex}
                    className={`text-center ${isThisCellInSelection ? 'bg-primary' : ''}`}
                    style={{
                        width: '50px',
                        height: '50px'
                    }}
                    onPointerDown={e => {
                        if(isSelectionInProgress) { // If pointer left grid and onPointerUp was missed, ignore new onPointerDown and continue selection
                            return;
                        }
                        if(isInsideExistingRoom(rooms, rowIndex, columnIndex)) {
                            return;
                        }
                        if(isThisCellInSelection) {
                            setSelection(undefined);
                            setIsSelectionInProgress(false);
                        } else {
                            setSelection({
                                start: {
                                    x: columnIndex,
                                    y: rowIndex
                                },
                                end: {
                                    x: columnIndex,
                                    y: rowIndex
                                }
                            });
                            setIsSelectionInProgress(true);
                        }
                    }}
                    onPointerEnter={e => {
                        if(!isSelectionInProgress) {
                            return;
                        }
                        extendSelectionIfNotOverlappingOccupiedCells(rowIndex, columnIndex);
                    }}
                    onPointerUp={e => {
                        if(!isSelectionInProgress) {
                            return;
                        }
                        extendSelectionIfNotOverlappingOccupiedCells(rowIndex, columnIndex);
                        setIsSelectionInProgress(false);
                    }}
                >
                    {/* <small>({columnIndex},{rowIndex})</small> */}
                </td>);
                cells.push(unoccupiedCell);
            }
            
        }
        const tableRow = (<tr 
            key={rowIndex}
        >
            {cells}
        </tr>);
        tableRows.push(tableRow);
    }
    
    return (
        <Table 
            bordered 
            className="mt-3" 
            style={{ 
                userSelect: 'none',
                cursor: 'default'
            }}
        >
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    );

}