import { Models } from '../../../localComponents/types/models';
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';

interface ServiceAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Services.ServiceDefinition> {
}

export const ServiceAutocomplete = (props: ServiceAutocompleteProps) => {

    return (
        <GenericAutocomplete
            {...props}
            apiPath='api/services'
            displayFunc={x => x.name}
        />
    )

}