import { useCallback, useEffect, useState } from "react";
import { Models } from "../../types/models";
import { Point2D } from "../../../sharedHealthComponents/types/frontendTypes";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Alert, Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { useMemorizedSelector } from "../../../sharedHealthComponents/redux/helpers/ReduxHooks";
import { departmentsActions, departmentsSelectors } from "../../redux/slices/departmentsSlice";
import { useAppDispatch } from "../../redux/store/healthRecordStore";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { showErrorAlert, showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { Center } from "../../../sharedCommonComponents/components/Center";
import { StoreButton } from "../../../sharedCommonComponents/components/StoreButton";
import { DepartmentRoomLayoutGridEditor, GridSelection } from "../../components/Departments/DepartmentRoomLayoutGridEditor";
import { toDictionary } from "../../../sharedCommonComponents/helpers/Transformations";

interface DepartmentRoomLayoutEditPageProps {}

export const DepartmentRoomLayoutEditPage = (props: DepartmentRoomLayoutEditPageProps) => {

    const { departmentId } = useParams();
    const department = useMemorizedSelector(departmentsSelectors.getById, { id: departmentId });
    const [ rooms, setRooms ] = useState<Models.Room[]>([]);
    const [ selectedRoom, setSelectedRoom ] = useState<Models.Room>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ gridSize, setGridSize ] = useState<Point2D>({ x: 12, y: 12 });
    const [ roomGridPositions, setRoomGridPositions ] = useState<Models.Organizations.RoomGridPosition[]>([]);
    const [ isStoring, setIsStoring ] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 

    useEffect(() => {
        if(!departmentId) {
            return;
        }
        dispatch(departmentsActions.loadItemIfNotLoadedYet({
            args: {
                itemId: departmentId
            }
        }));
    }, [ departmentId, dispatch ]);

    useEffect(() => {
        if(!departmentId) {
            return;
        }
        setIsLoading(true);
        const loadRooms = buildLoadObjectFunc<Models.Room[]>(
            `api/departments/${departmentId}/rooms`, {},
            resolveText("Department_CouldNotLoadRooms"),
            setRooms,
            undefined,
            () => setIsLoading(false)
        );
        loadRooms();
        const loadLayout = buildLoadObjectFunc<Models.Organizations.DepartmentRoomsLayout>(
            `api/departments/${departmentId}/room-layout`, {},
            resolveText("Department_CouldNotLoadLayout"),
            vm => {
                setGridSize({
                    x: vm.gridSizeX,
                    y: vm.gridSizeY
                });
                setRoomGridPositions(vm.rooms);
            },
            async response => {
                if(response?.status === 404) {
                    return; // Ignore missing layout
                }
                showErrorAlert(resolveText("Department_CouldNotLoadLayout"));
            },
            () => setIsLoading(false)
        );
        loadLayout();
    }, [ departmentId ]);

    const store = async () => {
        if(!department) {
            return;
        }
        const departmentLayout: Models.Organizations.DepartmentRoomsLayout = {
            id: department.id,
            institutionId: department.institutionId,
            departmentId: department.id,
            gridSizeX: gridSize.x,
            gridSizeY: gridSize.y,
            rooms: roomGridPositions
        };
        setIsStoring(true);
        await sendPostRequest(
            `api/departments/${department.id}/room-layout`, {},
            resolveText("Department_CouldNotStoreLayout"),
            departmentLayout,
            async () => {
                showSuccessAlert(resolveText("Department_SuccessfullyStoredLayout"));
                navigate(-1);
            },
            undefined,
            () => setIsStoring(false)
        );
    }

    const onGridSelectionChanged = useCallback((selection?: GridSelection) => {
        if(!selectedRoom) {
            return;
        }
        if(!selection) {
            setRoomGridPositions(state => state.filter(x => x.roomId !== selectedRoom.id));
            return;
        }
        setRoomGridPositions(state => {
            const newRoomGridPosition = {
                roomId: selectedRoom.id,
                start: selection.start,
                end: selection.end
            };
            if(state.some(x => x.roomId === selectedRoom.id)) {
                return state.map(room => room.roomId === selectedRoom.id ? newRoomGridPosition: room);
            } else {
                return state.concat(newRoomGridPosition);
            }
        });
        setSelectedRoom(undefined);
    }, [ selectedRoom ]);

    const clearRoomGridPosition = useCallback((roomId: string) => {
        setRoomGridPositions(state => state.filter(x => x.roomId !== roomId));
    }, []);

    if(isLoading) {
        return (<LoadingAlert />);
    }

    return (<>
        <h1>{resolveText("Department_Layout")}</h1>
        <FormGroup>
            <FormLabel>Grid</FormLabel>
            <Row>
                <FormLabel column xs="auto">Width</FormLabel>
                <Col>
                    <FormControl
                        type="number"
                        min={Math.max(1, ...roomGridPositions.map(x => x.end.x + 1))}
                        step={1}
                        value={gridSize.x}
                        onChange={e => setGridSize(state => ({
                            ...state,
                            x: Number(e.target.value)
                        }))}
                    />
                </Col>
                <FormLabel column xs="auto">Height</FormLabel>
                <Col>
                    <FormControl
                        type="number"
                        min={Math.max(1, ...roomGridPositions.map(x => x.end.y + 1))}
                        step={1}
                        value={gridSize.y}
                        onChange={e => setGridSize(state => ({
                            ...state,
                            y: Number(e.target.value)
                        }))}
                    />
                </Col>
            </Row>
        </FormGroup>

        <Row>
            <Col>
                <DepartmentRoomLayoutGridEditor
                    gridSize={gridSize}
                    rooms={roomGridPositions}
                    roomNames={toDictionary(rooms, x => x.id, x => x.name)}
                    onSelectionChanged={onGridSelectionChanged}
                />
            </Col>
            <Col xl={4}>
                <FormGroup className="my-2">
                    <FormLabel>{resolveText("Rooms")}</FormLabel>
                    {/* <FormControl
                        as="select"
                        value={selectedRoom?.id ?? ''}
                        onChange={e => setSelectedRoom(rooms.find(x => x.id === e.target.value))}
                    >
                        <option value="">{resolveText("PleaseSelect...")}</option>
                        {rooms.map(room => (
                            <option key={room.id} value={room.id}>{room.name}</option>
                        ))}
                    </FormControl> */}
                    {rooms.map(room => {
                        const cssClasses: string[] = [ 'clickable', 'my-1', 'py-1' ];
                        const isSelected = selectedRoom?.id === room.id;
                        if(isSelected) {
                            cssClasses.push('border-dark');
                            cssClasses.push('border-2');
                        };
                        const hasGridPosition = roomGridPositions.some(x => x.roomId === room.id);
                        return (
                            <Alert
                                className={cssClasses.join(' ')}
                                variant={hasGridPosition ? 'success' : 'secondary'}
                                onClick={() => isSelected ? setSelectedRoom(undefined) : setSelectedRoom(room)}
                            >
                                <Row>
                                    <Col>
                                        {room.name}
                                    </Col>
                                    {hasGridPosition
                                    ? <Col xs="auto">
                                        <i 
                                            className="fa fa-times red clickable"
                                            onClick={() => clearRoomGridPosition(room.id)}
                                        />
                                    </Col> : null}
                                </Row>
                            </Alert>
                        );
                    })}
                </FormGroup>
                {!!selectedRoom
                ? <div className="text-center">
                    <span><i>Draw room on grid</i></span>
                </div> : null}
            </Col>
        </Row>
        <Center>
            <StoreButton
                size="lg"
                onClick={store}
                isStoring={isStoring}
            />
        </Center>
    </>);

}
export default DepartmentRoomLayoutEditPage;