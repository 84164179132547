import { medicalProceduresActions } from "../../sharedHealthComponents/redux/slices/medicalProceduresSlice";
import { specimensActions } from "../../sharedHealthComponents/redux/slices/specimensSlice";
import { testResultsActions } from "../../sharedHealthComponents/redux/slices/testResultsSlice";
import { AppDispatch } from "../redux/store/healthRecordStore";
import { DiagnosticReportStatus, DiagnosticTestScaleType, EventStatus, HealthRecordEntryType, SpecimenStatus } from "../types/enums";
import { Models } from "../types/models";
import { ObservationQrCodeCommand, ProcedureQrCodeCommand, QrCodeCommand, QrCodeCommandType, SpecimenQrCodeCommand } from "./QrCommandParser";

export const uploadQrCodeCommand = (command: QrCodeCommand, dispatch: AppDispatch) => {
    switch(command.type) {
        case QrCodeCommandType.MedicationDispense:
            // const medicationDispenseCommand = command as MedicationDispenseQrCodeCommand;
            // const medicationDispension: Models.Medication.MedicationDispension = {
            //     id: command.id,
            //     type: HealthRecordEntryType.MedicationDispension,
            //     personId: medicationDispenseCommand.personId!,
            //     drug: {
            //         id: medicationDispenseCommand.drugId!,

            //     },
            //     hasBeenSeenBySharer: true,
            //     state: MedicationDispensionState.Dispensed,
            //     timestamp: new Date().toISOString() as any,
            //     value: ,
            //     unit: 
            // };
            // dispatch(medicationDispensionsActions.addItem({
            //     args: undefined,
            //     body: medicationDispension
            // }));
            break;
        case QrCodeCommandType.Observation:
            const observationCommand = command as ObservationQrCodeCommand;
            const observation: Models.DiagnosticTests.QuantitativeDiagnosticTestResult = {
                id: command.id!,
                type: HealthRecordEntryType.TestResult,
                personId: observationCommand.personId!,
                hasBeenSeenBySharer: true,
                timestamp: new Date().toISOString() as any,
                code: observationCommand.code!,
                testCategory: '',
                scaleType: DiagnosticTestScaleType.Quantitative,
                status: DiagnosticReportStatus.Final,
                specimenIds: [],
                value: Number(observationCommand.value!),
                unit: observationCommand.unit
            };
            dispatch(testResultsActions.addItem({
                args: undefined,
                body: observation
            }));
            break;
        case QrCodeCommandType.Procedure:
            const procedureCommand = command as ProcedureQrCodeCommand;
            const procedure: Models.Procedures.MedicalProcedure = {
                id: command.id,
                type: HealthRecordEntryType.Procedure,
                personId: procedureCommand.personId!,
                hasBeenSeenBySharer: true,
                timestamp: new Date().toISOString() as any,
                code: procedureCommand.code!,
                note: '',
                status: EventStatus.Completed,
                performerPractitionerIds: []
            };
            dispatch(medicalProceduresActions.addItem({
                args: undefined,
                body: procedure
            }));
            break;
        case QrCodeCommandType.Specimen:
            const specimenCommand = command as SpecimenQrCodeCommand;
            const specimen: Models.DiagnosticTests.Specimen = {
                id: command.id,
                type: HealthRecordEntryType.Specimen,
                personId: specimenCommand.personId!,
                hasBeenSeenBySharer: true,
                timestamp: new Date().toISOString() as any,
                bodyStructure: specimenCommand.bodyStructure!,
                status: SpecimenStatus.Collected,
                statusChanges: [
                    {
                        status: SpecimenStatus.Collected,
                        timestamp: new Date().toISOString() as any
                    }
                ],
                isAvailableForFurtherTests: true
            };
            dispatch(specimensActions.addItem({
                args: undefined,
                body: specimen
            }));
            break;
    }
}