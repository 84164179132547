export const PrivacyPolicyEn = () => {

    return (
        <>
            <p>
                This privacy policy will explain how shEHRd uses the personal data we collect from you when you use our website.
            </p>
            <h3>Topics:</h3>
            <ul>
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How do we store your data?</li>
                <li>What are your data protection rights?</li>
                <li>What are cookies?
                    <ul>
                        <li>How to manage your cookies</li>
                    </ul>
                </li>
                <li>Privacy policies of other websites</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
            </ul>
            <h3>What data do we collect?</h3>
            <p>shEHRd stores the following data:</p>
            <ul>
                <li>Basic personal information (name, birth date, gender, address, telephone,...)</li>
                <li>Health information (any medical information that you or someone you have given access to enter or upload)</li>
            </ul>
            <h3>How do we collect your data?</h3>
            <p>
                You directly provide shEHRd with most of the data we collect. We collect data and process data when you:
            </p>
            <ul>
                <li>Register or log into your account</li>
                <li>Enter medical information</li>
            </ul>
            {/* <p>
                shEHRd may also receive your data indirectly from the following sources:
            </p>
            <ul>
                <li>Other electronic health record systems</li>
            </ul> */}
            <h3>How will we use your data?</h3>
            <p>shEHRd collects your data so that we can:</p>
            <ul>
                <li>Log you in</li>
                <li>Provide you with your electronic health record</li>
                <li>Provide your electronic health record to others that you have given access (including anonymous visitors using any of your emergency tokens)</li>
            </ul>
            <p>
                shEHRd shares your data with other people that you have given permission to access your electronic health record. 
                This includes people who gain access to your EHR using any emergency token that you have created.
            </p>
            <h3>How do we store your data?</h3>
            <p>
                shEHRd securely stores your data at Hetzner in Falkenstein,
                Germany. The servers are protected by firewall and kept up to date
                with all security patches of the underlying software. shEHRd may
                copy or transfer your data to other servers in the EU to increase
                stability, reliability and performance of the website.
            </p>
            <p>
                shEHRd will keep your data until you delete your account.
                When deleting your account your data
                remains stored in backups for up to 180 days.
            </p>
            <h3>What are your data protection rights?</h3>
            <p>shEHRd would like to make sure you are fully aware of all of
                your data protection rights. Every user is entitled to the following:</p>
            <ul>
                <p><strong>The right to access</strong> &ndash;
                    You have the right to request shEHRd for copies of your personal
                    data.</p>
                <p><strong>The right to rectification</strong>
                    &ndash; You have the right to request that shEHRd correct any
                    information you believe is inaccurate. You also have the right to
                    request shEHRd to complete the information you believe is
                    incomplete.</p>
                <p><strong>The right to erasure</strong>
                    &ndash; You have the right to request that shEHRd erase your
                    personal data, under certain conditions.</p>
                <p><strong>The right to restrict
                    processing</strong> &ndash; You have the right to request that
                    shEHRd restrict the processing of your personal data, under
                    certain conditions.</p>
                <p><strong>The right to object to
                    processing</strong> &ndash; You have the right to object to
                    shEHRd's processing of your personal data, under certain
                    conditions.</p>
                <p><strong>The right to data portability</strong>
                    &ndash; You have the right to request that shEHRd transfer the
                    data that we have collected to another organization, or directly to
                    you, under certain conditions.</p>
            </ul>
            <p>
                If you make a request, we have one month to respond to you. If you
                would like to exercise any of these rights, please contact us at our
                email: <a href="mailto:jan@janscholtyssek.dk">jan@janscholtyssek.dk</a>
            </p>
            <h3>Cookies</h3>
            <p>
                Cookies are a technology for storing small chunks of information.
                shEHRd uses a cookie when you log-in using an external account.
                This cookie contains information about you (account ID and some other
                information provided by your external login provider) and is used to
                authenticate and authorize requests made against the shEHRd-API
                when navigating the website. It is removed when you log out or close
                your browser.
            </p>
            <p>For further information about cookies, visit allaboutcookies.org.</p>
            <h3>How to manage cookies</h3>
            <p>
                You can set your browser not to accept cookies, and the above
                website tells you how to remove cookies from your browser. However,
                in a few cases, some of our website features may not function as a
                result.
            </p>
            <h3>Privacy policies of other websites</h3>
            <p>
                The shEHRd website contains links to other websites. Our
                privacy policy applies only to our website, so if you click on a link
                to another website, you should read their privacy policy.
            </p>
            <h3>Changes to our privacy policy</h3>
            <p>
                shEHRd keeps its privacy policy under regular review and places
                any updates on this web page. This privacy policy was last updated on
                2022-09-28.
            </p>
            <h3>How to contact us</h3>
            <p>
                If you have any questions about shEHRd's privacy policy,
                the data we hold on you, or you would like to exercise one of your
                data protection rights, please do not hesitate to contact us.
            </p>
            <p>
                Email us at: <a href="mailto:jan@janscholtyssek.dk">jan@janscholtyssek.dk</a>
            </p>
            <h3>How to contact the appropriate authority</h3>
            <p>
                Should you wish to report a complaint or if you feel that
                shEHRd has not addressed your concern in a satisfactory manner,
                you may contact the European Data Protection Supervisor.
            </p>
            <p>
                Complain to the EDPS: <a href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en">https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en</a>
            </p>
        </>
    );

}