import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { ViewModels } from '../../types/viewModels';

interface StockAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.StockViewModel> {
}

export const StockAutocomplete = (props: StockAutocompleteProps) => {

    return (
        <GenericAutocomplete<ViewModels.StockViewModel>
            {...props}
            apiPath='api/stocks'
            displayFunc={x => `${x.name} (${x.department.name})`}
        />
    );

}