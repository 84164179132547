import { createSlice } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { loadItemsActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ServicesFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ServicesState extends RemoteState<ViewModels.ServiceViewModel, ServicesFilter> {
}

const initialState: ServicesState = {
    ...defaultRemoteInitialState(),
};

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const serviceQueryBuilder = (state: RootState, sliceState: ServicesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, serviceFilterComparer);
    const filter = sliceState.filter;
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    if(filter.departmentId) {
        queryParams.push({ key: 'departmentId', value: filter.departmentId });
    }
    if(filter.servicePackageId) {
        queryParams.push({ key: 'servicePackageId', value: filter.servicePackageId });
    }
    return queryParams;
}
const serviceFilterComparer = (f1?: ServicesFilter, f2?: ServicesFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText 
        && f1!.departmentId === f2!.departmentId
        && f1!.servicePackageId === f2!.servicePackageId;
}
export const servicesActions = {
    ...createRestApiActions(
        'services', 
        servicesSlice.actions,
        state => state.services,
        serviceQueryBuilder,
        serviceFilterComparer
    ),
    loadForDepartment: loadItemsActionBuilder(
        (args?: { departmentId: string}) => `api/departments/${args!.departmentId}/services`,
        serviceQueryBuilder,
        serviceFilterComparer,
        () => resolveText("Services_CouldNotLoad"),
        servicesSlice.actions.setIsLoading,
        servicesSlice.actions.setItems,
        servicesSlice.actions.addOrUpdateItems,
        servicesSlice.actions.setHasMoreItems,
        servicesSlice.actions.setLastUsedFilter,
        state => state.services
    ),
};
export const servicesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.services),
};