import { uuid } from "../../sharedCommonComponents/helpers/uuid";
import { ClassificationType, DiagnosticTestScaleType } from "../types/enums";
import { Models } from "../types/models";

enum QrCodeLineType {
    Action = "Action",
    Drug = "Drug",
    Person = "Person",
    SpecimenContainer = "SpecimenContainer",
    Procedure = "Procedure",
    Observation = "Observation"
}
interface QrCodeLine {
    type: QrCodeLineType;
    value: string;
}
export enum QrCodeCommandType {
    MedicationDispense = "MedicationDispense",
    Observation = "Observation",
    Procedure = "Procedure",
    Specimen = "Specimen"
}
export interface QrCodeCommand {
    type: QrCodeCommandType;
    id: string;
}
export interface PersonQrCodeCommand extends QrCodeCommand {
    personId?: string;
}
export interface MedicationDispenseQrCodeCommand extends PersonQrCodeCommand {
    drugId?: string;
}
export interface ObservationQrCodeCommand extends PersonQrCodeCommand {
    code?: Models.ClassificationReference;
    scaleType?: DiagnosticTestScaleType;
    value?: string;
    unit?: string;
}
export interface ProcedureQrCodeCommand extends PersonQrCodeCommand {
    code?: Models.ClassificationReference;
}
export interface SpecimenQrCodeCommand extends PersonQrCodeCommand {
    containerId?: string;
    bodyStructure?: Models.ClassificationReference;
}
const parseQrCodeLine = (line: string): QrCodeLine | undefined => {
    const splittedLine = line.split(':', 2);
    if(splittedLine.length !== 2) {
        return undefined;
    }
    const type = Object.values(QrCodeLineType).find(x => x.toLowerCase() === splittedLine[0].toLowerCase());
    if(!type) {
        return undefined; 
    }
    return {
        type: type,
        value: splittedLine[1]
    };
}
const parseClassificationReference = (str?: string): Models.ClassificationReference | undefined => {
    if(!str) {
        return undefined;
    }
    const splittedLine = str.split('|');
    if(splittedLine.length < 2) {
        return undefined;
    }
    const classification = Object.values(ClassificationType).find(x => x.toLowerCase() === splittedLine[0].toLowerCase());
    if(!classification) {
        return undefined;
    }
    const conceptId = splittedLine[1];
    return {
        classification: classification,
        conceptId: conceptId,
        displayName: ''
    };
}
const buildCommand = (parts: QrCodeLine[]): QrCodeCommand | undefined => {
    const actionPart = parts.find(x => x.type === QrCodeLineType.Action);
    if(!actionPart) {
        return undefined;
    }
    const commandType = Object.values(QrCodeCommandType).find(x => x.toLowerCase() === actionPart.value.toLowerCase());
    if(!commandType) {
        return undefined;
    }
    const id = uuid();
    const personId = parts.find(x => x.type === QrCodeLineType.Person)?.value;
    switch(commandType) {
        case QrCodeCommandType.MedicationDispense:
            const drugId = parts.find(x => x.type === QrCodeLineType.Drug)?.value;
            return {
                type: commandType,
                id: id,
                personId: personId,
                drugId: drugId
            } as MedicationDispenseQrCodeCommand;
        case QrCodeCommandType.Observation:
            const observationCode = parseClassificationReference(parts.find(x => x.type === QrCodeLineType.Observation)?.value);
            return {
                type: commandType,
                id: id,
                personId: personId,
                code: observationCode
            } as ObservationQrCodeCommand;
        case QrCodeCommandType.Procedure:
            const procedureCode = parseClassificationReference(parts.find(x => x.type === QrCodeLineType.Procedure)?.value);
            return {
                type: commandType,
                id: id,
                personId: personId,
                code: procedureCode
            } as ProcedureQrCodeCommand;
        case QrCodeCommandType.Specimen:
            const containerId = parts.find(x => x.type === QrCodeLineType.SpecimenContainer)?.value;
            return {
                type: commandType,
                id: id,
                personId: personId,
                containerId: containerId
            } as SpecimenQrCodeCommand;
    }
}
export const parseQrCodeCommands = (str: string): QrCodeCommand[] => {
    const lines = str.split('\n');
    const commands: QrCodeCommand[] = [];
    let currentCommandLines: QrCodeLine[] = [];
    for (const line of lines) {
        const parsedLine = parseQrCodeLine(line);
        if(!parsedLine) {
            continue;
        }
        if(parsedLine.type === QrCodeLineType.Action) {
            const command = buildCommand(currentCommandLines);
            if(command) {
                commands.push(command);
            }
            currentCommandLines = [];
        }
        currentCommandLines.push(parsedLine);
    }
    const command = buildCommand(currentCommandLines);
    if(command) {
        commands.push(command);
    }
    return commands;
}