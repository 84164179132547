export const SnomedCtConcepts = {
    ROOT_CONCEPTID: "138875005",
    DRUG_DISPENSIONFORMS_CONCEPTID: "105904009",
    DRUG_DISPENSIONFORMS_TABLET_CONCEPTID: "385055001",
    DRUG_DISPENSIONFORMS_CAPSULE_CONCEPTID: "385049006",
    DRUG_DISPENSIONFORMS_INFUSION_CONCEPTID: "385229008",
    DRUG_DISPENSIONFORMS_INJECTION_CONCEPTID: "385219001",
    DRUG_DISPENSIONFORMS_CREAM_CONCEPTID: "385099005",
    DRUG_APPLICATIONROUTES_CONCEPTID: "284009009",
    DRUG_APPLICATIONROUTES_PERORAL_CONCEPTID: "26643006",
    DRUG_APPLICATIONROUTES_INTRAVENOUS_CONCEPTID: "47625008",
    DRUG_APPLICATIONROUTES_INTRAMUSCULAR_CONCEPTID: "78421000",
    DRUG_APPLICATIONROUTES_TRANSDERMAL_CONCEPTID: "45890007",
    EQUIPMENTTYPE_CONCEPTID: "49062001",
    BODYPART_CONCEPTID: "38866009",
    SUBSTANCE_CONCEPTID: "105590001",
    SYMPTOMS_CONCEPTID: "404684003",
    EXPOSUREROUTE_CONCEPTID: "284009009",
    CPR_CONCEPTID: "89666000",
    CPR_CHESTCOMPRESSIONS_CONCEPTID: "83099006",
    CPR_DEFIBRILATION_CONCEPTID: "308842001",
    CPR_DEFIBRILATION_BIPHASIC_CONCEPTID: "428909008",
    SPECIMEN_CONCEPTID: "123038009",
    SPECIMEN_BLOOD_CONCEPTID: "119297000",
    SPECIMEN_URINE_CONCEPTID: "122575003",
    SPECIMEN_STOOL_CONCEPTID: "119339001",
    SPECIMENCONTAINER_CONCEPTID: "706046003",
    SPECIMENCONTAINER_TUBE_EDTA_CONCEPTID: "767389009",
    SPECIMENCONTAINER_FAECAL_CONCEPTID: "706047007",
    SPECIMENCONTAINER_URINE_CONCEPTID: "706054001",
    SPECIMENCONTAINER_GENERAL_CONCEPTID: "706053007",
    SPECIMEN_COLLECTIONMETHOD_CONCEPTID: "17636008",
    SPECIMENCOLLECTIONMETHOD_VENIPUNCTURE_CONCEPTID: "28520004",
    SPECIMENCOLLECTIONMETHOD_MIDSTREAMURINE_CONCEPTID: "225271002",
    SPECIMENCOLLECTIONMETHOD_STOOL_CONCEPTID: "225105004",
    PROCEDURE_CONCEPTID: "71388002",
};
