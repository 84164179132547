import { PropsWithChildren, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoadingAlert } from '../../sharedCommonComponents/components/LoadingAlert';

interface NotLoggedInLayoutProps {}

export const NotLoggedInLayout = (props: PropsWithChildren<NotLoggedInLayoutProps>) => {

    return (
    <div className='bg-secondary' style={{ height: '100%', minHeight: '100vh' }}>
        <ToastContainer
            theme='colored'
        />
        <Suspense fallback={<LoadingAlert />}>
            {props.children}
        </Suspense>
    </div>
    );

}