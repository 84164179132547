import { useEffect, useMemo, useState } from "react";
import { ViewModels } from "../../../localComponents/types/viewModels";
import GenericIdAutocomplete from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { DepartmentAutocomplete } from "./DepartmentAutocomplete";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../../../localComponents/types/models";
import { Alert, FormControl } from "react-bootstrap";

interface DepartmentIdAutocompleteProps extends IdAutocompleteProps {
    institutionId?: string;
    loadAll?: boolean;
}

export const DepartmentIdAutocomplete = (props: DepartmentIdAutocompleteProps) => {

    const { institutionId, loadAll, value, onChange } = props;

    const [ departments, setDepartments ] = useState<Models.Organizations.Department[]>();
    const selectedDepartment = useMemo(() => {
        if(!departments || !value) {
            return undefined;
        }
        return departments.find(x => x.id === value);
    }, [ departments, value ]);

    useEffect(() => {
        if(!loadAll) {
            return;
        }
        if(!institutionId) {
            return;
        }
        const loadDepartments = buildLoadObjectFunc(
            `api/institutions/${institutionId}/departments`, {},
            resolveText("Departments_CouldNotLoad"),
            setDepartments
        );
        loadDepartments();
    }, [ institutionId ]);

    // Reset value if departments are loaded but none match
    useEffect(() => {
        if(!value) {
            return;
        }
        if(!departments || departments.length === 0) {
            return;
        }
        if(selectedDepartment) {
            return;
        }
        // A department-ID is set, departments have been loaded, but none match.
        // => Reset value, because this should not happen
        onChange(undefined);
    }, [ value, departments, selectedDepartment ]);

    if(departments && departments.length > 0) {
        
        if(selectedDepartment) {
            return (<Alert
                dismissible
                onClose={() => onChange(undefined)}
                variant="info"
            >
                {selectedDepartment.name}
            </Alert>);
        }
        return (<FormControl
            as="select"
            value=""
            onChange={e => onChange(e.target.value)}
        >
            <option value="" disabled>{resolveText("PleaseSelect...")}</option>
            {departments.map(department => (
                <option key={department.id} value={department.id}>{department.name}</option>
            ))}
        </FormControl>);
    }

    return (
        <GenericIdAutocomplete<ViewModels.DepartmentViewModel>
            loadItemApiPathBuilder={id => `api/departments/${id}`}
            autocompleteBuilder={(key,props) => (
                <DepartmentAutocomplete
                    key={key}
                    institutionId={institutionId}
                    {...props}
                />
            )}
            {...props}
        />
    );

}
export default DepartmentIdAutocomplete;