import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface StockStatesState extends RemoteState<ViewModels.StockStateViewModel, {}> {
}

const initialState: StockStatesState = {
    ...defaultRemoteInitialState(),
}
export const stockStatesSlice = createSlice({
    name: 'stockStates',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const stockStateFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const stockStateQueryBuilder = (state: RootState, sliceState: StockStatesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, stockStateFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export interface AddStoreStatesArgs {
    consumableId: string;
}
export const stockStatesActions = {
    ...createRestApiActions(
        'stockStates',
        stockStatesSlice.actions,
        state => state.stockStates,
        stockStateQueryBuilder,
        stockStateFilterComparer
    ),
};
export const stockStatesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.stockStates),
    createSelectForConsumable: () => createSelector(
        (state: RootState) => state.stockStates.items,
        (_: RootState, args: { consumableId: string | undefined }) => args.consumableId,
        (items, consumableId) => consumableId 
            ? items.filter(x => x.consumableId === consumableId)
            : []
    ),
};