import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import InstitutionIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/InstitutionIdAutocomplete";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";

interface InstitutionSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.InstitutionSubscription> {
}

export const InstitutionSubscriptionEditor = (props: InstitutionSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Institution")}</FormLabel>
            <InstitutionIdAutocomplete
                value={subscription.institutionId}
                onChange={institutionId => onChange(state => ({
                    ...state,
                    institutionId: institutionId
                } as Models.Subscriptions.InstitutionSubscription))}
            />
        </FormGroup>
    </>);

}