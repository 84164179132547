import { FormControl, Row, Col } from 'react-bootstrap';
import { AutoCompleteContext } from '../../localComponents/types/enums';
import { MemoryFormControl } from '../../sharedCommonComponents/components/FormControls/MemoryFormControl';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';

interface UnitValueFormControlProps {
    value: number;
    unit: string;
    onValueChanged: (value: number) => void;
    onUnitChanged: (unit: string) => void;
}

export const UnitValueFormControl = (props: UnitValueFormControlProps) => {

    return (
    <Row>
        <Col>
            <FormControl required
                type='number'
                value={props.value}
                onChange={(e:any) => props.onValueChanged(e.target.value)}
                placeholder={resolveText('Observation_Value')}
            />
        </Col>
        <Col xs="auto">
            <MemoryFormControl
                context={AutoCompleteContext.Unit}
                defaultValue={props.unit}
                onChange={props.onUnitChanged}
                placeholder={resolveText('Unit')}
                minSearchTextLength={1}
            />
        </Col>
    </Row>
    );

}