import { ViewModels } from "../../../localComponents/types/viewModels";
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";

interface DepartmentAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.DepartmentViewModel> {
    institutionId?: string;
}

export const DepartmentAutocomplete = (props: DepartmentAutocompleteProps) => {

    return (
        <GenericAutocomplete<ViewModels.DepartmentViewModel>
            {...props}
            apiPath={props.institutionId ? `api/institutions/${props.institutionId}/departments` : 'api/departments'}
            displayFunc={x => x.name}
        />
    );

}