import { useMemo } from "react";
import { Models } from "../../types/models";
import { ViewModels } from "../../types/viewModels";
import { formatDate, formatPerson } from "../../../sharedHealthComponents/helpers/Formatters";

interface DepartmentBedListRoomTableRowProps {
    room: Models.Room;
    bedOccupancies: Models.BedManagement.BedOccupancy[];
    patients: ViewModels.BedListPatientViewModel[];
}

export const DepartmentBedListRoomTableRow = (props: DepartmentBedListRoomTableRowProps) => {

    const { room, bedOccupancies, patients } = props;

    const beds = useMemo(() => [ ...room.bedPositions ].sort((a,b) => a.localeCompare(b)), [ room ]);
    const bedTableRows = useMemo(() => beds.map((bed,bedIndex) => {
        const bedOccupancy = bedOccupancies.find(x => x.roomId === room.id && x.bedPosition === bed);
        const patient = !!bedOccupancy?.personId ? patients.find(x => x.person.personId === bedOccupancy.personId) : undefined;
        return (<tr
            key={bed}
        >
            {bedIndex === 0
            ? <td rowSpan={beds.length} className="text-center text-vertical-center">
                {room.name}
            </td> : null}
            <td className="text-center text-vertical-center">{bed}</td>
            <td>
                {patient 
                ? <>
                    <span className="patient-name">{formatPerson(patient?.person)}</span>
                    {patient.customHealthRecordSummary
                    ? <div className="health-record-summary">
                        {patient.customHealthRecordSummary.summary}
                    </div> : null}
                </> : null}
            </td>
            <td>
                {patient?.encounter?.plannedEndTime
                ? formatDate(patient.encounter.plannedEndTime)
                : null}
            </td>
            <td>
                { /* Intentionally left empty. Room for handwritten notes */ }
            </td>
        </tr>);
    }), [ room, beds, bedOccupancies, patients ]);

    return (<>
        {bedTableRows}
    </>);

}