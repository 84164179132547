import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { NotificationsFilter } from "../../types/frontendTypes";
import { Models } from "../../types/models";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface NotificationsState extends RemoteState<Models.Subscriptions.IPublicNotification, NotificationsFilter> {
}
const initialState: NotificationsState = {
    ...defaultRemoteInitialState(),
    filter: {
        includeDismissed: false
    }
}
export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        dismiss: (state, action: PayloadAction<string>) => {
            if(!state.lastUsedFilter?.includeDismissed) {
                state.items = state.items.filter(x => x.id !== action.payload);
            }
        },
        dismissByPublicId: (state, action: PayloadAction<string>) => {
            if(!state.lastUsedFilter?.includeDismissed) {
                state.items = state.items.filter(x => x.publicId !== action.payload);
            }
        },
        dismissAll: (state) => {
            if(!state.lastUsedFilter?.includeDismissed) {
                state.items = [];
            }
        }
    }
});

const notificationQueryBuilder = (state: RootState, sliceState: NotificationsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, notificationFilterComparer);
    queryParams.push({ key: 'orderBy', value: 'time' });
    const filter = sliceState.filter;
    if(filter) {
        if(filter.includeDismissed) {
            queryParams.push({ key: 'includeDismissed', value: 'true' });
        }
    }
    return queryParams;
}
const notificationFilterComparer = (f1?: NotificationsFilter, f2?: NotificationsFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.includeDismissed === f2!.includeDismissed;
}

export const notificationsActions = {
    ...createRestApiActions(
        'notifications',
        notificationsSlice.actions,
        state => state.notifications,
        notificationQueryBuilder,
        notificationFilterComparer
    ),
    deleteItem: undefined,
    dismissItem: postActionBuilder(
        (notificationId: string) => `api/notifications/${notificationId}/dismiss`,
        () => resolveText("Notification_CouldNotDismiss"),
        notificationsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(notificationsSlice.actions.dismiss(args))
    ),
    dismissByPublicId: postActionBuilder(
        (publicNotificationId: string) => `api/notifications/public/${publicNotificationId}/dismiss`,
        () => resolveText("Notification_CouldNotDismiss"),
        notificationsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(notificationsSlice.actions.dismissByPublicId(args))
    ),
    dismissAll: postActionBuilder(
        _ => 'api/notifications/dismiss-all',
        () => resolveText("Notification_CouldNotDismiss"),
        notificationsSlice.actions.setIsSubmitting,
        (dispatch,response,args) => dispatch(notificationsSlice.actions.dismissAll())
    )
};
export const notificationsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.notifications),
};