import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiClient } from "../../../sharedCommonComponents/communication/ApiClient";
import { showErrorAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ApiGetActionCreator, AsyncActionCreator } from "../../../sharedHealthComponents/types/reduxTypes";
import { SharedSubscriptionType } from "../../types/enums";
import { Models } from "../../types/models";
import { RootState } from "../store/healthRecordStore";
import { SubscriptionFilter } from "../../types/frontendTypes";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface SubscriptionsState extends RemoteState<Models.Subscriptions.Subscription, SubscriptionFilter> {
}
const initialState: SubscriptionsState = {
    ...defaultRemoteInitialState(),
}

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        removePatientSubscription: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(x => 
                x.type !== SharedSubscriptionType.Patient 
                || (x as Models.Subscriptions.PatientSubscription).personId !== action.payload
            );
        }
    }
});

const subscriptionQueryBuilder = (state: RootState, sliceState: SubscriptionsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, subscriptionFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.type) {
            queryParams.push({ key: 'type', value: filter.type });
        }
    }
    return queryParams;
}
const subscriptionFilterComparer = (f1?: SubscriptionFilter, f2?: SubscriptionFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.type === f2!.type;
}
export interface LoadPatientSubscriptionArgs {
    personId: string;
}
export const subscriptionsActions = {
    ...createRestApiActions(
        'subscriptions', 
        subscriptionsSlice.actions,
        state => state.subscriptions,
        subscriptionQueryBuilder,
        subscriptionFilterComparer
    ),
    loadForPatient: (({ args }) => {
        return async (dispatch) => {
            dispatch(subscriptionsSlice.actions.setIsLoading(true));
            const apiPath = `api/subscriptions/patient/${args!.personId}`;
            try {
                const response = await apiClient.instance!.get(apiPath, {}, { handleError: false });
                if(response.ok) {
                    const subscription = await response.json();
                    dispatch(subscriptionsSlice.actions.addOrUpdateItem(subscription));
                }
            } catch(e: any) {
                showErrorAlert(e.message);
            } finally {
                dispatch(subscriptionsSlice.actions.setIsLoading(false))
            }
        }
    }) as ApiGetActionCreator<LoadPatientSubscriptionArgs,{}>,
    subscribeToPerson: ((personId: string) => {
        return async (dispatch) => {
            dispatch(subscriptionsSlice.actions.setIsSubmitting(true));
            await sendPostRequest(
                `api/patients/${personId}/subscribe`, {},
                resolveText("Patient_CouldNotSubscribe"),
                null,
                async response => {
                    const subscription = await response.json() as Models.Subscriptions.PatientSubscription;
                    dispatch(subscriptionsSlice.actions.addOrUpdateItem(subscription));
                },
                undefined,
                () => dispatch(subscriptionsSlice.actions.setIsSubmitting(false))
            );
        }
    }) as AsyncActionCreator,
    unsubscribeFromPerson: ((personId: string) => {
        return async (dispatch) => {
            dispatch(subscriptionsSlice.actions.setIsSubmitting(true));
            await sendPostRequest(
                `api/patients/${personId}/unsubscribe`, {},
                resolveText("Patient_CouldNotUnsubscribe"),
                null,
                async () => {
                    dispatch(subscriptionsSlice.actions.removePatientSubscription(personId));
                },
                undefined,
                () => dispatch(subscriptionsSlice.actions.setIsSubmitting(false))
            );
        }
    }) as AsyncActionCreator
};
export const subscriptionsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.subscriptions),
    createSelectForPerson: () => createSelector(
        (state: RootState) => state.subscriptions.items,
        (_: RootState, args: { personId: string }) => args.personId,
        (items, personId) => personId 
            ? items
                .filter(x => x.type === SharedSubscriptionType.Patient)
                .map(x => x as Models.Subscriptions.PatientSubscription)
                .find(x => x.personId === personId)
            : undefined
    ),
};