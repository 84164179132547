import { Button, Card, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";
import { FormEvent, useState } from "react";
import { Models } from "../../types/models";
import { DateFormControl } from "../../../sharedCommonComponents/components/FormControls/DateFormControl";
import { Sex } from "../../types/enums";
import { Center } from "../../../sharedCommonComponents/components/Center";
import { AsyncButton } from "../../../sharedCommonComponents/components/AsyncButton";
import { RoomAutocomplete } from "../../components/Autocompletes/RoomAutocomplete";
import { HorizontalLineWithText } from "../../../sharedCommonComponents/components/HorizontalLineWithText";
import { PersonAutocomplete } from "../../../sharedHealthComponents/components/Autocompletes/PersonAutocomplete";
import InstitutionIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/InstitutionIdAutocomplete";
import DepartmentIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/DepartmentIdAutocomplete";
import { showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { useNavigate } from "react-router-dom";

interface QuickAdmitPatientPageProps {}

export const QuickAdmitPatientPage = (props: QuickAdmitPatientPageProps) => {

    const [ isExistingPerson, setIsExistingPerson ] = useState<boolean>(false);
    const [ personId, setPersonId ] = useState<string>('');
    const [ firstName, setFirstName ] = useState<string>('');
    const [ lastName, setLastName ] = useState<string>('');
    const [ birthDate, setBirthDate ] = useState<any>();
    const [ sex, setSex ] = useState<Sex>(Sex.Indeterminate);
    const [ institutionId, setInstitutionId ] = useState<string>();
    const [ departmentId, setDepartmentId ] = useState<string>();
    const [ selectedRoom, setSelectedRoom ] = useState<Models.Room>();
    const [ bedPosition, setBedPosition ] = useState<string>();
    const [ isSubmitting, setIsSubmitting ] = useState<boolean>();
    const navigate = useNavigate();


    const canSubmit = () => {
        if(isExistingPerson && !personId) {
            return false;
        }
        if(!isExistingPerson) {
            if(firstName.trim().length === 0) {
                return false;
            }
            if(lastName.trim().length === 0) {
                return false;
            }
            if(!birthDate) {
                return false;
            }
        }
        if(!institutionId) {
            return false;
        }
        if(!departmentId) {
            return false;
        }
        return true;
    }

    const admitPatient = async (e?: FormEvent) => {
        e?.preventDefault();
        if(!institutionId || !departmentId) {
            return;
        }
        const body: Models.RequestBodies.QuickAdmitPatientRequest = {
            confirmExistingPerson: isExistingPerson,
            personId,
            firstName,
            lastName,
            birthDate,
            sex: sex,
            institutionId,
            departmentId,
            bed: selectedRoom && !!bedPosition 
            ? {
                roomId: selectedRoom.id,
                bedPosition: bedPosition
            } : undefined,
        };
        setIsSubmitting(true);
        await sendPostRequest(
            'api/registration/quick-admit', {}, 
            resolveText("Registration_CouldNotAdmitPatient"),
            body,
            async () => {
                showSuccessAlert(resolveText("Registration_SuccessfullyAdmitted"));
                navigate(-1);
            },
            undefined,
            () => setIsSubmitting(false)
        )
    }

    const resetPerson = () => {
        setIsExistingPerson(false);
        setPersonId('');
        setFirstName('');
        setLastName('');
        setBirthDate(undefined);
        setSex(Sex.Indeterminate);
    }

    return (<>
        <h1>{resolveText("Registration_QuickRegistration")}</h1>
        <Form onSubmit={admitPatient}>
            <Card className="my-1">
                <Card.Body>
                    <h3>{resolveText("Person")}</h3>
                    <FormGroup>
                        <FormLabel>{resolveText("QuickAdmit_ExistingPerson")}</FormLabel>
                        <PersonAutocomplete
                            onChange={person => {
                                if(!person) {
                                    resetPerson();
                                } else {
                                    setIsExistingPerson(true);
                                    setPersonId(person.personId);
                                    setFirstName(person.firstName);
                                    setLastName(person.lastName);
                                    setBirthDate(person.birthDate);
                                    setSex(person.sex);
                                }
                            }}
                        />
                    </FormGroup>
                    <HorizontalLineWithText text={resolveText("or")} />
                    <FormGroup>
                        <FormLabel>{resolveText("Person_PersonId")}</FormLabel>
                        <FormControl 
                            value={personId}
                            onChange={e => setPersonId(e.target.value)}
                            disabled={isExistingPerson}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>{resolveText("Person_LastName")}</FormLabel>
                        <FormControl 
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>{resolveText("Person_FirstName")}</FormLabel>
                        <FormControl 
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>{resolveText("Person_BirthDate")}</FormLabel>
                        <DateFormControl
                            value={birthDate}
                            onChange={setBirthDate}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>{resolveText("Person_Sex")}</FormLabel>
                        <FormControl
                            as="select"
                            value={sex}
                            onChange={e => setSex(e.target.value as Sex)}
                        >
                            {Object.values(Sex).map(x => (
                                <option key={x} value={x}>{resolveText(`Sex_${x}`)}</option>
                            ))}
                        </FormControl>
                    </FormGroup>
                    <Center className="mt-3">
                        <Button
                            onClick={resetPerson}
                        >
                            {resolveText("Reset")}
                        </Button>
                    </Center>
                </Card.Body>
            </Card>
            <Card className="my-1">
                <Card.Body>
                    <h3>{resolveText("Admission")}</h3>
                    <FormGroup>
                        <FormLabel>{resolveText("Institution")}</FormLabel>
                        <InstitutionIdAutocomplete
                            value={institutionId}
                            onChange={setInstitutionId}
                        />
                    </FormGroup>
                    {!!institutionId
                    ? <FormGroup>
                        <FormLabel>{resolveText("Department")}</FormLabel>
                        <DepartmentIdAutocomplete
                            institutionId={institutionId}
                            value={departmentId}
                            onChange={setDepartmentId}
                        />
                    </FormGroup> : null}
                    {!!departmentId
                    ? <FormGroup>
                        <FormLabel>{resolveText("Room")}</FormLabel>
                        <RoomAutocomplete
                            departmentId={departmentId}
                            value={selectedRoom}
                            onChange={room => setSelectedRoom(room)}
                        />
                    </FormGroup> : null}
                    {!!selectedRoom
                    ? <FormGroup>
                        <FormLabel>{resolveText("BedOccupancy_BedPosition")}</FormLabel>
                        <FormControl
                            as="select"
                            value={bedPosition}
                            onChange={e => setBedPosition(e.target.value)}
                        >
                            {selectedRoom.bedPositions.map(x => (
                                <option key={x} value={x}>{x}</option>
                            ))}
                        </FormControl>
                    </FormGroup> : null}
                </Card.Body>
            </Card>
            <Center>
                <AsyncButton
                    type="submit"
                    size="lg"
                    isExecuting={isSubmitting}
                    activeText={resolveText("Admit")}
                    executingText={resolveText("Admit")}
                    disabled={!canSubmit()}
                    className="mt-3"
                />
            </Center>
        </Form>
    </>);

}
export default QuickAdmitPatientPage;