import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';

interface RegistrationMenuProps {}

export const RegistrationMenu = (props: RegistrationMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav className="me-auto">
            <NavDropdown title={resolveText('Menu_Registration')} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => navigate('/persons')}>{resolveText('Menu_PatientList')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/create/person')}>{resolveText('Menu_CreatePatient')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/shehrd-sync')}>{resolveText('Menu_ShehrdImport')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/checkin')}>{resolveText('Menu_Checkin')}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => navigate('/encounters')}>{resolveText('Menu_ListOfAdmissions')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/create/encounter')}>{resolveText('Menu_Admit')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/quick-admit')}>{resolveText('Menu_QuickAdmit')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/rooms')}>{resolveText('Menu_ScheduleBeds')}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => navigate('/appointments')}>{resolveText('Menu_Appointments')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/create/appointment')}>{resolveText('Menu_NewAppointment')}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}