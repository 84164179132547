import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { MaterialOrderIdAutocomplete } from "../Autocompletes/MaterialOrderIdAutocomplete";

interface MaterialOrderSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.MaterialOrderSubscription> {
}

export const MaterialOrderSubscriptionEditor = (props: MaterialOrderSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("MaterialOrder")}</FormLabel>
            <MaterialOrderIdAutocomplete
                value={subscription.orderId}
                onChange={orderId => onChange(state => ({
                    ...state,
                    orderId: orderId
                } as Models.Subscriptions.MaterialOrderSubscription))}
            />
        </FormGroup>
    </>);

}