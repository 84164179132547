import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Session } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";

const initialState: Session = {
    isLoggedIn: false
}
export const sessionSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {
        setSession: (state, action: PayloadAction<Session>) => {
            return action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setIsPasswordChangeRequired: (state, action: PayloadAction<boolean>) => {
            state.isPasswordChangeRequired = action.payload;
        },
        setLoggedInUser: (state, action: PayloadAction<ViewModels.IUserViewModel>) => {
            state.isLoggedIn = !!action.payload;
            state.loggedInUser = action.payload;
        }
    }
});