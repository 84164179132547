import { GenericAutocomplete, GenericAutocompleteImplementationProps } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";
import { formatMaterial } from "../../helpers/Formatters";
import { ViewModels } from "../../types/viewModels";

interface MaterialOrderAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.MaterialOrderViewModel> {
}

export const MaterialOrderAutocomplete = (props: MaterialOrderAutocompleteProps) => {

    return (<GenericAutocomplete
        {...props}
        apiPath="api/materialOrders"
        displayFunc={x => formatMaterial(x.materialReference)}
    />);

}