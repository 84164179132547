import { useContext } from 'react';
import { Button, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import { useAppDispatch } from '../../redux/store/healthRecordStore';
import { lockScreen } from '../../../sharedHealthComponents/helpers/ScreenLockHelpers';
import { getPerson } from '../../helpers/UserHelpers';

interface LoggedInUserProps {
    onLogOut: () => void;
}

export const LoggedInUser = (props: LoggedInUserProps) => {

    const { onLogOut } = props;
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <Nav className='ms-auto me-2'>
            <NavDropdown
                title={`${resolveText('Hello')}, ${getPerson(user)!.firstName}`}
                className='me-2'
            >
                <NavDropdown.Item onClick={() => navigate(`/myaccount`)}>{resolveText('Account_ManageAccount')}</NavDropdown.Item>
            </NavDropdown>
            <NavItem>
                <Button 
                    className="me-3"
                    variant="danger"
                    onClick={onLogOut}
                >
                    {resolveText('LogOut')}
                </Button>
            </NavItem>
            <NavItem>
                <Button 
                    className="me-3"
                    variant="danger"
                    onClick={() => lockScreen(dispatch, navigate)}
                >
                    <i className='fa fa-lock' />
                </Button>
            </NavItem>
        </Nav>
    );

}