import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { NewsScope } from "../../types/frontendEnums";
import { ServiceAudienceType } from "../../types/enums";

export interface NewsState extends RemoteState<Models.NewsItem, {}> {
}

const initialState: NewsState = {
    ...defaultRemoteInitialState(),
}

export const newsSlice = createSlice({
    name: 'news',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const newsFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const newsQueryBuilder = (state: RootState, sliceState: NewsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, newsFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const newsActions = {
    ...createRestApiActions(
        'news',
        newsSlice.actions,
        state => state.news,
        newsQueryBuilder,
        newsFilterComparer
    ),
};
const targetsInstitution = (
    newsItem: Models.NewsItem, 
    institutionId: string, 
    includeNewsForAll?: boolean) => {

    if(includeNewsForAll && newsItem.audience.some(x => x.type === ServiceAudienceType.All)) {
        return true;
    }
    return newsItem.audience
        .filter(audience => audience.type === ServiceAudienceType.Institution)
        .map(audience => audience as Models.Services.InstitutionServiceAudience)
        .some(institutionAudience => institutionAudience.institutionId === institutionId);
}
const targetsDepartment = (
    newsItem: Models.NewsItem, 
    departmentId: string, 
    includeNewsForAll?: boolean) => {
        
    if(includeNewsForAll && newsItem.audience.some(x => x.type === ServiceAudienceType.All)) {
        return true;
    }
    return newsItem.audience
        .filter(audience => audience.type === ServiceAudienceType.Department)
        .map(audience => audience as Models.Services.DepartmentServiceAudience)
        .some(departmentAudience => departmentAudience.departmentId === departmentId);
}
export interface SelectNewsByScopeArgs {
    scope: NewsScope,
    institutionId?: string;
    departmentId?: string;
    includeNewsForAll?: boolean;
}
export const newsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.news),
    createSelectByScope: () => createSelector(
        (state: RootState) => state.news.items,
        (_: RootState, args: SelectNewsByScopeArgs) => args.scope,
        (_: RootState, args: SelectNewsByScopeArgs) => args.institutionId,
        (_: RootState, args: SelectNewsByScopeArgs) => args.departmentId,
        (_: RootState, args: SelectNewsByScopeArgs) => args.includeNewsForAll,
        (items, scope, institutionId, departmentId, includeNewsForAll) => {
            switch(scope) {
                case NewsScope.Institution:
                    return items.filter(x => targetsInstitution(x, institutionId!, includeNewsForAll));
                case NewsScope.Department:
                    return items.filter(x => targetsDepartment(x, departmentId!, includeNewsForAll));
                default:
                    throw new Error(`Unknown news scope ${scope}`);
            }
        }
    )
};