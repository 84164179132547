import React from 'react';
import { Modal } from 'react-bootstrap';
import { ViewModels } from '../../localComponents/types/viewModels';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { SnomedCtEntryFinder } from '../components/SnomedCtEntryFinder';

interface SnomedCtConceptFinderModalProps {
    show: boolean;
    onClose: () => void;
    initialConceptId: string;
    onConceptSelected: (concept: ViewModels.SnomedCtConceptViewModel) => void;
}

export const SnomedCtConceptFinderModal = (props: SnomedCtConceptFinderModalProps) => {

    const onClose = () => {
        props.onClose();
    }

    return (
        <Modal 
            show={props.show}
            onHide={onClose}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>{resolveText("SnomedCt_FindConcept")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SnomedCtEntryFinder
                    showSelectButton
                    initialConceptId={props.initialConceptId}
                    onConceptSelected={props.onConceptSelected}
                />
            </Modal.Body>
        </Modal>
    );

}