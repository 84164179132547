export const sortByOrdinalGroup = <T extends unknown>(collection: T[], sortKeySelector: (item: T) => string): T[] => {
    const deconstructKey = (key: string): string[] => {
        const regexMatch = key.match(/^([^0-9]*)([0-9]+)([^0-9]*)$/);
        if(!regexMatch) {
            return [ key ];
        }
        return regexMatch && regexMatch.length === 4
            ? regexMatch.slice(1, 4)
            : [ regexMatch[0] ];
    }
    const deconstructedKeyCollection = collection.map(item => ({
        key: sortKeySelector(item),
        deconstructedKey: deconstructKey(sortKeySelector(item)),
        item: item
    }));
    return deconstructedKeyCollection.sort((a,b) => {
        if(a.deconstructedKey.length !== 3 || b.deconstructedKey.length !== 3) {
            return a.deconstructedKey[0].localeCompare(b.deconstructedKey[0]);
        }
        if(a.deconstructedKey[0] !== b.deconstructedKey[0]) {
            return a.deconstructedKey[0].localeCompare(b.deconstructedKey[0]);
        }
        const numericA = Number(a.deconstructedKey[1]);
        const numericB = Number(b.deconstructedKey[1]);
        if(numericA !== numericB) {
            return numericA - numericB;
        }
        return a.deconstructedKey[2].localeCompare(b.deconstructedKey[2]);
    }).map(x => x.item);
}