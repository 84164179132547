import { Models } from '../../../localComponents/types/models';
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';

interface InstitutionAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Organizations.Institution> {
}

export const InstitutionAutocomplete = (props: InstitutionAutocompleteProps) => {

    return (
        <GenericAutocomplete
            {...props}
            apiPath='api/institutions'
            displayFunc={x => x.name}
        />
    );

}