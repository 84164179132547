import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { AccountsFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface AccountsState extends RemoteState<
    ViewModels.PatientAccountViewModel | ViewModels.EmployeeAccountViewModel | ViewModels.DeviceAccountViewModel,
    AccountsFilter
> {}

const initialState: AccountsState = {
    ...defaultRemoteInitialState(),
    filter: {}
}

export const accountsSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const accountQueryBuilder = (state: RootState, sliceState: AccountsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, accountFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
const accountFilterComparer = (f1: AccountsFilter | undefined, f2: AccountsFilter | undefined) => {
    if(!f1 || !f2) {
        if(!f1 && !f2) {
            return true;
        }
        return false;
    }
    return f1?.searchText === f2?.searchText;
}
export const accountsActions = {
    ...createRestApiActions(
        'accounts', 
        accountsSlice.actions, 
        state => state.accounts, 
        accountQueryBuilder, 
        accountFilterComparer
    ),
}
export const accountsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.accounts),
};