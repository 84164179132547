import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ContactsListFilter } from "../../types/frontendTypes";
import { Models } from "../../types/models";
import { RootState } from "../store/healthRecordStore";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ContactsState extends RemoteState<Models.Individuals.Contact, ContactsListFilter> {
}
const initialState: ContactsState = {
    ...defaultRemoteInitialState(),
}
export const contactsSlice = createSlice({
    name: 'contacts',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const contactFilterComparer = (f1?: ContactsListFilter, f2?: ContactsListFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false; 
    }
    return f1!.searchText === f2!.searchText
        && f1!.departmentId === f2!.departmentId;
}
const contactQueryBuilder = (state: RootState, sliceState: ContactsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, contactFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.departmentId) {
            queryParams.push({ key: 'departmentId', value: filter.departmentId });
        }
    }
    return queryParams;
}
export const contactsActions = {
    ...createRestApiActions(
        'contacts', 
        contactsSlice.actions,
        state => state.contacts,
        contactQueryBuilder,
        contactFilterComparer
    ),
};
export const contactsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.contacts),
};