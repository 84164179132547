import { Models } from '../../../localComponents/types/models';
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';

interface DrugAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Medication.Drug> {
}

export const DrugAutocomplete = (props: DrugAutocompleteProps) => {

    return (
        <GenericAutocomplete<Models.Medication.Drug>
            {...props}
            apiPath='api/drugs'
            displayFunc={x => x.productName}
        />
    )

}