export const PrivacyPolicyDe = () => {

    return (
        <>
            <p>
                Diese Datenschutzerklärung beschreibt wie shEHRd die persönlichen Daten verwendet die gesammelt werden wenn du die Webseite benutzt.
            </p>
            <h3>Themen:</h3>
            <ul>
                <li>Welche Daten werden gesammelt?</li>
                <li>Wie werden die Daten gesammelt?</li>
                <li>Wie werden die Daten verwendet?</li>
                <li>Wie werden die Daten gespeichert?</li>
                <li>Was sind deine Datenschutzrechte?</li>
                <li>Was sind Cookies?
                    <ul>
                        <li>Cookies verwalten</li>
                    </ul>
                </li>
                <li>Datenschutzerklärungen anderer Webseiten</li>
                <li>Änderung der Datenschutzerklärung</li>
                <li>Kontaktiere uns</li>
                <li>Kontaktiere die passende Behörde</li>
            </ul>
            <h3>Welche Daten werden gesammelt?</h3>
            <p>shEHRd speichert die folgenden Daten:</p>
            <ul>
                <li>Grundlegende persönliche Informationen (Name, Geburtsdatum, Geschlecht, Adressen, Telefonnr.,...)</li>
                <li>Gesundheitsdaten (die du oder eine von dir authorisierte Person eingeben)</li>
            </ul>
            <h3>Wie werden die Daten gesammelt?</h3>
            <p>
                Du teilst shEHRd die meisten dieser Daten direkt mit. Dies passiert wenn du:
            </p>
            <ul>
                <li>Dich registriert oder anmeldest</li>
                <li>Daten zu deiner Person oder Gesundheitsdaten eingibst</li>
            </ul>
            {/* <p>
                shEHRd empfängt auch indirekt Daten von diesen Quellen:
            </p>
            <ul>
                <li>Externe Patientenakten-Systeme</li>
            </ul> */}
            <h3>Wie werden die Daten verwendet?</h3>
            <p>shEHRd sammelt die Daten damit du:</p>
            <ul>
                <li>Dich anmelden kannst</li>
                <li>Deine Patientenakte benutzen kannst</li>
                <li>Andere deine Patientenakte einsehen und bearbeiten können, wenn sie von dir dazu berechtigt worden sind (inklusive jener die Zugriff über von dir erstellten Notfall-Tokens bekommen)</li>
            </ul>
            <p>shEHRd teilt deine Daten mit allen die von dir dazu berechtigt worden sind, hierunter auch anonyme Besucher, die Zugriff erhalten durch einen von dir erstellten Notfall-Token.</p>
            <h3>Wie werden die Daten gespeichert?</h3>
            <p>
                shEHRd speichert die Daten sicher auf Servern bei Hetzner in Falkenstein,
                Deutschland. Die Server sind durch eine Firewall geschützt und werden laufend
                mit den neuesten Sicherheitsupdates aktualisiert.
                shEHRd behält sich vor die Daten auf andere Server in der EU zu kopieren oder überführen um die Stabilität, Zuverlässigkeit und Geschwindigkeit der Webseite sicherzustellen
            </p>
            <p>
                shEHRd speichert deine Daten bis du deine Konten löschts.
                Nach der Löschung sind deine Daten noch bis zu 180 Tage in Backups gespeichert.
            </p>
            <h3>Was sind deine Datenschutzrechte?</h3>
            <p>
                shEHRd möchte dich gerne mit deinen Rechten vertraut machen. Deine Rechte sind:</p>
            <ul>
                <p>
                    <strong>Recht auf Einsicht</strong> &ndash;
                    Du hast das Recht dir von shEHRd die Daten zuschicken zu lassen die über dich gespeichert sind.
                </p>
                <p>
                    <strong>Recht auf Korrektur</strong>
                    &ndash; Du hast das Recht Informationen berichtigen zu lassen, die du für falsch hälst. 
                    Du hast auch das Recht unvollständige Informationen von shEHRd vervollständigen zu lassen.
                </p>
                <p>
                    <strong>Recht auf Löschung</strong>
                    &ndash; Du hast das Recht, mit gewissen Einschränkungen, deine persönliche Daten von shEHRd löschen zu lassen.
                </p>
                <p>
                    <strong>Recht auf Einschränkung der Verarbeitung</strong> 
                    &ndash; Du hast das Recht von shEHRd zu verlangen die Verarbeitung, mit gewissen Einschränkungen, einschränken zu lassen.
                </p>
                <p>
                    <strong>Recht der Verarbeitung zu widersprechen</strong> 
                    &ndash; Du hast das Recht der Verarbeitung deiner Daten durch shEHRd zu widersprechen.
                </p>
                <p>
                    <strong>Recht auf Datenübertragung</strong>
                    &ndash; Du hast das Recht, mit gewissen Einschränkungen, von shEHRd die Übertragung deiner Daten an dich oder eine andere Organisation zu verlangen.
                </p>
            </ul>
            <p>
                Wenn du eine Anfrage bezüglich dieser Rechte stellst, hat shEHRd einen Monat Zeit dir zu Antworten.
                Wenn du deine Rechte ausüben möchtest kannst du uns per Mail erreichen: <a href="mailto:jan@janscholtyssek.dk">jan@janscholtyssek.dk</a>
            </p>
            <h3>Cookies</h3>
            <p>
                Cookies sind eine Web-Technologie um kleine Informationsstücke zu speichern.
                shEHRd benutzt Cookies wenn ein externer Anmeldedienst genutzt wird.
                Dieser Cookie enthält Informationen über dich, die der externe Anmeldedienst bei der Anmeldung zurückschickt.
                Typischerweise sind dies deine Konto-ID bei dem Anmeldedienst und evtl. dein Name und andere Profildaten.
                Dieser Cookie ist nötig um den Anmeldeprozess durchführen zu können und dich bei allen Anfragen gegenüber dem shEHRd-API auszuweisen.
                Der Cookie wird gelöscht wenn du dich abmeldest oder der Browser geschlossen wird.
            </p>
            <p>Für weitere Informationen zu Cookies verweisen wir auf allaboutcookies.org.</p>
            <h3>Cookies verwalten</h3>
            <p>
                Du kannst deinen Browser dazu anweisen keine Cookies zu akzeptieren, und die obenstehende Webseite erklärt dir wie du Cookies aus deinem Browser löschen kannst.
                Allerdings können dadurch einige Funktionen der Webseite nicht mehr funktionieren.
            </p>
            <h3>Datenschutzerklärungen anderer Webseiten</h3>
            <p>
                Die shEHRd-Webseite enthält Links/Verweise zu anderen Webseiten. Diese Datenschutzerklärung gilt nur für unsere Webseite. 
                Wenn du einen Link zu einer anderen Webseite anklickst solltest du deren Datenschutzerklärung lesen.
            </p>
            <h3>Änderung der Datenschutzerklärung</h3>
            <p>
                shEHRd aktualisiert regelmäßig diese Datenschutzerklärung und legt sie auf dieser Seite ab. Letze Aktualisierung am 2022-09-28.
            </p>
            <h3>Kontaktiere uns</h3>
            <p>
                Wenn du Fragen hast zu dieser Datenschutzerklärung, den Daten die wir über dich gespeichert haben, oder du deine oben aufgeführten Datenschutzrechte ausüben möchtest, dann schreibe uns gerne.
            </p>
            <p>
                Email: <a href="mailto:jan@janscholtyssek.dk">jan@janscholtyssek.dk</a>
            </p>
            <h3>Kontaktiere die passende Behörde</h3>
            <p>
                Möchtest du dich beschweren oder findest du dass shEHRd dein Anliegen nicht zufriedenstellend adressiert hat, kannst du den European Data Protection Supervisor. kontaktieren
            </p>
            <p>
                Beschwerde an den EDPS: <a href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en">https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en</a>
            </p>
        </>
    );

}