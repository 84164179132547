import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import PersonIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/PersonIdAutocomplete";

interface PatientSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.PatientSubscription> {
}

export const PatientSubscriptionEditor = (props: PatientSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Patient")}</FormLabel>
            <PersonIdAutocomplete
                value={subscription.personId}
                onChange={personId => onChange(state => ({
                    ...state,
                    personId: personId
                } as Models.Subscriptions.PatientSubscription))}
            />
        </FormGroup>
    </>);

}