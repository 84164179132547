import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ViewModels } from "../../types/viewModels";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface InstitutionsState extends RemoteState<ViewModels.InstitutionViewModel, {}> {
}

const initialState: InstitutionsState = {
    ...defaultRemoteInitialState(),
}

export const institutionsSlice = createSlice({
    name: 'institutions',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const institutionFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const institutionQueryBuilder = (state: RootState, sliceState: InstitutionsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, institutionFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const institutionsActions = {
    ...createRestApiActions(
        'institutions', 
        institutionsSlice.actions,
        state => state.institutions,
        institutionQueryBuilder,
        institutionFilterComparer
    ),
};
export const institutionsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.institutions),
};