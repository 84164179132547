import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { StockIdAutocomplete } from "../Autocompletes/StockIdAutocomplete";

interface StockSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.StockSubscription> {
}

export const StockSubscriptionEditor = (props: StockSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Stock")}</FormLabel>
            <StockIdAutocomplete
                value={subscription.stockId}
                onChange={stockId => onChange(state => ({
                    ...state,
                    stockId: stockId
                } as Models.Subscriptions.StockSubscription))}
            />
        </FormGroup>
    </>);

}