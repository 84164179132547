import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";
import { getActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";

export interface RoomsState extends RemoteState<Models.Room, {}> {
}

const initialState: RoomsState = {
    ...defaultRemoteInitialState(),
}

export const roomsSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const roomFilterComparer = (f1?: {}, f2?: {}) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return true;
}
const roomQueryBuilder = (state: RootState, sliceState: RoomsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, roomFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        // TODO
    }
    return queryParams;
}
export const roomsActions = {
    ...createRestApiActions(
        'rooms', 
        roomsSlice.actions,
        state => state.rooms,
        roomQueryBuilder,
        roomFilterComparer
    ),
    loadForInstitution: getActionBuilder<{ institutionId: string }, Models.Room[]>(
        args => `api/institutions/${args!.institutionId}/rooms`,
        () => resolveText("Rooms_CouldNotLoad"),
        roomsSlice.actions.setIsLoading,
        (dispatch,response,_) => dispatch(roomsSlice.actions.addOrUpdateItems(response))
    ),
    loadForDepartment: getActionBuilder<{ departmentId: string }, Models.Room[]>(
        args => `api/departments/${args!.departmentId}/rooms`,
        () => resolveText("Rooms_CouldNotLoad"),
        roomsSlice.actions.setIsLoading,
        (dispatch,response,_) => dispatch(roomsSlice.actions.addOrUpdateItems(response))
    ),
};
export const roomsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.rooms),
    createSelectForDepartment: () => createSelector(
        (state: RootState) => state.rooms.items,
        (_: RootState, args: { department: Models.Organizations.Department | undefined }) => args.department,
        (items, department) => department
            ? items.filter(x => department.roomIds.includes(x.id))
            : []
    ),
};