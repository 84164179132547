import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { ViewModels } from '../../types/viewModels';


interface ResourceAutocompleteProps extends GenericAutocompleteImplementationProps<ViewModels.ResourceViewModel> {
}

export const ResourceAutocomplete = (props: ResourceAutocompleteProps) => {

    return (
        <GenericAutocomplete<ViewModels.ResourceViewModel>
            {...props}
            apiPath='api/resources'
            displayFunc={x => x.name}
        />
    );

}