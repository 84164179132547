import { Models } from "../../../localComponents/types/models";
import GenericIdAutocomplete from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { DrugAutocomplete } from "./DrugAutocomplete";

interface DrugIdAutocompleteProps extends IdAutocompleteProps {}

export const DrugIdAutocomplete = (props: DrugIdAutocompleteProps) => {

    return (
        <GenericIdAutocomplete<Models.Medication.Drug>
            loadItemApiPathBuilder={id => `api/drugs/${id}`}
            autocompleteBuilder={(key,props) => (
                <DrugAutocomplete
                    key={key}
                    {...props}
                />
            )}
            {...props}
        />
    );

}
export default DrugIdAutocomplete;