import { lazy } from 'react';
import { AccountType } from '../types/enums';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { Models } from '../types/models';
import RegisteredUserRoutes from './RegisteredUserRoutes';
import JankisHealthRecordRoutes from './JankisHealthRecordRoutes';
import ChecklistRoutes from '../../checklist/navigation/ChecklistRoutes';
import FunctionalGroupListPage from '../pages/Collaboration/FunctionalGroupListPage';
import { CreateEditFunctionalGroupPage } from '../pages/Collaboration/CreateEditFunctionalGroupPage';
import FunctionalGroupMembersPage from '../pages/Collaboration/FunctionalGroupMembersPage';
import QuickAdmitPatientPage from '../pages/Registration/QuickAdmitPatientPage';
import DepartmentRoomLayoutEditPage from '../pages/Departments/DepartmentRoomLayoutEditPage';
import PrintDepartmentPatientsListPage from '../pages/Departments/PrintDepartmentPatientsListPage';

const CheckinPage = lazy(() => import('../pages/Registration/CheckinPage'));
const HealthRecordEnvironment = lazy(() => import('../../sharedHealthComponents/pages/Patients/HealthRecordEnvironment'));
const KnowledgeBaseStartPage = lazy(() => import('../pages/Collaboration/KnowledgeBaseStartPage'));
const KnowledgeBaseArticleViewerPage = lazy(() => import('../pages/Collaboration/KnowledgeBaseArticleViewerPage'));
const CreateEditKnowledgeBaseArticlePage = lazy(() => import('../pages/Collaboration/CreateEditKnowledgeBaseArticlePage'));
const DepartmentPatientJourneysPage = lazy(() => import('../pages/Departments/DepartmentPatientJourneysPage'));
const FeedbackListPage = lazy(() => import('../pages/FeedbackListPage'));
const PrintTaskListPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/PrintTaskListPage'));
const DepartmentSelectionPage = lazy(() => import('../pages/EntryPages/DepartmentSelectionPage'));
const CreateEditServicePackagePage = lazy(() => import('../pages/Services/CreateEditServicePackagePage'));
const CreateEditMealMenuPage = lazy(() => import('../pages/Services/MealService/CreateEditMealMenuPage'));
const CreateEditAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/CreateEditAppointmentPage'));
const AppointmentsListPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/AppointmentsListPage'));
const PrintAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/PrintAppointmentPage'));
const EditInstitutionSchedulePage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/EditInstitutionSchedulePage'));
const CreateEditQuestionnairePage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/CreateEditQuestionnairePage'));
const CreateEditPractitionerPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/CreateEditPractitionerPage'));
const EditPersonPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/EditPersonPage'));
const PersonsListPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/PersonsListPage'));
const InstitutionEditPage = lazy(() => import('../pages/Config/InstitutionEditPage'));
const InstitutionsListPage = lazy(() => import('../pages/Config/InstitutionsListPage'));
const ConsumableEditPage = lazy(() => import('../pages/Materials/ConsumableEditPage'));
const ConsumablePage = lazy(() => import('../pages/Materials/ConsumablePage'));
const ConsumablesListPage = lazy(() => import('../pages/Materials/ConsumablesListPage'));
const ContactEditPage = lazy(() => import('../pages/ContactList/ContactEditPage'));
const ContactPage = lazy(() => import('../pages/ContactList/ContactPage'));
const ContactsListPage = lazy(() => import('../pages/ContactList/ContactsListPage'));
const BedOccupancyEditPage = lazy(() => import('../pages/Departments/BedOccupancyEditPage'));
const DepartmentEditPage = lazy(() => import('../pages/Departments/DepartmentEditPage'));
const DepartmentPage = lazy(() => import('../pages/Departments/DepartmentPage'));
const DepartmentsListPage = lazy(() => import('../pages/Departments/DepartmentsListPage'));
const RoomsPage = lazy(() => import('../pages/Departments/RoomsPage'));
const RoomPage = lazy(() => import('../pages/Departments/RoomPage'));
const CreateEditDrugPage = lazy(() => import('../pages/Drugs/CreateEditDrugPage'));
const DrugsListPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/DrugsListPage'));
const MyPatientsPage = lazy(() => import('../pages/Patients/MyPatientsPage'));
const InstitutionEncountersListPage = lazy(() => import('../pages/Registration/InstitutionEncountersListPage'));
const CreateEditEncounterPage = lazy(() => import('../pages/Registration/CreateEditEncounterPage'));
const ResourceEditPage = lazy(() => import('../pages/Materials/ResourceEditPage'));
const ResourcePage = lazy(() => import('../pages/Materials/ResourcePage'));
const ResourcesListPage = lazy(() => import('../pages/Materials/ResourcesListPage'));
const RequestServicePage = lazy(() => import('../pages/Services/RequestServicePage'));
const ServiceEditPage = lazy(() => import('../pages/Services/CreateEditServicePage'));
const ServicePage = lazy(() => import('../pages/Services/ServicePage'));
const ServicePackagesListPage = lazy(() => import('../pages/Services/ServicePackagesListPage'));
const ServiceRequestPage = lazy(() => import('../pages/Services/ServiceRequestPage'));
const ServiceRequestsListPage = lazy(() => import('../pages/Services/ServiceRequestsListPage'));
const ServicesListPage = lazy(() => import('../pages/Services/ServicesListPage'));
const StockEditPage = lazy(() => import('../pages/Stocks/StockEditPage'));
const StockPage = lazy(() => import('../pages/Stocks/StockPage'));
const StocksListPage = lazy(() => import('../pages/Stocks/StocksListPage'));
const AccountEditPage = lazy(() => import('../pages/UserManagement/AccountEditPage'));
const AccountsListPage = lazy(() => import('../pages/UserManagement/AccountsListPage'));
const RoleEditPage = lazy(() => import('../pages/UserManagement/RoleEditPage'));
const RolePage = lazy(() => import('../pages/UserManagement/RolePage'));
const RolesListPage = lazy(() => import('../pages/UserManagement/RolesListPage'));
const QuestionnairesListPage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/QuestionnairesListPage'));
const NewsEditPage = lazy(() => import('../pages/News/NewsEditPage'));
const MaterialOrdersListPage = lazy(() => import('../pages/Materials/MaterialOrdersListPage'));
const MaterialOrderPage = lazy(() => import('../pages/Materials/MaterialOrderPage'));
const CreateEditTodoListItemPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTodoListItemPage'));
const TodoListsPage = lazy(() => import('../pages/Collaboration/TodoListsPage'));
const DeployTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/DeployTasksPackagePage'));
const TasksPackagesPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TasksPackagesPage'));
const CreateEditTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTasksPackagePage'));
const ClinicHomePage = lazy(() => import('../pages/EntryPages/ClinicHomePage'));
const GenericHomePage = lazy(() => import('../pages/EntryPages/GenericHomePage'));
const MealServiceHomePage = lazy(() => import('../pages/EntryPages/MealServiceHomePage'));
const RegistrationEntryPage = lazy(() => import('../pages/EntryPages/RegistrationEntryPage'));
const WardHomePage = lazy(() => import('../pages/EntryPages/WardHomePage'));
const ExportPatientDataPage = lazy(() => import('../../sharedHealthComponents/pages/DataImportExport/ExportPatientDataPage'));
const AdverseEventsListPage = lazy(() => import('../pages/Collaboration/AdverseEventsListPage'));
const ReportAdverseEventPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/ReportAdverseEventPage'));
const TaskPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TaskPage'));
const TransferDataToFromShehrdPage = lazy(() => import('../pages/DataImportExport/TransferDataToFromShehrdPage'));
const DiagnosticTestsPackagesExplorerPage = lazy(() => import('../../sharedHealthComponents/pages/TestResults/DiagnosticTestsPackagesExplorerPage'));
const InvoicingOverviewPage = lazy(() => import('../pages/Financial/InvoicingOverviewPage'));
const CreateEditInvoicePage = lazy(() => import('../pages/Financial/CreateEditInvoicePage'));
const PrintInvoicePage = lazy(() => import('../pages/Financial/PrintInvoicePage'));
const PrintTemplateEditorPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateEditorPage'));
const CreateEditPrescriptionPage = lazy(() => import('../pages/Drugs/CreateEditPrescriptionPage'));
const PrescriptionPrintPage = lazy(() => import('../pages/Drugs/PrescriptionPrintPage'));
const ActionsPage = lazy(() => import('../pages/EntryPages/ActionsPage'));
const PrintTemplateListPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateListPage'));
const EquipmentMeasurementsListPage = lazy(() => import('../../sharedHealthComponents/pages/Equipments/EquipmentMeasurementsListPage'));
const CreateEditEquipmentMeasurement = lazy(() => import('../../sharedHealthComponents/pages/Equipments/CreateEditEquipmentMeasurement'));
const CreateEditPharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/CreateEditPharmacyOrderPage'));
const PharmacyOrdersListPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrdersListPage'));
const PharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrderPage'));

export const ProfessionalPortalRoutes = (
    features: Models.Configuration.FeatureSettings,
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>): JankisRouteDefinition[] => {
    const routes: JankisRouteDefinition[] = [
        { path: '/', element: <DepartmentSelectionPage />, audience: [ AccountType.Employee ]},
        { path: '/home/clinic/:departmentId', element: <ClinicHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/generic/:departmentId', element: <GenericHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/mealservice/:departmentId', element: <MealServiceHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/registration/:departmentId', element: <RegistrationEntryPage />, audience: [ AccountType.Employee ]},
        { path: '/home/ward/:departmentId', element: <WardHomePage />, audience: [ AccountType.Employee ]},
        { path: '/home/actions', element: <ActionsPage />, audience: [ AccountType.Employee ]},

        // Persons
        { path: '/mypatients', element: <MyPatientsPage />, audience: [ AccountType.Employee ]},
        { path: '/persons', element: <PersonsListPage />, audience: [ AccountType.Employee ]},
        { path: '/create/person', element: <EditPersonPage />, audience: [ AccountType.Employee ] },
        { path: '/edit/person/:personId', element: <EditPersonPage />, audience: [ AccountType.Employee ] },
        { path: '/create/practitioner', element: <CreateEditPractitionerPage />, audience: [ AccountType.Employee ]},
        { path: '/edit/practitioner/:id', element: <CreateEditPractitionerPage />, audience: [ AccountType.Employee ]},

        // Institution
        { path: '/create/institution', element: <InstitutionEditPage />, audience: [ AccountType.Employee ]},
        { path: '/edit/institution/:id', element: <InstitutionEditPage />, audience: [ AccountType.Employee ]},
        { path: "/institutions", element: <InstitutionsListPage />, audience: [ AccountType.Employee ] },
        { path: "/institutions/:institutionId/edit", element: <InstitutionEditPage />, audience: [ AccountType.Employee ] },
        { path: "/checkin", element: <CheckinPage />, audience: [ AccountType.Employee ] },
        { path: "/institutions/:institutionId/checkin", element: <CheckinPage />, audience: [ AccountType.Employee ] },
        { path: "/institutions/:institutionId/rooms", element: <RoomsPage />, audience: [ AccountType.Employee ] },

        // Department
        { path: '/institution/:institutionId/create/department', element: <DepartmentEditPage />, audience: [ AccountType.Employee ]},
        { path: '/institution/:institutionId/edit/department/:departmentId', element: <DepartmentEditPage />, audience: [ AccountType.Employee ]},
        { path: "/departments", element: <DepartmentsListPage />, audience: [ AccountType.Employee ] },
        { path: "/create/department", element: <DepartmentEditPage />, audience: [ AccountType.Employee ] },
        { path: "/departments/:departmentId", element: <DepartmentPage />, audience: [ AccountType.Employee ] },
        { path: "/departments/:departmentId/edit", element: <DepartmentEditPage />, audience: [ AccountType.Employee ] },
        { path: "/institutions/:institutionId/departments/:departmentId/rooms", element: <RoomsPage />, audience: [ AccountType.Employee ] },
        { path: '/departments/:departmentId/clinic', element: <ClinicHomePage />, audience: [ AccountType.Employee ]},
        { path: '/departments/:departmentId/procedures', element: <DepartmentPatientJourneysPage />, audience: [ AccountType.Employee ]},

        // Rooms & beds
        { path: "/rooms", element: <RoomsPage />, audience: [ AccountType.Employee ] },
        { path: "/department/:departmentId/room/:roomId", element: <RoomPage />, audience: [ AccountType.Employee ] },
        { path: "/create/bedoccupancy/institution/:institutionId/department/:departmentId/room/:roomId/bed/:bedPosition", element: <BedOccupancyEditPage />, audience: [ AccountType.Employee ] },
        { path: "/bedoccupancies/:occupancyId/edit", element: <BedOccupancyEditPage />, audience: [ AccountType.Employee ] },
        { path: '/quick-admit', element: <QuickAdmitPatientPage />, audience: [ AccountType.Employee ]},
        { path: '/department/:departmentId/layout', element: <DepartmentRoomLayoutEditPage />, audience: [ AccountType.Employee ]},
        { path: '/department/:departmentId/print-bedlist', element: <PrintDepartmentPatientsListPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},

        // User management
        // { path: '/institution/:id/manage', element: <ManageInstitutionMembersPage />, audience: [ AccountType.Employee ]},
        { path: "/accounts", element: <AccountsListPage />, audience: [ AccountType.Employee ] },
        { path: "/create/account", element: <AccountEditPage />, audience: [ AccountType.Employee ] },
        { path: "/accounts/:accountId/edit", element: <AccountEditPage />, audience: [ AccountType.Employee ] },
        { path: "/roles", element: <RolesListPage />, audience: [ AccountType.Employee ] },
        { path: "/create/role", element: <RoleEditPage />, audience: [ AccountType.Employee ] },
        { path: "/roles/:roleId", element: <RolePage />, audience: [ AccountType.Employee ] },
        { path: "/roles/:roleId/edit", element: <RoleEditPage />, audience: [ AccountType.Employee ] },

        // Functional groups
        { path: '/functionalGroups', element: <FunctionalGroupListPage />, audience: [ AccountType.Employee ]},
        { path: '/create/functionalGroup', element: <CreateEditFunctionalGroupPage />, audience: [ AccountType.Employee ]},
        { path: '/edit/functionalGroup/:id', element: <CreateEditFunctionalGroupPage />, audience: [ AccountType.Employee ]},
        { path: '/functionalGroup/:groupId/members', element: <FunctionalGroupMembersPage />, audience: [ AccountType.Employee ]},

        // Print templates
        { path: "/print-templates", element: <PrintTemplateListPage />, audience: [ AccountType.Employee ] },
        { path: "/create/print-template", element: <PrintTemplateEditorPage />, audience: [ AccountType.Employee ] },
        { path: "/edit/print-template/:id", element: <PrintTemplateEditorPage />, audience: [ AccountType.Employee ] },

        { path: '/knowledgeBase', element: <KnowledgeBaseStartPage />, audience: [ AccountType.Employee ]},
        { path: '/knowledgeBase/articles/:id', element: <KnowledgeBaseArticleViewerPage />, audience: [ AccountType.Employee ]},
        { path: '/knowledgeBase/create/article', element: <CreateEditKnowledgeBaseArticlePage />, audience: [ AccountType.Employee ]},
        { path: '/knowledgeBase/edit/article/:id', element: <CreateEditKnowledgeBaseArticlePage />, audience: [ AccountType.Employee ]},

        // Feedback
        { path: '/feedback', element: <FeedbackListPage />, audience: [ AccountType.Employee ]},
        
        ...RegisteredUserRoutes(features, onNewAuthenticationResult, onLogOut),
        { path: '/healthrecord/:personId/*', element: <HealthRecordEnvironment routes={JankisHealthRecordRoutes(features)} />, usesCustomLayout: true }
    ];
    if(features.enableAdverseEvents) {
        const adverseEventRoutes: JankisRouteDefinition[] = [
            { path: '/adverse-events', element: <AdverseEventsListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/adverse-event', element: <ReportAdverseEventPage />, audience: [ AccountType.Employee ]},
            { path: '/edit/adverse-event/:id', element: <ReportAdverseEventPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...adverseEventRoutes);
    }
    if(features.enableContactList) {
        const contactListRoutes: JankisRouteDefinition[] = [
            { path: "/contacts", element: <ContactsListPage />, audience: [ AccountType.Employee ] },
            { path: "/create/contact", element: <ContactEditPage />, audience: [ AccountType.Employee ] },
            { path: "/contacts/:contactId", element: <ContactPage />, audience: [ AccountType.Employee ] },
            { path: "/contacts/:contactId/edit", element: <ContactEditPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...contactListRoutes);
    }
    if(features.enableDataImportExport) {
        const ioRoutes: JankisRouteDefinition[] = [
            { path: "/export", element: <ExportPatientDataPage />, audience: [ AccountType.Employee ]},
            { path: "/shehrd-sync", element: <TransferDataToFromShehrdPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...ioRoutes);
    }
    if(features.enableInvoicing) {
        const invoicingRoutes: JankisRouteDefinition[] = [
            { path: "/invoices", element: <InvoicingOverviewPage />, audience: [ AccountType.Employee ] },
            { path: "/create/invoice/institution/:institutionId", element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: "/create/invoice/institution/:institutionId/person/:personId/encounter/:encounterId", element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: "/edit/invoice/:id", element: <CreateEditInvoicePage />, audience: [ AccountType.Employee ] },
            { path: "/print/invoice/:id", element: <PrintInvoicePage />, usesCustomLayout: true, audience: [ AccountType.Employee ] },
        ];
        routes.push(...invoicingRoutes);
    }
    if(features.enableNews) {
        const newsRoutes: JankisRouteDefinition[] = [
            { path: "/create/news", element: <NewsEditPage />, audience: [ AccountType.Employee ] },
            { path: "/edit/news/:id", element: <NewsEditPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...newsRoutes);
    }
    if(features.enablePatientPortal) {
        const patientPortalRoutes: JankisRouteDefinition[] = [
            // Nothing to include for now
        ];
        routes.push(...patientPortalRoutes);
    }
    if(features.enableScheduling) {
        const schedulingEventRoutes: JankisRouteDefinition[] = [
            { path: '/institution/:institutionId/schedule/edit', element: <EditInstitutionSchedulePage />, audience: [ AccountType.Employee ]},
            { path: '/institution/:institutionId/department/:departmentId/schedule/edit', element: <EditInstitutionSchedulePage />, audience: [ AccountType.Employee ]},
            { path: '/encounters', element: <InstitutionEncountersListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/encounter', element: <CreateEditEncounterPage />, audience: [ AccountType.Employee ]},
            { path: '/department/:departmentId/create/encounter', element: <CreateEditEncounterPage />, audience: [ AccountType.Employee ]},
            { path: '/appointments', element: <AppointmentsListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/appointment', element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: '/institution/:institutionId/create/appointment', element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: '/institution/:institutionId/department/:departmentId/create/appointment', element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: '/edit/appointment/:id', element: <CreateEditAppointmentPage />, audience: [ AccountType.Employee ]},
            { path: '/print/appointment/:id', element: <PrintAppointmentPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...schedulingEventRoutes);
    }
    if(features.enableServices) {
        const servicesRoutes: JankisRouteDefinition[] = [
            // Service
            { path: '/services', element: <ServicesListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/service', element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: '/department/:departmentId/create/service', element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: '/services/:serviceId', element: <ServicePage />, audience: [ AccountType.Employee ]},
            { path: '/services/:serviceId/edit', element: <ServiceEditPage />, audience: [ AccountType.Employee ]},
            { path: "/departments/:departmentId/services", element: <ServicesListPage />, audience: [ AccountType.Employee ] },
            { path: '/servicepackage/:servicePackageId/services', element: <ServicesListPage />, audience: [ AccountType.Employee ]},

            // Service package
            { path: '/servicepackages', element: <ServicePackagesListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/servicepackage', element: <CreateEditServicePackagePage />, audience: [ AccountType.Employee ]},
            { path: "/departments/:departmentId/menus", element: <ServicePackagesListPage />, audience: [ AccountType.Employee ] },
            { path: '/edit/servicepackage/:servicePackageId', element: <CreateEditServicePackagePage />, audience: [ AccountType.Employee ]},
            { path: '/edit/menu/:menuId', element: <CreateEditMealMenuPage />, audience: [ AccountType.Employee ]},

            // Service request
            { path: '/servicerequests', element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ]},
            { path: '/services/:serviceId/requests', element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ]},
            { path: "/departments/:departmentId/requests", element: <ServiceRequestsListPage />, audience: [ AccountType.Employee ] },
            { path: '/servicerequests/:requestId', element: <ServiceRequestPage />, audience: [ AccountType.Employee ]},
            { path: '/create/servicerequest', element: <RequestServicePage />, audience: [ AccountType.Employee ]},
            { path: '/services/:serviceId/request', element: <RequestServicePage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...servicesRoutes);
    }
    if(features.enableTaskList) {
        const taskListRoutes: JankisRouteDefinition[] = [
            { path: "/create/todolistitem", element: <CreateEditTodoListItemPage />, audience: [ AccountType.Employee ]},
            { path: "/edit/todolistitem/:id", element: <CreateEditTodoListItemPage />, audience: [ AccountType.Employee ]},
            { path: "/todolist", element: <TodoListsPage />, audience: [ AccountType.Employee ]},
            { path: "/task/:taskId", element: <TaskPage />, audience: [ AccountType.Employee ]},
            { path: "/taskpackages", element: <TasksPackagesPage />, audience: [ AccountType.Employee ]},
            { path: "/create/taskpackage", element: <CreateEditTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: "/edit/taskpackage/:id", element: <CreateEditTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: "/taskpackages/deploy", element: <DeployTasksPackagePage />, audience: [ AccountType.Employee ]},
            { path: "/taskpackages/deploy/:tasksPackageId", element: <DeployTasksPackagePage />, audience: [ AccountType.Employee ]},

            { path: "/print/tasks", element: <PrintTaskListPage />, usesCustomLayout: true, audience: [ AccountType.Employee ]},

            ...ChecklistRoutes().map(route => ({
                ...route,
                audience: [ AccountType.Employee ]
            }) as JankisRouteDefinition)
        ];
        routes.push(...taskListRoutes);
    }
    if(features.enableWarehousing) {
        const warehousingRoutes: JankisRouteDefinition[] = [
            { path: "/resources", element: <ResourcesListPage />, audience: [ AccountType.Employee ] },
            { path: "/create/resource", element: <ResourceEditPage />, audience: [ AccountType.Employee ] },
            { path: "/resources/:resourceId", element: <ResourcePage />, audience: [ AccountType.Employee ] },
            { path: "/resources/:resourceId/edit", element: <ResourceEditPage />, audience: [ AccountType.Employee ] },

            { path: "/consumables", element: <ConsumablesListPage />, audience: [ AccountType.Employee ] },
            { path: "/create/consumable", element: <ConsumableEditPage />, audience: [ AccountType.Employee ] },
            { path: "/consumables/:consumableId", element: <ConsumablePage />, audience: [ AccountType.Employee ] },
            { path: "/consumables/:consumableId/edit", element: <ConsumableEditPage />, audience: [ AccountType.Employee ] },

            { path: "/materialorders", element: <MaterialOrdersListPage />, audience: [ AccountType.Employee ] },
            { path: "/materialorder/:orderId", element: <MaterialOrderPage />, audience: [ AccountType.Employee ] },

            { path: "/stocks", element: <StocksListPage />, audience: [ AccountType.Employee ] },
            { path: "/create/stock", element: <StockEditPage />, audience: [ AccountType.Employee ] },
            { path: "/stocks/:stockId", element: <StockPage />, audience: [ AccountType.Employee ] },
            { path: "/stocks/:stockId/edit", element: <StockEditPage />, audience: [ AccountType.Employee ] },

            { path: "/departments/:departmentId/resources", element: <ResourcesListPage />, audience: [ AccountType.Employee ] },
            { path: "/departments/:departmentId/stocks", element: <StocksListPage />, audience: [ AccountType.Employee ] },
        ];
        routes.push(...warehousingRoutes);
    }
    if(features.healthRecord.enableMedication) {
        const medicationEventRoutes: JankisRouteDefinition[] = [
            { path: "/create/drug", element: <CreateEditDrugPage />, audience: [ AccountType.Employee ]},
            { path: "/drugs", element: <DrugsListPage />, audience: [ AccountType.Employee ] },
            { path: "/drugs/:drugId/edit", element: <CreateEditDrugPage />, audience: [ AccountType.Employee ] },

            { path: "/healthrecord/:personId/create/prescription", element: <CreateEditPrescriptionPage />, audience: [ AccountType.Employee ] },
            { path: "/healthrecord/:personId/edit/prescription/:id", element: <CreateEditPrescriptionPage />, audience: [ AccountType.Employee ] },
            { path: "/print/prescription/:id", element: <PrescriptionPrintPage />, audience: [ AccountType.Employee, AccountType.Patient ]},
            { path: '/create/pharmacyOrder', element: <CreateEditPharmacyOrderPage />, audience: [ AccountType.Employee ]},
            { path: '/edit/pharmacyOrder/:id', element: <CreateEditPharmacyOrderPage />, audience: [ AccountType.Employee ]},
            { path: '/pharmacyOrders', element: <PharmacyOrdersListPage />, audience: [ AccountType.Employee ]},
            { path: '/pharmacyOrder/:id', element: <PharmacyOrderPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...medicationEventRoutes);
    }
    if(features.healthRecord.enableQuestionnaires) {
        const questionnaireEventRoutes: JankisRouteDefinition[] = [
            { path: '/questionnaires', element: <QuestionnairesListPage />, audience: [ AccountType.Employee ]},
            { path: '/create/questionnaire', element: <CreateEditQuestionnairePage />, audience: [ AccountType.Employee ]},
            { path: '/edit/questionnaire/:id', element: <CreateEditQuestionnairePage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...questionnaireEventRoutes);
    }
    if(features.healthRecord.enableTestResults) {
        const testResultRoutes: JankisRouteDefinition[] = [
            { path: "/testpackages", element: <DiagnosticTestsPackagesExplorerPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...testResultRoutes);
    }
    const equipmentMeasurementRoutes: JankisRouteDefinition[] = [
        { path: '/equipmentmeasurements' , element: <EquipmentMeasurementsListPage />, audience: [ AccountType.Employee ]},
        { path: '/create/equipmentmeasurement', element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.Employee ]},
        { path: '/edit/equipmentmeasurement/:id', element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.Employee ]}
    ]
    routes.push(...equipmentMeasurementRoutes);
    return routes;
}