import { Col, Row } from "react-bootstrap";
import { ClassificationType, DiagnosticTestScaleType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { GenericAutocompleteImplementationProps, GenericAutocomplete } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";
import { useEffect } from "react";
import { useAppDispatch } from "../../../localComponents/redux/store/healthRecordStore";
import { toLoincConcept } from "../../helpers/ClassificationReferenceHelpers";
import { useMemorizedSelector } from "../../redux/helpers/ReduxHooks";
import { classificationsSelectors, classificationsActions } from "../../redux/slices/classificationsSlice";
import { ClassificationReferenceSuggestions } from "./ClassificationReferenceSuggestions";

interface LoincAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Classifications.LoincConcept> {
    scaleTypes?: DiagnosticTestScaleType[];
    suggestedConceptIds?: string[];
    autocompleteContext?: string;
    maxSuggestions?: number;
}

export const LoincAutocomplete = (props: LoincAutocompleteProps) => {

    const { 
        value,
        onChange,
        scaleTypes, 
        suggestedConceptIds, 
        autocompleteContext, 
        maxSuggestions,
        disabled 
    } = props;
    const additionalParameters = scaleTypes && scaleTypes.length > 0 
        ? { scaleTypes: props.scaleTypes!.join(',') }
        : undefined;

    const suggestions = useMemorizedSelector(classificationsSelectors.createSelectLoincConcepts, { ids: suggestedConceptIds ?? [] });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!suggestedConceptIds) {
            return;
        }
        dispatch(classificationsActions.loadLoincConceptsIfNotLoaded({
            args: {
                conceptIds: suggestedConceptIds
            }
        }));
    }, [ suggestedConceptIds, dispatch ]);


    return (
    <>
        <Row>
            <Col>
                <GenericAutocomplete<Models.Classifications.LoincConcept>
                    {...props}
                    apiPath='api/classifications/loinc'
                    displayFunc={x => `${x.id} - ${x.name}`}
                    additionalParameters={additionalParameters}
                />
            </Col>
        </Row>
        {!value && !disabled
        ? <ClassificationReferenceSuggestions
            context={autocompleteContext}
            classification={ClassificationType.Icd11}
            maxCount={maxSuggestions}
            additionalSuggestions={suggestions}
            onSuggestionSelected={suggestion => onChange(toLoincConcept(suggestion))}
        /> : null}
    </>);

}
export default LoincAutocomplete;