import { Alert, FormGroup, FormLabel } from "react-bootstrap";
import { AccordionCard } from "../../../sharedCommonComponents/components/AccordionCard";
import { MedicationDispenseQrCodeCommand, ObservationQrCodeCommand, PersonQrCodeCommand, ProcedureQrCodeCommand, QrCodeCommand, QrCodeCommandType, SpecimenQrCodeCommand } from "../../helpers/QrCommandParser";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Update } from "../../../sharedCommonComponents/types/frontendTypes";
import ClassificationReferenceAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/ClassificationReferenceAutocomplete";
import { ClassificationType } from "../../types/enums";
import { UnitValueFormControl } from "../../../sharedHealthComponents/components/UnitValueFormControl";
import DrugIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/DrugIdAutocomplete";
import PersonIdAutocomplete from "../../../sharedHealthComponents/components/Autocompletes/PersonIdAutocomplete";

interface QrCodeCommandCardProps {
    command: QrCodeCommand;
    onChange: (update: Update<QrCodeCommand>) => void;
}

export const QrCodeCommandCard = (props: QrCodeCommandCardProps) => {

    const { command, onChange } = props;

    let typeSpecificControls = null;
    switch(command.type) {
        case QrCodeCommandType.MedicationDispense:
        case QrCodeCommandType.Observation:
        case QrCodeCommandType.Procedure:
        case QrCodeCommandType.Specimen:
            typeSpecificControls = (
                <PersonQrCodeCommandControls
                    command={command as PersonQrCodeCommand}
                    onChange={onChange}
                />
            );
            break;
    }

    if(!typeSpecificControls) {
        return (<Alert
        >
            {command.type}
        </Alert>);
    }

    return (
        <AccordionCard
            title={command.type}
            eventKey={command.id}
        >
            {typeSpecificControls}
        </AccordionCard>
    );

}
interface PersonQrCodeCommandControlsProps {
    command: PersonQrCodeCommand;
    onChange: (update: Update<PersonQrCodeCommand>) => void;
}
const PersonQrCodeCommandControls = (props: PersonQrCodeCommandControlsProps) => {

    const { command, onChange } = props;

    let typeSpecificControls = null;
    switch(command.type) {
        case QrCodeCommandType.MedicationDispense:
            typeSpecificControls = (
                <MedicationDispenseQrCodeCommandControls
                    command={command as MedicationDispenseQrCodeCommand}
                    onChange={onChange}
                />
            );
            break;
        case QrCodeCommandType.Observation:
            typeSpecificControls = (
                <ObservationQrCodeCommandControls
                    command={command}
                    onChange={onChange}
                />
            );
            break;
        case QrCodeCommandType.Procedure:
            typeSpecificControls = (
                <ProcedureQrCodeCommandControls
                    command={command}
                    onChange={onChange}
                />
            );
            break;
        case QrCodeCommandType.Specimen:
            typeSpecificControls = (
                <SpecimenQrCodeCommandControls
                    command={command}
                    onChange={onChange}
                />
            );
            break;
    }

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Person")}</FormLabel>
            <PersonIdAutocomplete
                value={command.personId}
                onChange={personId => onChange(state => ({
                    ...state,
                    personId: personId
                }))}
            />
        </FormGroup>
        {typeSpecificControls}
    </>);
}
interface MedicationDispenseQrCodeCommandControlsProps {
    command: MedicationDispenseQrCodeCommand;
    onChange: (update: Update<MedicationDispenseQrCodeCommand>) => void;
}
const MedicationDispenseQrCodeCommandControls = (props: MedicationDispenseQrCodeCommandControlsProps) => {
    const { command, onChange } = props;
    
    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Drug")}</FormLabel>
            <DrugIdAutocomplete
                value={command.drugId}
                onChange={drugId => onChange(state => ({
                    ...state,
                    drugId: drugId
                }))}
            />
        </FormGroup>
    </>);
}
interface ObservationQrCodeCommandControlsProps {
    command: ObservationQrCodeCommand;
    onChange: (update: Update<ObservationQrCodeCommand>) => void;
}
const ObservationQrCodeCommandControls = (props: ObservationQrCodeCommandControlsProps) => {
    const { command, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("DiagnosticTestResult_Code")}</FormLabel>
            <ClassificationReferenceAutocomplete
                classification={ClassificationType.Loinc}
                value={command.code}
                onChange={classificationReference => onChange(state => ({
                    ...state,
                    code: classificationReference
                }))}
            />
        </FormGroup>
        <FormGroup>
            <FormLabel>{resolveText("QuantitativeDiagnosticTestResult_Value")}</FormLabel>
            <UnitValueFormControl
                value={Number(command.value ?? '0')}
                unit={command.unit ?? ''}
                onValueChanged={value => onChange(state => ({
                    ...state,
                    value: value + ''
                }))}
                onUnitChanged={unit => onChange(state => ({
                    ...state,
                    unit: unit
                }))}
            />
        </FormGroup>
    </>);
}
interface ProcedureQrCodeCommandControlsProps {
    command: ProcedureQrCodeCommand;
    onChange: (update: Update<ProcedureQrCodeCommand>) => void;
}
const ProcedureQrCodeCommandControls = (props: ProcedureQrCodeCommandControlsProps) => {
    const { command, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("MedicalProcedure_Code")}</FormLabel>
            <ClassificationReferenceAutocomplete
                classification={ClassificationType.SnomedCt}
                value={command.code}
                onChange={classificationReference => onChange(state => ({
                    ...state,
                    code: classificationReference
                }))}
            />
        </FormGroup>
    </>);
}
interface SpecimenQrCodeCommandControlsProps {
    command: SpecimenQrCodeCommand;
    onChange: (update: Update<SpecimenQrCodeCommand>) => void;
}
const SpecimenQrCodeCommandControls = (props: SpecimenQrCodeCommandControlsProps) => {
    const { command, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Specimen")}</FormLabel>
            {/* Container auto complete */}
        </FormGroup>
    </>);
}
