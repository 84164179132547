import { FormEvent, useEffect, useState } from 'react';
import { Accordion, Alert, Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import QRCode from 'react-qr-code';
import { Center } from '../../sharedCommonComponents/components/Center';
import { QrCodeCommand, parseQrCodeCommands } from '../helpers/QrCommandParser';
import { QrCodeCommandCard } from '../components/HumanMachineInterface/QrCodeCommandCard';
import { useAppDispatch } from '../redux/store/healthRecordStore';
import { uploadQrCodeCommand } from '../helpers/QrCodeCommandUploader';

interface QRScannerModalProps {
    show: boolean;
    onHide: () => void;
}

const EyoyoQrCodes = {
    DataUpload: '%%SpecCode16',
    ClearData: '%%SpecCode18'
}

export const QRScannerModal = (props: QRScannerModalProps) => {

    const [ isReceiving, setIsReceiving ] = useState<boolean>(false);
    const [ qrCodeValue, setQrCodeValue ] = useState<string>(EyoyoQrCodes.DataUpload);
    const [ receivedData, setReceivedData ] = useState<string>('');
    const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
    const [ commands, setCommands ] = useState<QrCodeCommand[]>([]);
    //const [ generatorQrCodeValue, setGeneratorQrCodeValue ] = useState<string>('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(receivedData) {
            setIsReceiving(true);
            const timeout = setTimeout(finalizeDataInput, 500);
            return () => clearTimeout(timeout);
        } else {
            setIsReceiving(false);
        }
    }, [ receivedData ]);

    const finalizeDataInput = async () => {
        setIsProcessing(true);
        setIsReceiving(false);

        // TODO Parse and store data
        // Assume it was successful:
        const commands = parseQrCodeCommands(receivedData);
        setCommands(commands);

        setQrCodeValue(EyoyoQrCodes.ClearData);
        setIsProcessing(false);
    }

    const resetAndClose = () => {
        props.onHide();
        reset();
    }

    const reset = (e?: FormEvent) => {
        e?.preventDefault();

        setCommands([]);
        setReceivedData('');
        setQrCodeValue(EyoyoQrCodes.DataUpload);
        setIsProcessing(false);
        setIsReceiving(false);
        document.getElementById('received-data-input')?.focus();
    }
    const submit = (e?: FormEvent) => {
        e?.preventDefault();
        for (const command of commands) {
            uploadQrCodeCommand(command, dispatch);
        }
        resetAndClose();
    }

    return (
        <Modal show={props.show} onHide={resetAndClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{resolveText('QRCodeModal_Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isReceiving && !isProcessing 
                ? <Center className='mb-3'>
                    <div className='text-center'>
                        {qrCodeValue}
                    </div>
                    <QRCode value={qrCodeValue} />
                </Center>
                : null}
                <Form 
                    id='qrscanner-form' 
                    onSubmit={submit}
                    onReset={reset}
                >
                    <FormControl
                        autoFocus
                        as="textarea"
                        id='received-data-input'
                        value={receivedData}
                        onChange={e => setReceivedData(e.target.value)}
                        readOnly={isProcessing}
                    />
                    {isProcessing
                    ? <Alert variant='info'>
                        Processing...
                    </Alert> 
                    : commands.length > 0
                    ? <Accordion className='my-2'>
                        {commands.map(command => (
                            <QrCodeCommandCard
                                key={command.id}
                                command={command}
                                onChange={update => setCommands(state => state.map(x => x.id === command.id ? update(x) : x))}
                            />
                        ))}
                    </Accordion>
                    : null}
                </Form>
                {/* <hr />
                <Center className='my-3'>
                    <QRCode value={generatorQrCodeValue} />
                </Center>
                <FormControl onBlur={e => setGeneratorQrCodeValue(e.target.value)} /> */}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col />
                    <Col md="auto">
                        <Button
                            type='reset'
                            form='qrscanner-form'
                            variant='danger'
                        >
                            Reset
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button
                            type='submit'
                            form='qrscanner-form'
                            variant='primary'
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );

}