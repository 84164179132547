import GenericIdAutocomplete from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { StockAutocomplete } from "./StockAutocomplete";

interface StockIdAutocompleteProps extends IdAutocompleteProps {
}

export const StockIdAutocomplete = (props: StockIdAutocompleteProps) => {
    
    return (<GenericIdAutocomplete<ViewModels.StockViewModel>
        autocompleteBuilder={(key, props) => (
            <StockAutocomplete
                key={key}
                {...props}
            />
        )}
        loadItemApiPathBuilder={id => `api/subscriptions/${id}`}
        {...props}
    />);

}