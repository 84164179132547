import { createContext } from "react";
import { Models } from "../types/models";

export const noFeatures: Models.Configuration.FeatureSettings  = {
    enableAdverseEvents: false,
    enableContactList: false,
    enableDataImportExport: false,
    enableInvoicing: false,
    enableNews: false,
    enablePatientPortal: false,
    enableScheduling: false,
    enableServices: false,
    enableTaskList: false,
    enableWarehousing: false,
    healthRecord: {
        enableDiagnoses: false,
        enableDocuments: false,
        enableEquipment: false,
        enableIsolations: false,
        enableMedication: false,
        enableNotes: false,
        enableObservations: false,
        enableOpinions: false,
        enablePregnancies: false,
        enableProcedures: false,
        enableQuestionnaires: false,
        enableSpecimens: false,
        enableTestResults: false,
        enableFamilyTree: false,
    }
}

const FeatureContext = createContext<Models.Configuration.FeatureSettings>(noFeatures);
export const FeatureProvider = FeatureContext.Provider;
export default FeatureContext;