import { Row, Col, Badge, Spinner } from "react-bootstrap";
import { Models } from "../../../localComponents/types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ClassificationType } from "../../../localComponents/types/enums";
import { useEffect, useMemo, useState } from "react";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { QueryParameters } from "../../../sharedCommonComponents/types/frontendTypes";
import { areClassificationReferencesEqual } from "../../helpers/ClassificationReferenceHelpers";

interface ClassificationReferenceSuggestionsProps {
    context?: string;
    classification?: ClassificationType;
    maxCount?: number;
    additionalSuggestions?: Models.ClassificationReference[];
    onSuggestionSelected: (suggestion: Models.ClassificationReference) => void;
}

const getDistinctConcepts = (concepts: Models.ClassificationReference[]) => {
    const uniqueConcepts: Models.ClassificationReference[] = [];
    for (const concept of concepts) {
        if(uniqueConcepts.some(existingConcept => areClassificationReferencesEqual(concept, existingConcept))) {
            continue;
        }
        uniqueConcepts.push(concept);
    }
    return uniqueConcepts;
}
export const ClassificationReferenceSuggestions = (props: ClassificationReferenceSuggestionsProps) => {

    const { context, classification, maxCount, additionalSuggestions, onSuggestionSelected } = props;

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ personalSuggestions, setPersonalSuggestions ] = useState<Models.ClassificationReference[]>([]);

    useEffect(() => {
        if(!context) {
            return;
        }
        const queryParams: QueryParameters = [
            { key: 'context', value: context }
        ];
        if(classification) {
            queryParams.push({ key: 'classification', value: classification });
        }
        if(maxCount) {
            queryParams.push({ key: 'count', value: maxCount + '' });
        }

        setIsLoading(true);
        const loadSuggestions = buildLoadObjectFunc<Models.ClassificationReference[]>(
            'api/classifications/suggestions', queryParams,
            resolveText("Classification_CouldNotLoadSuggestions"),
            setPersonalSuggestions,
            undefined,
            () => setIsLoading(false)
        );
        loadSuggestions();
    }, [ context, classification, maxCount ]);
    const combinedSuggestions = useMemo(() => 
        getDistinctConcepts((personalSuggestions ?? []).concat(additionalSuggestions ?? []))
    , [ personalSuggestions, additionalSuggestions ]);

    if(combinedSuggestions.length === 0) {
        return null;
    }

    return (
    <Row className='mt-1'>
        <Col>
        <small>{resolveText("Suggestions")}:</small>
        {combinedSuggestions.map(suggestion => (
            <Badge pill
                key={suggestion.classification + suggestion.conceptId}
                onClick={() => onSuggestionSelected(suggestion)}
                bg='warning'
                text='dark'
                className='clickable mx-2 mb-2 p-2'
            >
                {suggestion.displayName}
            </Badge>
        ))}
        {isLoading ? <Spinner size="sm" variant="warning" /> : null}
        </Col>
    </Row>);

}