import React, { ReactNode, useContext } from 'react';
import FeatureContext from '../../contexts/FeatureContext';
import { NavDropdown } from 'react-bootstrap';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { Models } from '../../types/models';
import { useNavigate } from 'react-router-dom';
import { DepartmentType } from '../../types/enums';

interface DepartmentSubMenuProps {
    department: Models.Organizations.Department;
}

export const DepartmentSubMenu = (props: DepartmentSubMenuProps) => {

    const { department } = props;
    const features = useContext(FeatureContext);
    const navigate = useNavigate();

    let dropdownItems: ReactNode[] = [];
    if(department.departmentType === DepartmentType.Clinics) {
        dropdownItems.push(<NavDropdown.Item key='clinics' onClick={() => navigate(`/departments/${department.id}/clinic`)}>{resolveText('WaitingRoom')}</NavDropdown.Item>);
    }
    if(department.departmentType === DepartmentType.Ward) {
        dropdownItems.push(<NavDropdown.Item key='rooms' onClick={() => navigate(`/institutions/${department.institutionId}/departments/${department.id}/rooms`)}>{resolveText('Rooms')}</NavDropdown.Item>);
    } else if(department.departmentType === DepartmentType.MealService) {
        dropdownItems.push(<NavDropdown.Item key='menus' onClick={() => navigate(`/departments/${department.id}/menus`)}>{resolveText('MealMenus')}</NavDropdown.Item>);
    }
    if(features.enableServices) {
        dropdownItems.push(<NavDropdown.Item key='services' onClick={() => navigate(`/departments/${department.id}/services`)}>{resolveText('Services')}</NavDropdown.Item>);
        dropdownItems.push(<NavDropdown.Item key='servicerequests' onClick={() => navigate(`/departments/${department.id}/requests`)}>{resolveText('ServiceRequests')}</NavDropdown.Item>);
    }
    if(features.enableWarehousing) {
        dropdownItems.push(<NavDropdown.Item key='stocks' onClick={() => navigate(`/departments/${department.id}/stocks`)}>{resolveText('Stocks')}</NavDropdown.Item>);
    }
    if(features.enableTaskList) {
        dropdownItems.push(<NavDropdown.Item key='tasks' onClick={() => navigate(`/todolist?mode=Department&departmentId=${department.id}`)}>{resolveText('TodoList')}</NavDropdown.Item>);
    }
    if(features.healthRecord.enableProcedures) {
        dropdownItems.push(<NavDropdown.Item key='procedures' onClick={() => navigate(`/departments/${department.id}/procedures`)}>{resolveText('DepartmentPatientJourneys')}</NavDropdown.Item>);
    }

    return (
        <>
            <NavDropdown.Divider />
            <NavDropdown title={<b>{department.name}</b>}>
                {dropdownItems}
            </NavDropdown>
        </>
    );

}   
export default DepartmentSubMenu;