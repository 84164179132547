import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { loadItemsActionBuilder, postActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ServiceRequestState, ServiceType } from "../../types/enums";
import { ServiceRequestsFilter } from "../../types/frontendTypes";
import { Models } from "../../types/models";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ServiceRequestsState extends RemoteState<ViewModels.ServiceRequestViewModel,ServiceRequestsFilter> {
}

const initialState: ServiceRequestsState = {
    ...defaultRemoteInitialState(),
    filter: {
        showOnlyUnfinishedOrders: true
    }
}
export const serviceRequestsSlice = createSlice({
    name: 'serviceRequests',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        setAssignee: (state, action: PayloadAction<SetServiceRequestAssigneeArgs>) => {
            const request = state.items.find(x => x.id === action.payload.requestId);
            if(!request) {
                return;
            }
            request.assigneePersonId = action.payload.assignee?.personId;
            request.assignee = action.payload.assignee;
        },
        changeState: (state, action: PayloadAction<ChangeServiceRequestStateArgs>) => {
            const request = state.items.find(x => x.id === action.payload.requestId);
            if(!request) {
                return;
            }
            request.state = action.payload.newState;
        },
        setHandlerNote: (state, action: PayloadAction<SetServiceRequestHandlerNoteArgs>) => {
            const request = state.items.find(x => x.id === action.payload.requestId);
            if(!request) {
                return;
            }
            request.handlerNote = action.payload.note;
        }
    }
});
export interface SetServiceRequestAssigneeArgs {
    requestId: string;
    assignee: Models.Individuals.Person | undefined;
}
export interface ChangeServiceRequestStateArgs {
    requestId: string;
    newState: ServiceRequestState;
}
export interface SetServiceRequestHandlerNoteArgs {
    requestId: string;
    note: string;
}

const serviceRequestsFilterComparer = (f1: ServiceRequestsFilter | undefined, f2: ServiceRequestsFilter | undefined) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText 
        && f1!.serviceId === f2!.serviceId 
        && f1!.serviceType === f2!.serviceType
        && f1!.serviceDepartmentId === f2!.serviceDepartmentId
        && f1!.requesterDepartmentId === f2!.requesterDepartmentId
        && f1!.showOnlyUnfinishedOrders === f2!.showOnlyUnfinishedOrders
        && f1!.timeRangeStart === f2?.timeRangeStart
        && f1!.timeRangeEnd === f2!.timeRangeEnd;
}
const serviceRequestQueryBuilder = (state: RootState, sliceState: ServiceRequestsState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, serviceRequestsFilterComparer)
    const filter = sliceState.filter;
    if(filter) {
        if(filter.serviceDepartmentId) {
            queryParams.push({ key: 'serviceDepartmentId', value: filter.serviceDepartmentId });
        }
        if(filter.requesterDepartmentId) {
            queryParams.push({ key: 'requesterDepartmentId', value: filter.requesterDepartmentId });
        }
        if(filter.serviceId) {
            queryParams.push({ key: 'serviceId', value: filter.serviceId });
        }
        if(filter.serviceType) {
            queryParams.push({ key: 'serviceType', value: filter.serviceType });
        }
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.showOnlyUnfinishedOrders) {
            queryParams.push({ key: 'showOnlyUnfinishedOrders', value: 'true' });
        }
        if(filter.timeRangeStart) {
            queryParams.push({ key: 'timeRangeStart', value: filter.timeRangeStart as any });
        }
        if(filter.timeRangeEnd) {
            queryParams.push({ key: 'timeRangeEnd', value: filter.timeRangeEnd as any });
        }
    }
    return queryParams;
}

export const serviceRequestsActions = {
    ...createRestApiActions(
        'serviceRequests', 
        serviceRequestsSlice.actions,
        state => state.serviceRequests,
        serviceRequestQueryBuilder,
        serviceRequestsFilterComparer
    ),
    loadForService: loadItemsActionBuilder(
        (args?: { serviceId: string}) => `api/services/${args!.serviceId}/requests`,
        serviceRequestQueryBuilder,
        serviceRequestsFilterComparer,
        () => resolveText("ServiceRequests_CouldNotLoad"),
        serviceRequestsSlice.actions.setIsLoading,
        serviceRequestsSlice.actions.setItems,
        serviceRequestsSlice.actions.addOrUpdateItems,
        serviceRequestsSlice.actions.setHasMoreItems,
        serviceRequestsSlice.actions.setLastUsedFilter,
        state => state.serviceRequests
    ),
    loadForDepartment: loadItemsActionBuilder(
        (args?: { departmentId: string }) => `api/departments/${args!.departmentId}/servicerequests`,
        serviceRequestQueryBuilder,
        serviceRequestsFilterComparer,
        () => resolveText("ServiceRequests_CouldNotLoad"),
        serviceRequestsSlice.actions.setIsLoading,
        serviceRequestsSlice.actions.setItems,
        serviceRequestsSlice.actions.addOrUpdateItems,
        serviceRequestsSlice.actions.setHasMoreItems,
        serviceRequestsSlice.actions.setLastUsedFilter,
        state => state.serviceRequests
    ),
    loadForPerson: loadItemsActionBuilder(
        (args?: { personId: string }) => `api/persons/${args!.personId}/servicerequests`,
        serviceRequestQueryBuilder,
        serviceRequestsFilterComparer,
        () => resolveText("ServiceRequests_CouldNotLoad"),
        serviceRequestsSlice.actions.setIsLoading,
        serviceRequestsSlice.actions.setItems,
        serviceRequestsSlice.actions.addOrUpdateItems,
        serviceRequestsSlice.actions.setHasMoreItems,
        serviceRequestsSlice.actions.setLastUsedFilter,
        state => state.serviceRequests
    ),
    setAssignee: postActionBuilder(
        (args: SetServiceRequestAssigneeArgs) => `api/servicerequests/${args!.requestId}/assign`,
        () => resolveText("ServiceRequest_Assignee_CouldNotSet"),
        serviceRequestsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(serviceRequestsSlice.actions.setAssignee(args))
    ),
    changeState: postActionBuilder(
        (args: ChangeServiceRequestStateArgs) => `api/servicerequests/${args!.requestId}/changestate`,
        () => resolveText('ServiceRequest_State_CouldNotChange'),
        serviceRequestsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(serviceRequestsSlice.actions.changeState(args))
    ),
    setHandlerNote: postActionBuilder(
        (args: SetServiceRequestHandlerNoteArgs) => `api/servicerequests/${args!.requestId}/handlernote`,
        () => resolveText('ServiceRequest_CouldNotStore'),
        serviceRequestsSlice.actions.setIsSubmitting,
        (dispatch,_,args) => dispatch(serviceRequestsSlice.actions.setHandlerNote(args))
    )
};
export const serviceRequestsSelectors = {
    ...createDefaultGenericItemSelectors(state => state.serviceRequests),
    createSelectMealForPerson: () => createSelector(
        (state: RootState) => state.serviceRequests.items,
        (_: RootState, args: { personId: string | undefined }) => args.personId,
        (items, personId) => personId
            ? items
                .filter(x => 
                    x.service.serviceType === ServiceType.Meal
                    && x.requesterPersonId === personId)
                .map(x => x as any as ViewModels.MealViewModel)
            : []
    ),
    createSelectHospitalizationRequests: () => createSelector(
        (state: RootState) => state.serviceRequests.items,
        (serviceRequests) => serviceRequests
            .filter(x => x.service.serviceType === ServiceType.Hospitalization)
            .map(x => x as ViewModels.HospitalizationRequestViewModel)
    ),
};