import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Models } from "../../../localComponents/types/models";
import { useAppDispatch } from "../../../localComponents/redux/store/healthRecordStore";
import { showErrorAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { apiClient } from "../../../sharedCommonComponents/communication/ApiClient";
import { healthRecordEntryEditLockActions, healthRecordEntryEditLockSlice } from "../../redux/slices/healthRecordEntryEditLockSlice";

interface HealthRecordCollaborationWrapperProps extends PropsWithChildren {
    personId: string;
}

export const HealthRecordCollaborationWrapper = (props: HealthRecordCollaborationWrapperProps) => {

    const { personId } = props;

    const dispatch = useAppDispatch();
    const [ connection, setConnection ] = useState<HubConnection>();

    useEffect(() => {
        dispatch(healthRecordEntryEditLockActions.loadEditLocks({ 
            args: {
                personId: personId
            }
        }));
    }, [ personId, dispatch ]);

    const connect = useCallback(async (personId: string) => {
        try {
            const accessTokenResponse = await apiClient.instance!.get(`api/persons/${personId}/healthrecord/signalr-accesstoken`);
            const accessToken = await accessTokenResponse.json() as Models.AccessControl.Jwt;
            const signalRConnection = new HubConnectionBuilder()
                .withUrl(apiClient.instance!.buildUrl('/hubs/healthrecord'), { accessTokenFactory: () => accessToken.rawAccessToken })
                .withAutomaticReconnect()
                .build();
            signalRConnection.on('ReceiveEntryEditLock', (editLock: Models.Collaboration.HealthRecordEntryEditLock) => {
                dispatch(healthRecordEntryEditLockSlice.actions.addOrUpdateItem(editLock));
            });
            signalRConnection.on('OnEditLockReleased', (entryReference: Models.HealthRecordEntryReference) => {
                dispatch(healthRecordEntryEditLockSlice.actions.releaseEditLock(entryReference));
            });
            await signalRConnection.start();
            setConnection(signalRConnection);
        } catch {
            showErrorAlert("Could not establish collaboration environment");
        }
    }, [ dispatch ]);

    const subscribe = useCallback(async (personId: string) => {
        if(!connection) {
            return;
        }
        try {
            await connection.invoke('Subscribe', personId);
        } catch {
            showErrorAlert(`Could not subscribe to collaboration events for ${personId}`);
        }
    }, [ connection ]);

    const unsubscribe = useCallback(async (personId: string) => {
        if(!connection) {
            return;
        }
        try {
            await connection.invoke('Unsubscribe', personId);
        } catch {
            showErrorAlert(`Could not unsubscribe from collaboration events for ${personId}`);
        }
    }, [ connection ]);

    const disconnect = useCallback(async () => {
        if(!connection) {
            return;
        }
        try {
            await connection.stop();
        } catch {
            showErrorAlert("Could not disconnect from collaboration events");
        }
    }, [ connection ]);

    useEffect(() => {
        connect(personId);
        return () => {
            disconnect();
        }
    }, [ personId, connect ]);

    useEffect(() => {
        subscribe(personId);
        return () => {
            unsubscribe(personId);
        }
    }, [ personId, subscribe, unsubscribe ]);

    return (<>
        {props.children}
    </>);

}