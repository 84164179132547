import { lazy } from 'react';
import HealthRecordRoutes from '../../sharedHealthComponents/navigation/HealthRecordRoutes';
import { AccountType } from '../types/enums';
import { JankisRouteDefinition } from '../types/frontendTypes';
import { Models } from '../types/models';

const HealthRecordPage = lazy(() => import('../../localComponents/pages/Patients/HealthRecordPage'));
const PatientNursingPage = lazy(() => import('../../localComponents/pages/Patients/PatientNursingPage'));
const OrderServiceForPatientPage = lazy(() => import('../../localComponents/pages/Patients/OrderServiceForPatientPage'));
const AddTaskOrChecklistPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/AddTaskOrChecklistPage'));
const CreateEditIsolationPage = lazy(() => import('../../localComponents/pages/Patients/CreateEditIsolationPage'));

export const JankisHealthRecordRoutes = (features: Models.Configuration.FeatureSettings): JankisRouteDefinition[] => {


    const healthRecordFeatures = features.healthRecord;
    const healthRecordRoutes = HealthRecordRoutes(healthRecordFeatures);
    const routes: JankisRouteDefinition[] = [
        ...healthRecordRoutes.map(route => ({
            ...route,
            audience: [ AccountType.Patient, AccountType.Employee ]
        }) as JankisRouteDefinition),
        { path: '/', usesCustomLayout: true, element: <HealthRecordPage />, audience: [ AccountType.Patient, AccountType.Employee ]},
        { path: 'nursing', element: <PatientNursingPage />, usesCustomLayout: true, audience: [ AccountType.Patient, AccountType.Employee ]},
    ];
    if(healthRecordFeatures.enableIsolations) {
        const isolationRoutes: JankisRouteDefinition[] = [
            { path: 'create/isolation', element: <CreateEditIsolationPage />, audience: [ AccountType.Employee ]},
            { path: 'edit/isolation/:id', element: <CreateEditIsolationPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...isolationRoutes);
    }
    if(features.enableServices) {
        const serviceRoutes: JankisRouteDefinition[] = [
            { path: 'order/service', element: <OrderServiceForPatientPage />, audience: [ AccountType.Patient, AccountType.Employee ]},
        ];
        routes.push(...serviceRoutes);
    }
    if(features.enableTaskList) {
        const taskRoutes: JankisRouteDefinition[] = [
            { path: 'add/task', element: <AddTaskOrChecklistPage />, audience: [ AccountType.Employee ]},
        ];
        routes.push(...taskRoutes);
    }

    return routes;
}
export default JankisHealthRecordRoutes;