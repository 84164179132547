import { useContext } from "react";
import { GenericTypeCreateEditPage } from "../../../sharedCommonComponents/pages/GenericTypeCreateEditPage";
import UserContext from "../../contexts/UserContext";
import { Models } from "../../types/models";
import { useAppDispatch } from "../../redux/store/healthRecordStore";
import { functionalGroupsActions } from "../../redux/slices/functionalGroupsSlice";
import { showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useNavigate } from "react-router-dom";
import { Hide } from "../../../sharedCommonComponents/helpers/ReactJsonSchemaFormsHelpers";

interface CreateEditFunctionalGroupPageProps {}

export const CreateEditFunctionalGroupPage = (props: CreateEditFunctionalGroupPageProps) => {

    const user = useContext(UserContext)!;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const store = async (functionalGroup: Models.Collaboration.FunctionalGroup) => {
        dispatch(functionalGroupsActions.addItem({
            args: undefined,
            body: functionalGroup,
            onSuccess: () => {
                showSuccessAlert(resolveText("FunctionalGroup_SuccessfullyStored"));
                navigate(-1);
            }
        }));
    }

    return (<GenericTypeCreateEditPage<Models.Collaboration.FunctionalGroup>
        typeName="FunctionalGroup"
        validated={user.userSettings.forms.alwaysShowValidation}
        onSubmit={store}
        uiSchema={{
            id: Hide,
        }}
    />);

}