import { Models } from '../../../localComponents/types/models';
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { formatPerson } from '../../helpers/Formatters';

interface PersonAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Individuals.Person> {
}

export const PersonAutocomplete = (props: PersonAutocompleteProps) => {

    return (
        <GenericAutocomplete
            {...props}
            apiPath='api/persons'
            displayFunc={formatPerson}
        />
    );

}