import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { ResourceIdAutocomplete } from "../Autocompletes/ResourceIdAutocomplete";

interface ResourceSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.ResourceSubscription> {
}

export const ResourceSubscriptionEditor = (props: ResourceSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Resource")}</FormLabel>
            <ResourceIdAutocomplete
                value={subscription.resourceId}
                onChange={resourceId => onChange(state => ({
                    ...state,
                    resourceId: resourceId
                } as Models.Subscriptions.ResourceSubscription))}
            />
        </FormGroup>
    </>);

}