import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import FeatureContext from '../../contexts/FeatureContext';

interface CommonMenuProps {}

export const CommonMenu = (props: CommonMenuProps) => {

    const features = useContext(FeatureContext);
    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText('Menu_Common')} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => navigate('/knowledgeBase')}>{resolveText("Menu_KnowledgeBase")}</NavDropdown.Item>
                {features.enableAdverseEvents
                ? <NavDropdown.Item onClick={() => navigate('/adverse-events')}>{resolveText('Menu_AdverseEvents')}</NavDropdown.Item>
                : null}
                {features.enableContactList
                ? <NavDropdown.Item onClick={() => navigate('/contacts')}>{resolveText('Menu_Contacts')}</NavDropdown.Item>
                : null}
                {features.enableWarehousing
                ? <>
                    <NavDropdown.Item onClick={() => navigate('/consumables')}>{resolveText('Menu_Consumables')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => navigate('/resources')}>{resolveText('Menu_Resources')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => navigate('/materialorders')}>{resolveText('Menu_MaterialOrders')}</NavDropdown.Item>
                </> : null}
                {features.enableTaskList
                ? <NavDropdown.Item onClick={() => navigate('/todoList')}>{resolveText("Tasks")}</NavDropdown.Item>
                : null}
                {features.enableServices
                ? <NavDropdown.Item onClick={() => navigate('/services')}>{resolveText("Services")}</NavDropdown.Item>
                : null}
                <NavDropdown.Item onClick={() => navigate('/tools/chats')}>{resolveText('Menu_TranslatedChat')}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}