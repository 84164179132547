import { useParams } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useMemorizedSelector } from "../../../sharedHealthComponents/redux/helpers/ReduxHooks";
import { functionalGroupsActions, functionalGroupsSelectors } from "../../redux/slices/functionalGroupsSlice";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store/healthRecordStore";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { CouldNotLoadAlert } from "../../../sharedCommonComponents/components/CouldNotLoadAlert";
import { Col, Row, Table } from "react-bootstrap";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { LoadingTableRow } from "../../../sharedCommonComponents/components/LoadingTableRow";
import { NoEntriesTableRow } from "../../../sharedCommonComponents/components/NoEntriesTableRow";
import { ViewModels } from "../../types/viewModels";
import { formatPractitioner } from "../../../sharedHealthComponents/helpers/Formatters";
import { AsyncButton } from "../../../sharedCommonComponents/components/AsyncButton";
import UserContext from "../../contexts/UserContext";
import { sendPostRequest } from "../../../sharedCommonComponents/helpers/StoringHelpers";

interface FunctionalGroupMembersPageProps {}

export const FunctionalGroupMembersPage = (props: FunctionalGroupMembersPageProps) => {

    const { groupId } = useParams();

    const user = useContext(UserContext) as ViewModels.EmployeeUserViewModel;
    const isLoadingGroup = useAppSelector(state => state.functionalGroups.isLoading);
    const group = useMemorizedSelector(functionalGroupsSelectors.getById, { id: groupId });
    const [ isLoadingMembers, setIsLoadingMembers ] = useState<boolean>(false);
    const [ members, setMembers ] = useState<ViewModels.FunctionalGroupMembershipViewModel[]>([]);
    const iAmAMember = useMemo(() => user.professionalProfile && members.some(x => x.practitionerId === user.professionalProfile.id), [ members, user ]);
    const [ isJoiningLeaving, setIsJoiningLeaving ] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!groupId) {
            return;
        }
        dispatch(functionalGroupsActions.loadItemIfNotLoadedYet({
            args: {
                itemId: groupId
            }
        }));
        setIsLoadingMembers(true);
        const loadMembers = buildLoadObjectFunc(
            `api/functionalGroups/${groupId}/members`, {},
            resolveText("FunctionalGroupMembers_CouldNotLoad"),
            setMembers,
            undefined,
            () => setIsLoadingMembers(false)
        );
        loadMembers();
    }, [ groupId, dispatch ]);

    const joinGroup = useCallback(async () => {
        setIsJoiningLeaving(true);
        await sendPostRequest(
            `api/functionalGroups/${groupId}/join`, {},
            resolveText("FunctionGroup_CouldNotJoin"),
            null,
            async response => {
                if(response.status === 200) {
                    const membership = await response.json() as ViewModels.FunctionalGroupMembershipViewModel;
                    setMembers(state => state.concat(membership));
                }
            },
            undefined,
            () => setIsJoiningLeaving(false)
        );
    }, [ groupId ]);

    const leaveGroup = useCallback(async () => {
        setIsJoiningLeaving(true);
        await sendPostRequest(
            `api/functionalGroups/${groupId}/leave`, {},
            resolveText("FunctionGroup_CouldNotLeave"),
            null,
            async () => {
                if(user.professionalProfile) {
                    setMembers(state => state.filter(x => x.practitionerId !== user.professionalProfile.id));
                }
            },
            undefined,
            () => setIsJoiningLeaving(false)
        );
    }, [ groupId, user ]);

    if(isLoadingGroup) {
        return (<LoadingAlert />);
    }

    if(!group) {
        return (<CouldNotLoadAlert />);
    }

    return (<>
        <h1>{resolveText("FunctionalGroup")} {group.name}</h1>
        <Row>
            <Col></Col>
            <Col xs="auto">
                {iAmAMember
                ? <AsyncButton
                    variant="danger"
                    onClick={leaveGroup}
                    isExecuting={isJoiningLeaving}
                    activeText={resolveText("FunctionalGroup_Leave")}
                    executingText={resolveText("FunctionalGroup_Leave")}
                />
                : <AsyncButton
                    onClick={joinGroup}
                    isExecuting={isJoiningLeaving}
                    activeText={resolveText("FunctionalGroup_Join")}
                    executingText={resolveText("FunctionalGroup_Join")}
                />}
            </Col>
        </Row>
        <Table>
            <thead>
                <tr>
                    <th>{resolveText("FunctionalGroupMembers")}</th>
                </tr>
            </thead>
            <tbody>
                {isLoadingMembers ? <LoadingTableRow colSpan={1} />
                : members.length === 0 ? <NoEntriesTableRow colSpan={1} />
                : members.map(member => (
                    <tr key={member.id}>
                        <td>
                            {formatPractitioner(member.practitioner)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </>);

}
export default FunctionalGroupMembersPage;