import { useNavigate } from "react-router-dom";
import { GenericReduxList } from "../../../sharedHealthComponents/components/GenericReduxList";
import { functionalGroupsActions, functionalGroupsSlice } from "../../redux/slices/functionalGroupsSlice";
import { Button } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { GenericFilterView } from "../../../sharedHealthComponents/components/GenericFilterView";
import { RowFormGroup } from "../../../sharedCommonComponents/components/FormControls/RowFormGroup";
import { useAppDispatch, useAppSelector } from "../../redux/store/healthRecordStore";

interface FunctionalGroupListPageProps {}

export const FunctionalGroupListPage = (props: FunctionalGroupListPageProps) => {

    const navigate = useNavigate();

    const filter = useAppSelector(state => state.functionalGroups.filter);
    const dispatch = useAppDispatch();

    return (<>
        <h1>{resolveText("FunctionalGroups")}</h1>
        <GenericFilterView
            filterSelector={state => state.functionalGroups.filter}
            loadItems={functionalGroupsActions.loadItems}
        >
            <RowFormGroup
                label={resolveText("Search")}
                value={filter.searchText ?? ''}
                onChange={searchText => dispatch(functionalGroupsSlice.actions.patchFilter({
                    ...filter,
                    searchText: searchText
                }))}
            />
            <RowFormGroup
                type="checkbox"
                label={resolveText("FunctionalGroup_OnlyMine")}
                value={filter.onlyMine ?? false}
                onChange={onlyMine => dispatch(functionalGroupsSlice.actions.patchFilter({
                    ...filter,
                    onlyMine: onlyMine
                }))}
            />
        </GenericFilterView>
        <GenericReduxList
            sliceSelector={state => state.functionalGroups}
            loadItems={functionalGroupsActions.loadItems}
            hasCreateNewButton
            onCreateNew={() => navigate('/create/functionalgroup')}
            tableHeaderBuilder={() => (
                <thead>
                    <tr>
                        <th></th>
                        <th>{resolveText("FunctionalGroup_Name")}</th>
                        <th></th>
                    </tr>
                </thead>
            )}
            tableRowBuilder={item => (
                <tr key={item.id}>
                    <td></td>
                    <td>{item.name}</td>
                    <td>
                        <Button
                            onClick={() => navigate(`/functionalGroup/${item.id}/members`)}
                        >
                            {resolveText("Open")}
                        </Button>
                    </td>
                </tr>
            )}
            colSpan={3}
        />
    </>);

}
export default FunctionalGroupListPage;