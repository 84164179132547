import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store/healthRecordStore";
import { JanKisSubscriptionType, SharedSubscriptionType } from "../../types/enums";
import { subscriptionsActions } from "../../redux/slices/subscriptionsSlice";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { Models } from "../../types/models";
import { uuid } from "../../../sharedCommonComponents/helpers/uuid";
import UserContext from "../../contexts/UserContext";
import { DepartmentSubscriptionEditor } from "../../components/Subscriptions/DepartmentSubscriptionEditor";
import { StockSubscriptionEditor } from "../../components/Subscriptions/StockSubscriptionEditor";
import { InstitutionSubscriptionEditor } from "../../components/Subscriptions/InstitutionSubscriptionEditor";
import { MaterialOrderSubscriptionEditor } from "../../components/Subscriptions/MaterialOrderSubscriptionEditor";
import { PatientSubscriptionEditor } from "../../components/Subscriptions/PatientSubscriptionEditor";
import { ResourceSubscriptionEditor } from "../../components/Subscriptions/ResourceSubscriptionEditor";
import { ServiceRequestSubscriptionEditor } from "../../components/Subscriptions/ServiceRequestSubscriptionEditor";
import { ServiceSubscriptionEditor } from "../../components/Subscriptions/ServiceSubscriptionEditor";
import { StoreButton } from "../../../sharedCommonComponents/components/StoreButton";
import { showSuccessAlert } from "../../../sharedCommonComponents/helpers/AlertHelpers";

interface CreateEditSubscriptionProps {
}

const SubscriptionTypes = { ...SharedSubscriptionType, ...JanKisSubscriptionType };
export const CreateEditSubscription = (props: CreateEditSubscriptionProps) => {

    const { id } = useParams();
    const user = useContext(UserContext)!;
    const [ subscription, setSubscription ] = useState<Models.Subscriptions.Subscription>({
        id: id ?? uuid(),
        type: undefined as any,
        accountId: user.accountId
    });
    const isSubmitting = useAppSelector(state => state.subscriptions.isSubmitting);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if(!id) {
            return;
        }
        dispatch(subscriptionsActions.loadItemIfNotLoadedYet({
            args: {
                itemId: id
            },
            onSuccess: item => {
                setSubscription(item);
            }
        }));
    }, [ id, dispatch ]);

    const changeType = (type: string) => {
        switch(type) {
            case SubscriptionTypes.Department:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    departmentId: undefined as any
                } as Models.Subscriptions.DepartmentSubscription))
                break;
            case SubscriptionTypes.Institution:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    institutionId: undefined as any
                } as Models.Subscriptions.InstitutionSubscription));
                break;
            case SubscriptionTypes.MaterialOrder:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    orderId: undefined as any
                } as Models.Subscriptions.MaterialOrderSubscription));
                break;
            case SubscriptionTypes.Patient:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    personId: undefined as any
                } as Models.Subscriptions.PatientSubscription));
                break;
            case SubscriptionTypes.Resource:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    resourceId: undefined as any
                } as Models.Subscriptions.ResourceSubscription));
                break;
            case SubscriptionTypes.Service:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    serviceId: undefined as any
                } as Models.Subscriptions.ServiceSubscription));
                break;
            case SubscriptionTypes.ServiceRequest:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    requestId: undefined as any
                } as Models.Subscriptions.ServiceRequestSubscription));
                break;
            case SubscriptionTypes.Stock:
                setSubscription(state => ({
                    id: state?.id ?? uuid(),
                    type: type,
                    accountId: user.accountId,
                    stockId: undefined as any
                } as Models.Subscriptions.StockSubscription));
                break;
        }
    }

    const submit = (e?: FormEvent) => {
        e?.preventDefault();
        dispatch(subscriptionsActions.addItem({
            args: undefined,
            body: subscription,
            onSuccess: () => {
                showSuccessAlert(resolveText("Subscription_SuccessfullyCreated"));
                navigate(-1);
            }
        }))
    }

    let typeSpecificFormGroups = null;
    switch(subscription?.type) {
        case SubscriptionTypes.Department:
            typeSpecificFormGroups = (<DepartmentSubscriptionEditor
                subscription={subscription as Models.Subscriptions.DepartmentSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.Institution:
            typeSpecificFormGroups = (<InstitutionSubscriptionEditor
                subscription={subscription as Models.Subscriptions.InstitutionSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.MaterialOrder:
            typeSpecificFormGroups = (<MaterialOrderSubscriptionEditor
                subscription={subscription as Models.Subscriptions.MaterialOrderSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.Patient:
            typeSpecificFormGroups = (<PatientSubscriptionEditor
                subscription={subscription as Models.Subscriptions.PatientSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.Resource:
            typeSpecificFormGroups = (<ResourceSubscriptionEditor
                subscription={subscription as Models.Subscriptions.ResourceSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.Service:
            typeSpecificFormGroups = (<ServiceSubscriptionEditor
                subscription={subscription as Models.Subscriptions.ServiceSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.ServiceRequest:
            typeSpecificFormGroups = (<ServiceRequestSubscriptionEditor
                subscription={subscription as Models.Subscriptions.ServiceRequestSubscription}
                onChange={setSubscription}
            />);
            break;
        case SubscriptionTypes.Stock:
            typeSpecificFormGroups = (<StockSubscriptionEditor
                subscription={subscription as Models.Subscriptions.StockSubscription}
                onChange={setSubscription}
            />);
            break;
        default:
            break;
    }

    return (<>
        <h1>{resolveText("Subscription")}</h1>
        <Form onSubmit={submit}>
            <FormGroup>
                <FormLabel>{resolveText("Subscription_Type")}</FormLabel>
                <FormControl
                    as="select"
                    value={subscription?.type ?? ''}
                    onChange={e => changeType(e.target.value)}
                >
                    <option value="" disabled>{resolveText("PleaseSelect...")}</option>
                    {Object.values(SubscriptionTypes).map(x => (
                        <option key={x} value={x}>{x}</option>
                    ))}
                </FormControl>
            </FormGroup>
            {typeSpecificFormGroups}
            <StoreButton
                isStoring={isSubmitting}
                disabled={!subscription.type}
            />
        </Form>
    </>);

}