import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ResourcessFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ResourcesState extends RemoteState<ViewModels.ResourceViewModel, ResourcessFilter> {
}

const initialState: ResourcesState = {
    ...defaultRemoteInitialState(),
};
export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const resourcesQueryBuilder = (state: RootState, sliceState: ResourcesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, resourcesFilterComparer);
    const filter = sliceState.filter;
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    return queryParams;
}
const resourcesFilterComparer = (f1?: ResourcessFilter, f2?: ResourcessFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.searchText === f2.searchText;
}
export const resourcesActions = {
    ...createRestApiActions(
        'resources', 
        resourcesSlice.actions,
        state => state.resources,
        resourcesQueryBuilder,
        resourcesFilterComparer
    ),
};
export const resourcesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.resources),
};