import { Col, Row } from 'react-bootstrap';
import { Models } from '../../../localComponents/types/models';
import { GenericAutocomplete, GenericAutocompleteImplementationProps } from '../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete';
import { ClassificationReferenceSuggestions } from './ClassificationReferenceSuggestions';
import { useMemorizedSelector } from '../../redux/helpers/ReduxHooks';
import { classificationsActions, classificationsSelectors } from '../../redux/slices/classificationsSlice';
import { toIcdCategory } from '../../helpers/ClassificationReferenceHelpers';
import { useAppDispatch } from '../../../localComponents/redux/store/healthRecordStore';
import { useEffect } from 'react';
import { ClassificationType } from '../../../localComponents/types/enums';

interface IcdAutocompleteProps extends GenericAutocompleteImplementationProps<Models.Classifications.Icd.IcdCategory> {
    suggestedConceptIds?: string[];
    autocompleteContext?: string;
    maxSuggestions?: number;
}

export const IcdAutocomplete = (props: IcdAutocompleteProps) => {

    const { 
        value, 
        onChange, 
        suggestedConceptIds, 
        autocompleteContext, 
        maxSuggestions,
        disabled
    } = props;
    const suggestions = useMemorizedSelector(classificationsSelectors.createSelectIcdCategories, { ids: suggestedConceptIds ?? [] });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!suggestedConceptIds) {
            return;
        }
        dispatch(classificationsActions.loadIcdCategoriesIfNotLoaded({
            args: {
                conceptIds: suggestedConceptIds
            }
        }));
    }, [ suggestedConceptIds, dispatch ]);

    return (
    <>
        <Row>
            <Col>
                <GenericAutocomplete<Models.Classifications.Icd.IcdCategory>
                    {...props}
                    apiPath='api/classifications/icd11'
                    displayFunc={x => `${x.code} - ${x.name}`}
                />
            </Col>
        </Row>
        {!value && !disabled
        ? <ClassificationReferenceSuggestions
            context={autocompleteContext}
            classification={ClassificationType.Icd11}
            maxCount={maxSuggestions}
            additionalSuggestions={suggestions}
            onSuggestionSelected={suggestion => onChange(toIcdCategory(suggestion))}
        /> : null}
    </>);

}