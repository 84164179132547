import { Models } from "../types/models";

export const getRoomAtPosition = (rooms: Models.Organizations.RoomGridPosition[], rowIndex: number, columnIndex: number) => {
    return rooms.find(room => 
        columnIndex >= room.start.x && columnIndex <= room.end.x
        && rowIndex >= room.start.y && rowIndex <= room.end.y
    );
}
export const isInsideExistingRoom = (rooms: Models.Organizations.RoomGridPosition[], rowIndex: number, columnIndex: number) => {
    const roomAtPosition = getRoomAtPosition(rooms, rowIndex, columnIndex);
    return !!roomAtPosition;
}