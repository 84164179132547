import React, { useContext, useMemo } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import FeatureContext from '../../contexts/FeatureContext';
import UserContext from '../../contexts/UserContext';
import { hasGlobalPermission } from '../../helpers/PermissionsHelpers';
import { Permission } from '../../types/enums';

interface ConfigMenuProps {}

export const ConfigMenu = (props: ConfigMenuProps) => {

    const features = useContext(FeatureContext);
    const user = useContext(UserContext);
    const hasHandleFeedbackPermission = useMemo(() => hasGlobalPermission(user, Permission.HandleFeedback), [ user ]);
    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText('Menu_Config')} id="menu-config">
                <NavDropdown.Item onClick={() => navigate('/institutions')}>{resolveText('Menu_Config_Institutions')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/departments')}>{resolveText('Menu_Config_Departments')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/accounts')}>{resolveText('Menu_Config_Accounts')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/roles')}>{resolveText('Menu_Config_Roles')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/functionalGroups')}>{resolveText('Menu_Config_FunctionalGroups')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/print-templates')}>{resolveText('Menu_Config_PrintTemplates')}</NavDropdown.Item>
                {features.healthRecord.enableMedication
                ? <NavDropdown.Item onClick={() => navigate('/drugs')}>{resolveText('Menu_Config_Drugs')}</NavDropdown.Item>
                : null}
                {features.healthRecord.enableQuestionnaires
                ? <NavDropdown.Item onClick={() => navigate('/questionnaires')}>{resolveText('Menu_Config_Questionnaires')}</NavDropdown.Item>
                : null}
                {features.enableTaskList
                ? <>
                    <NavDropdown.Item onClick={() => navigate('/taskpackages')}>{resolveText('Menu_Config_TasksPackages')}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => navigate('/checklists')}>{resolveText('Menu_Config_Checklists')}</NavDropdown.Item>
                </>
                : null}
                {features.healthRecord.enableTestResults
                ? <NavDropdown.Item onClick={() => navigate('/testpackages')}>{resolveText('Menu_Config_TestPackages')}</NavDropdown.Item>
                : null}
                {features.enableDataImportExport
                ? <NavDropdown.Item onClick={() => navigate('/export')}>{resolveText('Menu_Config_ExportPatientData')}</NavDropdown.Item>
                : null}
                <NavDropdown.Item onClick={() => navigate('/equipmentmeasurements')}>{resolveText('Menu_Config_EquipmentMeasurements')}</NavDropdown.Item>
                {hasHandleFeedbackPermission ? <NavDropdown.Item onClick={() => navigate('/feedback')}>{resolveText('Feedback')}</NavDropdown.Item> : null}
            </NavDropdown>
        </Nav>
    );

}