import { AccountType, Permission } from "../types/enums";
import { ViewModels } from "../types/viewModels";

export const hasGlobalPermission = (user: ViewModels.IUserViewModel | undefined, permission: Permission) => {
    if(!user) {
        return false;
    }
    if(user.accountType !== AccountType.Employee) {
        return false;
    }
    const employeeUserVM = user as ViewModels.EmployeeUserViewModel;
    return employeeUserVM.permissions.some(institutionPermission => {
        if(institutionPermission.permissions.includes(permission)) {
            return true;
        }
        if(institutionPermission.roles.some(role => role.permissions.includes(permission))) {
            return true;
        }
        return false;
    });
}