import { FormGroup, FormLabel } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SubscriptionEditorProps } from "../../../sharedHealthComponents/types/frontendTypes";
import { Models } from "../../types/models";
import { ServiceRequestIdAutocomplete } from "../../../sharedHealthComponents/components/Autocompletes/ServiceRequestIdAutocomplete";
import { useState } from "react";
import { ServiceIdAutocomplete } from "../../../sharedHealthComponents/components/Autocompletes/ServiceIdAutocomplete";

interface ServiceRequestSubscriptionEditorProps extends SubscriptionEditorProps<Models.Subscriptions.ServiceRequestSubscription> {
}

export const ServiceRequestSubscriptionEditor = (props: ServiceRequestSubscriptionEditorProps) => {
    const { subscription, onChange } = props;

    const [ serviceId, setServiceId ] = useState<string>();

    return (<>
        <FormGroup>
            <FormLabel>{resolveText("Service")}</FormLabel>
            <ServiceIdAutocomplete
                value={serviceId}
                onChange={setServiceId}
            />
        </FormGroup>
        <FormGroup>
            <FormLabel>{resolveText("ServiceRequest")}</FormLabel>
            <ServiceRequestIdAutocomplete
                serviceId={serviceId}
                value={subscription.requestId}
                onChange={requestId => onChange(state => ({
                    ...state,
                    requestId: requestId
                } as Models.Subscriptions.ServiceRequestSubscription))}
            />
        </FormGroup>
    </>);

}