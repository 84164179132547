import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { Models } from "../../types/models";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { KnowledgeBaseArticleFilter } from "../../types/frontendTypes";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { RootState } from "../store/healthRecordStore";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { getActionBuilder } from "../../../sharedHealthComponents/redux/helpers/ActionCreatorBuilder";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface KnowledgeBaseState extends RemoteState<Models.Collaboration.KnowledgeBaseArticle,KnowledgeBaseArticleFilter> {
    categories: string[];
}
const initialState: KnowledgeBaseState = {
    ...defaultRemoteInitialState(),
    categories: []
};
export const knowledgeBaseSlice = createSlice({
    name: 'knowledgeBase',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
        setCategories: (state, action: PayloadAction<string[]>) => {
            state.categories = action.payload;
        }
    }
});
const knowledgeBaseFilterComparer = (f1?: KnowledgeBaseArticleFilter, f2?: KnowledgeBaseArticleFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText
        && f1!.category === f2!.category;
}
const knowledgeBaseQueryBuilder = (state: RootState, sliceState: RemoteState<Models.Collaboration.KnowledgeBaseArticle,KnowledgeBaseArticleFilter>) => {
    const queryParams = defaultQueryBuilder(state, sliceState, knowledgeBaseFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
        if(filter.category) {
            queryParams.push({ key: 'category', value: filter.category });
        }
    }
    return queryParams;
}
export const knowledgeBaseActions = {
    ...createRestApiActions(
        'knowledgeBase',
        knowledgeBaseSlice.actions,
        state => state.knowledgeBase,
        knowledgeBaseQueryBuilder,
        knowledgeBaseFilterComparer
    ),
    loadCategories: getActionBuilder<unknown,string[]>(
        _ => 'api/knowledgeBase/categories',
        () => resolveText("KnowledgeBase_CouldNotLoadCategories"),
        knowledgeBaseSlice.actions.setIsLoading,
        (dispatch,response,_) => dispatch(knowledgeBaseSlice.actions.setCategories(response))
    )
}
export const knowledgeBaseSelectors = {
    ...createDefaultGenericItemSelectors(state => state.knowledgeBase),
}