import { ResourceAutocomplete } from "./ResourceAutocomplete";
import { ViewModels } from "../../types/viewModels";
import GenericIdAutocomplete from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";

interface ResourceIdAutocompleteProps extends IdAutocompleteProps {
}

export const ResourceIdAutocomplete = (props: ResourceIdAutocompleteProps) => {

    return (<GenericIdAutocomplete<ViewModels.ResourceViewModel>
        loadItemApiPathBuilder={id => `api/resources/${id}`}
        autocompleteBuilder={(key, props) => (
            <ResourceAutocomplete
                key={key}
                {...props}
            />
        )}
        {...props}
    />);

}