import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { StocksFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface StocksState extends RemoteState<ViewModels.StockViewModel, StocksFilter> {
}

const initialState: StocksState = {
    ...defaultRemoteInitialState(),
};

export const stocksSlice = createSlice({
    name: 'stocks',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});
const stockFilterComparer = (f1?: StocksFilter, f2?: StocksFilter) => {
    if(!areFiltersEqual(f1, f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText;
}
const stockQueryBuilder = (state: RootState, sliceState: StocksState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, stockFilterComparer);
    const filter = sliceState.filter;
    if(filter) {
        if(filter.searchText) {
            queryParams.push({ key: 'searchText', value: filter.searchText });
        }
    }
    return queryParams;
}
export const stocksActions = {
    ...createRestApiActions(
        'stocks', 
        stocksSlice.actions,
        state => state.stocks,
        stockQueryBuilder,
        stockFilterComparer
    ),
};
export const stocksSelectors = {
    ...createDefaultGenericItemSelectors(state => state.stocks),
};