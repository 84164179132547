import '../../styles/print-department-bedlist.css';

import { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ViewModels } from "../../types/viewModels";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { DepartmentBedListRoomTableRow } from "../../components/Departments/DepartmentBedListRoomTableRow";
import { LoadingAlert } from "../../../sharedCommonComponents/components/LoadingAlert";
import { CouldNotLoadAlert } from "../../../sharedCommonComponents/components/CouldNotLoadAlert";
import { Center } from "../../../sharedCommonComponents/components/Center";
import { formatDateTime } from '../../../sharedHealthComponents/helpers/Formatters';
import { sortByOrdinalGroup } from '../../helpers/RoomHelpers';

interface PrintDepartmentPatientsListPageProps {}


export const PrintDepartmentPatientsListPage = (props: PrintDepartmentPatientsListPageProps) => {

    const { departmentId } = useParams();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ bedList, setBedList ] = useState<ViewModels.DepartmentBedListViewModel>();
    const rooms = useMemo(() => sortByOrdinalGroup([ ...(bedList?.rooms.filter(x => x.bedPositions.length > 0) ?? []) ], x => x.name), [ bedList ]);

    useEffect(() => {
        if(!departmentId) {
            return;
        }
        setIsLoading(true);
        const loadBedList = buildLoadObjectFunc(
            `api/departments/${departmentId}/bedlist`, {},
            resolveText("Department_CouldNotLoad"),
            setBedList,
            undefined,
            () => setIsLoading(false)
        );
        loadBedList();
    }, [ departmentId]);

    if(isLoading) {
        return (<LoadingAlert />);
    }

    if(!bedList) {
        return (<CouldNotLoadAlert />);
    }

    return (<>
        <Center className="no-print mb-3">
            <Button
                onClick={() => window.print()}
            >
                <i className="fa fa-print" /> {resolveText("Print")}
            </Button>
        </Center>
        <div className='page-title'>
            <Row className='align-items-center'>
                <Col />
                <Col xs="auto">
                    <h3>{bedList.department.name}</h3>
                </Col>
                <Col xs="auto">
                    {formatDateTime(new Date())}
                </Col>
                <Col />
            </Row>
        </div>
        <div className='page-container'>
            <Table
                size='sm'
                className='department-bedlist-table'
            >
                <colgroup>
                    <col style={{ width: '1%', whiteSpace: 'nowrap' }}/>
                    <col style={{ width: '1%', whiteSpace: 'nowrap' }}/>
                    <col style={{ width: '40%' }}/>
                    <col style={{ width: '1%', whiteSpace: 'nowrap' }}/>
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>{resolveText("Room")}</th>
                        <th>{resolveText("Bed")}</th>
                        <th className='text-center'>{resolveText("Patient")}</th>
                        <th>E</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rooms.map(room => (
                        <DepartmentBedListRoomTableRow
                            key={room.id}
                            room={room}
                            bedOccupancies={bedList.bedOccupancies}
                            patients={bedList.patients}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    </>);

}
export default PrintDepartmentPatientsListPage;