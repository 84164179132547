import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import FeatureContext from '../../contexts/FeatureContext';
import { useAppSelector } from '../../redux/store/healthRecordStore';

interface NurseMenuProps {}

export const NurseMenu = (props: NurseMenuProps) => {

    const features = useContext(FeatureContext);
    const serviceDepartments = useAppSelector(state => state.departments.serviceDepartments);
    const navigate = useNavigate();

    return (
        <Nav>
            <NavDropdown title={resolveText('Menu_Nursing')} id="menu-nursing">
                <NavDropdown.Item onClick={() => navigate('/ward/handover')} disabled>{resolveText('Menu_Handover')}</NavDropdown.Item>
            </NavDropdown>
            {features.enableServices && serviceDepartments.length > 0
            ? <NavDropdown title={resolveText('Menu_Services')} id="menu-services">
                <NavDropdown.Item onClick={() => navigate('/services')}>{resolveText('Menu_AllServices')}</NavDropdown.Item>
                <NavDropdown.Divider />
                {serviceDepartments.map(department => {
                    return (
                        <NavDropdown.Item 
                            key={department.id}
                            onClick={() => navigate(`/departments/${department.id}/services`)}
                        >
                            {department.name}
                        </NavDropdown.Item>
                    )
                })}
                <NavDropdown.Divider />
                <NavDropdown.Item 
                    onClick={() => navigate(`/create/pharmacyOrder`)}
                >
                    {resolveText("PharmacyOrder_CreateNew")}
                </NavDropdown.Item>
                <NavDropdown.Item 
                    onClick={() => navigate(`/pharmacyOrders`)}
                >
                    {resolveText("PharmacyOrders")}
                </NavDropdown.Item>
            </NavDropdown>
            : null}
        </Nav>
    );

}