import { useContext } from 'react';
import { Accordion, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import DepartmentSubMenu from './DepartmentSubMenu';
import { ViewModels } from '../../types/viewModels';

interface DepartmentsMenuProps {}

export const DepartmentsMenu = (props: DepartmentsMenuProps) => {

    
    const user = useContext(UserContext)! as ViewModels.EmployeeUserViewModel;
    const departments = user!.departments;
    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText('Menu_Department')} id="menu-department">
                <NavDropdown.Item onClick={() => navigate('/rooms')}>{resolveText('Rooms')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/mypatients')}>{resolveText('MyPatients')}</NavDropdown.Item>
                <Accordion>
                {departments.map(department => (
                    <DepartmentSubMenu 
                        key={department.id}
                        department={department}
                    />
                ))}
                </Accordion>
            </NavDropdown>
        </Nav>
    );

}