import { useParams } from "react-router-dom";
import { RouteDefinition } from "../../../sharedCommonComponents/types/frontendTypes";
import RoutesBuilder from "../../../sharedCommonComponents/navigation/RoutesBuilder";
import { HealthRecordCollaborationWrapper } from "../../components/Patients/HealthRecordCollaborationWrapper";
import { Alert } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { PageContainer } from "../../../sharedCommonComponents/components/PageContainer";

interface HealthRecordEnvironmentProps {
    routes: RouteDefinition[];
}

export const HealthRecordEnvironment = (props: HealthRecordEnvironmentProps) => {

    const { routes } = props;
    const { personId } = useParams();

    if(!personId) {
        return (<Alert
            variant="danger"
        >
            {resolveText("MissingID")}
        </Alert>);
    }

    return (<HealthRecordCollaborationWrapper
        personId={personId}
    >
        <RoutesBuilder
            routeDefinitions={routes}
            containerBuilder={children => (
                <PageContainer
                    className='mt-3'
                >
                    {children}
                </PageContainer>
            )}
        />
    </HealthRecordCollaborationWrapper>);

}
export default HealthRecordEnvironment;