import { Models } from "../../../localComponents/types/models";
import GenericIdAutocomplete from "../../../sharedCommonComponents/components/Autocompletes/GenericIdAutocomplete";
import { IdAutocompleteProps } from "../../../sharedCommonComponents/types/frontendTypes";
import { InstitutionAutocomplete } from "./InstitutionAutocomplete";

interface InstitutionIdAutocompleteProps extends IdAutocompleteProps {}

export const InstitutionIdAutocomplete = (props: InstitutionIdAutocompleteProps) => {

    return (
        <GenericIdAutocomplete<Models.Organizations.Institution>
            {...props}
            loadItemApiPathBuilder={id => `api/institutions/${id}`}
            autocompleteBuilder={(key,props) => (
                <InstitutionAutocomplete
                    key={key}
                    {...props}
                />
            )}
        />
    );

}
export default InstitutionIdAutocomplete;