import { createSlice } from "@reduxjs/toolkit";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { InvoiceFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface InvoicesState extends RemoteState<ViewModels.EncounterInvoiceViewModel,InvoiceFilter> {

}
const initialState: InvoicesState = {
    ...defaultRemoteInitialState(),
}
export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState)
    }
});

const invoicesQueryBuilder = (state: RootState, sliceState: InvoicesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, invoicesFilterComparer);
    const filter = sliceState.filter;
    if(filter?.status?.length > 0) {
        for (const status of filter.status) {
            queryParams.push({ key: 'status', value: status });
        }
    }
    return queryParams;
}
const invoicesFilterComparer = (f1?: InvoiceFilter, f2?: InvoiceFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    if(!f1?.status && !f2?.status) {
        return true;
    }
    if(!f1?.status || !f2?.status) {
        return false;
    }
    if(f1.status.length !== f2.status.length) {
        return false;
    }
    for (const status of f1.status) {
        if(!f2.status.includes(status)) {
            return false;
        }
    }
    for (const status of f2.status) {
        if(!f1.status.includes(status)) {
            return false;
        }
    }
    return true;
}
export const invoicesActions = {
    ...createRestApiActions(
        'invoices',
        invoicesSlice.actions,
        state => state.invoices,
        invoicesQueryBuilder,
        invoicesFilterComparer
    )
};
export const invoicesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.invoices),
};