import { createSlice } from "@reduxjs/toolkit";
import { areFiltersEqual } from "../../../sharedHealthComponents/helpers/FilterHelpers";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ServicePackagesFilter } from "../../types/frontendTypes";
import { Models } from "../../types/models";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

interface ServicePackagesState extends RemoteState<Models.Services.ServicePackage, ServicePackagesFilter> {

}

const initialState: ServicePackagesState = {
    ...defaultRemoteInitialState(),
}
export const servicePackagesSlice = createSlice({
    name: 'servicePackages',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const servicePackagesQueryBuilder = (state: RootState, sliceState: ServicePackagesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, servicePackagesFilterComparer);
    const filter = sliceState.filter;
    if(filter.departmentId) {
        queryParams.push({ key: 'departmentId', value: filter.departmentId });
    }
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    return queryParams;
}
const servicePackagesFilterComparer = (f1?: ServicePackagesFilter, f2?: ServicePackagesFilter) => {
    if(!areFiltersEqual(f1,f2)) {
        return false;
    }
    return f1!.searchText === f2!.searchText 
        && f1!.departmentId === f2!.departmentId;
}
export const servicePackagesActions = {
    ...createRestApiActions(
        'servicePackages',
        servicePackagesSlice.actions,
        state => state.servicePackages,
        servicePackagesQueryBuilder,
        servicePackagesFilterComparer
    )
};
export const servicePackagesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.servicePackages),
};