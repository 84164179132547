import { PropsWithChildren, useEffect } from 'react';
import { AccordionCard } from '../../sharedCommonComponents/components/AccordionCard';
import { resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { ApiGetActionCreator } from '../types/reduxTypes';
import { RootState, useAppDispatch, useAppSelector } from '../../localComponents/redux/store/healthRecordStore';

interface GenericFilterViewProps<ArgsType,ItemType> {
    filterSelector: (state: RootState) => any;
    loadItems: ApiGetActionCreator<ArgsType,ItemType>;
    isOpenAtCreate?: boolean;
}

export const GenericFilterView = <ArgsType,ItemType>(props: PropsWithChildren<GenericFilterViewProps<ArgsType,ItemType>>) => {

    const { filterSelector, loadItems, isOpenAtCreate } = props;
    const dispatch = useAppDispatch();
    const filter = useAppSelector(filterSelector);

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(loadItems({})), 300);
        return () => {
            clearTimeout(timeout);
        }
    }, [ filter, dispatch, loadItems ]);

    return (
        <AccordionCard standalone
            eventKey='filter'
            title={resolveText("Filters")}
            bg='info'
            headerClassName='py-2'
            className='mb-2'
            isOpenAtCreate={isOpenAtCreate}
        >
            {props.children}
        </AccordionCard>
    );

}