import { lazy } from 'react';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { RedirectToLogin } from '../components/RedirectToLogin';
import { Models } from '../types/models';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'));

interface NotLoggedInRoutesProps {
    onLoggedIn: (loginResult: Models.AccessControl.LoginResult, redirectUrl?: string) => void;
}

export const NotLoggedInRoutes = (props: NotLoggedInRoutesProps): RouteDefinition[] => [
    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/', element: <LoginPage onLoggedIn={props.onLoggedIn} /> },
    { path: '/login', element: <LoginPage onLoggedIn={props.onLoggedIn} /> },
    { path: '*', element: <RedirectToLogin /> },
]