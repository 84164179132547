import { useEffect, useState } from "react";
import { ClassificationType } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { GenericAutocompleteImplementationProps } from "../../../sharedCommonComponents/components/Autocompletes/GenericAutocomplete";
import { 
    snomedCtViewModelToClassificationReference, 
    icdCategoryToClassificationReference, 
    toSnomedCtConceptViewModel, 
    toIcdCategory,
    toLoincConcept,
    loincConceptToClassificationReference
} from "../../helpers/ClassificationReferenceHelpers";
import { IcdAutocomplete } from "./IcdAutocomplete";
import LoincAutocomplete from "./LoincAutocomplete";
import { SnomedCtAutoComplete } from "./SnomedCtAutoComplete";
import { buildLoadObjectFunc } from "../../../sharedCommonComponents/helpers/LoadingHelpers";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ViewModels } from "../../../localComponents/types/viewModels";

interface ClassificationReferenceAutocompleteProps extends GenericAutocompleteImplementationProps<Models.ClassificationReference> {
    classification: ClassificationType;
}

export const ClassificationReferenceAutocomplete = (props: ClassificationReferenceAutocompleteProps) => {

    const { classification, isLoading: propsIsLoading, value, onChange } = props;
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    useEffect(() => {
        if(!value?.conceptId) {
            return;
        }
        if(value.displayName) {
            return;
        }
        setIsLoading(true);
        const loadConcept = buildLoadObjectFunc(
            `api/classifications/${value.classification}/${value.conceptId}`, {},
            resolveText("ClassificationReference_CouldNotLoad"),
            item => {
                switch(value.classification) {
                    case ClassificationType.SnomedCt:
                        const snomedCtConcept = item as ViewModels.SnomedCtConceptViewModel;
                        onChange(snomedCtViewModelToClassificationReference(snomedCtConcept));
                        break;
                    case ClassificationType.Icd11:
                        const icdCategory = item as Models.Classifications.Icd.IcdCategory;
                        onChange(icdCategoryToClassificationReference(icdCategory));
                        break;
                    case ClassificationType.Loinc:
                        const loincConcept = item as Models.Classifications.LoincConcept;
                        onChange(loincConceptToClassificationReference(loincConcept));
                        break;
                    default:
                        break;
                }
            },
            undefined,
            () => setIsLoading(false)
        );
        loadConcept();
    }, [ value ]);

    switch(classification) {
        case ClassificationType.SnomedCt:
            return (<SnomedCtAutoComplete
                isLoading={isLoading || propsIsLoading}
                value={toSnomedCtConceptViewModel(value)}
                onChange={vm => onChange(snomedCtViewModelToClassificationReference(vm))}
            />);
        case ClassificationType.Icd11:
            return (<IcdAutocomplete
                isLoading={isLoading || propsIsLoading}
                value={toIcdCategory(value)}
                onChange={vm => onChange(icdCategoryToClassificationReference(vm))}
            />);
        case ClassificationType.Loinc:
            return (<LoincAutocomplete
                isLoading={isLoading || propsIsLoading}
                value={toLoincConcept(value)}
                onChange={vm => onChange(loincConceptToClassificationReference(vm))}
            />);
        default:
            throw new Error(`Unknown classification ${classification}`);
    }
}
export default ClassificationReferenceAutocomplete;