import { createSlice } from "@reduxjs/toolkit";
import { defaultRemoteInitialState } from "../../../sharedHealthComponents/redux/helpers/DefaultInitialState";
import { createDefaultGenericItemReducers } from "../../../sharedHealthComponents/redux/helpers/DefaultReducers";
import { createRestApiActions } from "../../../sharedHealthComponents/redux/helpers/GenericSliceActions";
import { defaultQueryBuilder } from "../../../sharedHealthComponents/redux/helpers/QueryBuilders";
import { RemoteState } from "../../../sharedHealthComponents/types/reduxInterfaces";
import { ConsumablesFilter } from "../../types/frontendTypes";
import { ViewModels } from "../../types/viewModels";
import { RootState } from "../store/healthRecordStore";
import { createDefaultGenericItemSelectors } from "../../../sharedHealthComponents/redux/helpers/GenericSliceSelectors";

export interface ConsumablesState extends RemoteState<ViewModels.ConsumableViewModel, ConsumablesFilter> {
}

const initialState: ConsumablesState = {
    ...defaultRemoteInitialState(),
};
export const consumablesSlice = createSlice({
    name: 'consumables',
    initialState,
    reducers: {
        ...createDefaultGenericItemReducers(initialState),
    }
});

const consumablesQueryBuilder = (state: RootState, sliceState: ConsumablesState) => {
    const queryParams = defaultQueryBuilder(state, sliceState, consumablesFilterComparer);
    const filter = sliceState.filter;
    if(filter.searchText) {
        queryParams.push({ key: 'searchText', value: filter.searchText });
    }
    return queryParams;
}
const consumablesFilterComparer = (f1?: ConsumablesFilter, f2?: ConsumablesFilter) => {
    if(!f1 && !f2) {
        return true;
    }
    if(!f1 || !f2) {
        return false;
    }
    return f1.searchText === f2.searchText;
}
export const consumablesActions = {
    ...createRestApiActions(
        'consumables', 
        consumablesSlice.actions,
        state => state.consumables,
        consumablesQueryBuilder,
        consumablesFilterComparer
    ),
};
export const consumablesSelectors = {
    ...createDefaultGenericItemSelectors(state => state.consumables),
};